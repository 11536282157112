import { useEffect, useState } from "react";
import React from 'react'
import './requestPerfil.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import redes_sociais from '../../image/redes-sociais.png'
import google_meu_negocio from '../../image/google-meu-negocio.png'
import app_cliente from '../../image/app-cliente.png'
import { Link, useParams } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import axios from "axios";

const RequestPerfil = () => {
    const [requests, setRequests] = useState([])
    const [titlePage, setTitlePage] = useState('')
    const [status, setStatus] = useState('');
    const [responseRequest, setResponseRequest] = useState([])
    const [addons, setAddons] = useState([]);
    const [reselerName, setReselerName] = useState('');
    const [modules, setModules] = useState([])
    const [invoices, setInvoices] = useState([])
    const [imgReserve, setImgReserve] = useState('');
    const [renovacao, setRenovacao] = useState('Não possui fatura')
    const [renovacaoLengh, setRenovacaoLengh] = useState(0)
    const [reselerId, setReselerId] = useState('')

    const { id } = useParams()

    useEffect(() => {
        api
            .get(`/Solicitacoes/${id}`)
            .then(response => {
                setRequests(response.data)
                setReselerId(response.data.reseller)
                if (response.data.request == 'duvidas plataforma') {
                    setTitlePage('Dúvidas da plataforma')
                } else if (response.data.request == 'suporte') {
                    setTitlePage('Suporte')
                } else if (response.data.request == 'informacoes') {
                    setTitlePage('Informações')
                } else if (response.data.request == 'raio-x') {
                    setTitlePage('Raio-x das redes sociais')
                } else if (response.data.request == 'google') {
                    setTitlePage('Google meu negócio')
                } else if (response.data.request == 'app-gas-click') {
                    setTitlePage('Aplicativo gás no clique')
                }
            })
    }, [])

    useEffect(() => {
        api
            .get(`/MensagensSolicitacoes/${id}`)
            .then(response => {
                if (response.data.message !== null || response.data.message !== undefined) {
                    var json = JSON.parse(response.data.message)
                    setResponseRequest(json)
                }
            })
    }, [])

    useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosUsuariosRevenda/clube_api')
            .then(response => {
                console.log(response.data)
                response.data.map(key => {
                    if (key.id == reselerId) {
                        setAddons(key)
                        setModules(key.addons)
                        // axios.get(`${key.url}Configuracoes`)
                        //     .then(response => {
                        //         setImgReserve(response.data[0].logoLogin)
                        //     })
                    }
                })
            })
    }, [reselerId])

    return (
        <>
            <div className='orderedContainer'>
                <Card className="cardContainer" style={{ width: '100%', height: '87vh', borderRadius: '10px', padding: '10px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <div className="containerRequestChamados">
                        <div className="divSpanRequest">
                            <div className="divSpanPerfilRequest">
                                <div>
                                    <span className="spanRequest">{titlePage}</span>
                                </div>
                                <div style={{ width: 230 }}>
                                    <FormControl fullWidth2 style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-label" className="inputLabelStatusProcess">Mudar etapa do processo</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className='statusProcess'
                                            value={status}
                                            label="Mudar etapa do processo"
                                            onChange={e => {
                                            }}
                                        >
                                            <MenuItem value={'resolvido'}>Resolvido</MenuItem>
                                            <MenuItem value={'nao resolvido'}>Não resolvido</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div style={{ width: '50%' }}>
                            <div className="divCadastro">
                                <Table className="tableProfile">
                                    {/* {responseRequest.map(key => {
                                        console.log(key)
                                    })} */}
                                    {titlePage == 'Google meu negócio' ? (
                                        <tbody style={{ height: 300 }}>
                                            <tr>
                                                <td className="labelPerfilProcess">Responsável pela implantação</td>
                                                <td className="labelResultPerfilProcess">
                                                    <div className="spanResultPerfilProcess">
                                                        <span >{responseRequest.responsavel_revenda}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="trPerfilProcess">
                                                <td className="labelPerfilProcess">Telefone do responsável</td>
                                                <td className="labelResultPerfilProcess">
                                                    <div className="spanResultPerfilProcess">
                                                        <span >{responseRequest.telefone_revenda}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="trPerfilProcess">
                                                <td className="labelPerfilProcess">Email do responsável</td>
                                                <td className="labelResultPerfilProcess">
                                                    <div className="spanResultPerfilProcess">
                                                        <span >{responseRequest.email_revenda}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="trPerfilProcess">
                                                <td className="labelPerfilProcess">Porque está buscando a implantação do Google Meu Negócio</td>
                                                <td className="labelResultPerfilProcess">
                                                    <div className="spanResultPerfilProcess">
                                                        <span >{responseRequest.motivo_revenda}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : titlePage == 'Suporte' ? (
                                        <tbody style={{ height: 300 }}>
                                            <tr>
                                                <td className="labelPerfilProcess">Solicitação</td>
                                                <td className="labelResultPerfilProcess">
                                                    <div className="spanResultPerfilProcess">
                                                        <span >{responseRequest}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : titlePage == 'Informações' ? (
                                        <tbody style={{ height: 300 }}>
                                            <tr>
                                                <td className="labelPerfilProcess">Solicitação</td>
                                                <td className="labelResultPerfilProcess">
                                                    <div className="spanResultPerfilProcess">
                                                        <span >{responseRequest}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : titlePage == 'Dúvidas da plataforma' ? (
                                        <tbody style={{ height: 300 }}>
                                            <tr>
                                                <td className="labelPerfilProcess">Solicitação</td>
                                                <td className="labelResultPerfilProcess">
                                                    <div className="spanResultPerfilProcess">
                                                        <span >{responseRequest}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : ('')}
                                </Table>
                            </div>
                            <div className="divTitleInfoReseller">
                                <span className="spanTitleInfoReseller">Informações da revenda solicitante</span>
                            </div>
                            <div className="dataReseler" style={{ justifyContent: 'space-between' }}>
                                <div className="containerImgReseler">
                                    <img src={imgReserve} className="imgReserve" />
                                </div>
                                <div className="contenctReseler" style={{ width: "40%" }}>
                                    <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Revenda: </span> {addons.reseller_name}</span>
                                    <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Nome: </span> {addons.name}</span>
                                    <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>CPF: </span> {addons.cpf == undefined ? ('Não inforrmado') : addons.cpf}</span>
                                    <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>E-mail: </span> {addons.email}</span>
                                    <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Telefone: </span> {addons.phone == undefined ? ('Não informado') : addons.phone}</span>
                                    <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Endereço: </span> {addons.address == undefined ? ('Não informado') : addons.address + ', ' + addons.number + ' - ' + addons.neighborhood + ' - ' + addons.city + '/' + addons.state}</span>
                                    <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>CNPJ:</span> {addons.cnpj == undefined ? ('Não informado') : addons.cnpj}</span>
                                </div>
                                <div className="statusReseler">
                                    <div className="divStatus" style={addons.status == 1 ? { backgroundColor: '#2D8D00' } : { backgroundColor: 'red' }}>
                                        {addons.status == 1 ? "Ativo" : "Inativo"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card >
            </div >
        </>
    )
}

export default RequestPerfil