const emojis = [
    {
      id: 1,
      code: 'U+1F600',
      emoji: '😀',
      name: 'grinning face',
      nameUrl: 'grinning-face',
    },
    {
      id: 2,
      code: 'U+1F603',
      emoji: '😃',
      name: 'grinning face with big eyes',
      nameUrl: 'grinning-face-with-big-eyes',
    },
    {
      id: 3,
      code: 'U+1F604',
      emoji: '😄',
      name: 'grinning face with smiling eyes',
      nameUrl: 'grinning-face-with-smiling-eyes',
    },
    {
      id: 4,
      code: 'U+1F601',
      emoji: '😁',
      name: 'beaming face with smiling eyes',
      nameUrl: 'beaming-face-with-smiling-eyes',
    },
    {
      id: 5,
      code: 'U+1F606',
      emoji: '😆',
      name: 'grinning squinting face',
      nameUrl: 'grinning-squinting-face',
    },
    {
      id: 6,
      code: 'U+1F605',
      emoji: '😅',
      name: 'grinning face with sweat',
      nameUrl: 'grinning-face-with-sweat',
    },
    {
      id: 7,
      code: 'U+1F923',
      emoji: '🤣',
      name: 'rolling on the floor laughing',
      nameUrl: 'rolling-on-the-floor-laughing',
    },
    {
      id: 8,
      code: 'U+1F602',
      emoji: '😂',
      name: 'face with tears of joy',
      nameUrl: 'face-with-tears-of-joy',
    },
    {
      id: 9,
      code: 'U+1F642',
      emoji: '🙂',
      name: 'slightly smiling face',
      nameUrl: 'slightly-smiling-face',
    },
    {
      id: 10,
      code: 'U+1F643',
      emoji: '🙃',
      name: 'upside-down face',
      nameUrl: 'upside-down-face',
    },
    {
      id: 11,
      code: 'U+1F609',
      emoji: '😉',
      name: 'winking face',
      nameUrl: 'winking-face',
    },
    {
      id: 12,
      code: 'U+1F60A',
      emoji: '😊',
      name: 'smiling face with smiling eyes',
      nameUrl: 'smiling-face-with-smiling-eyes',
    },
    {
      id: 13,
      code: 'U+1F607',
      emoji: '😇',
      name: 'smiling face with halo',
      nameUrl: 'smiling-face-with-halo',
    },
    {
      id: 14,
      code: 'U+1F970',
      emoji: '🥰',
      name: 'smiling face with hearts',
      nameUrl: 'smiling-face-with-hearts',
    },
    {
      id: 15,
      code: 'U+1F60D',
      emoji: '😍',
      name: 'smiling face with heart-eyes',
      nameUrl: 'smiling-face-with-heart-eyes',
    },
    {
      id: 16,
      code: 'U+1F929',
      emoji: '🤩',
      name: 'star-struck',
      nameUrl: 'star-struck',
    },
    {
      id: 17,
      code: 'U+1F618',
      emoji: '😘',
      name: 'face blowing a kiss',
      nameUrl: 'face-blowing-a-kiss',
    },
    {
      id: 18,
      code: 'U+1F617',
      emoji: '😗',
      name: 'kissing face',
      nameUrl: 'kissing-face',
    },
    {
      id: 19,
      code: 'U+263A',
      emoji: '☺',
      name: 'smiling face',
      nameUrl: 'smiling-face',
    },
    {
      id: 20,
      code: 'U+1F61A',
      emoji: '😚',
      name: 'kissing face with closed eyes',
      nameUrl: 'kissing-face-with-closed-eyes',
    },
    {
      id: 21,
      code: 'U+1F619',
      emoji: '😙',
      name: 'kissing face with smiling eyes',
      nameUrl: 'kissing-face-with-smiling-eyes',
    },
    {
      id: 22,
      code: 'U+1F60B',
      emoji: '😋',
      name: 'face savoring food',
      nameUrl: 'face-savoring-food',
    },
    {
      id: 23,
      code: 'U+1F61B',
      emoji: '😛',
      name: 'face with tongue',
      nameUrl: 'face-with-tongue',
    },
    {
      id: 24,
      code: 'U+1F61C',
      emoji: '😜',
      name: 'winking face with tongue',
      nameUrl: 'winking-face-with-tongue',
    },
    {
      id: 25,
      code: 'U+1F92A',
      emoji: '🤪',
      name: 'zany face',
      nameUrl: 'zany-face',
    },
    {
      id: 26,
      code: 'U+1F61D',
      emoji: '😝',
      name: 'squinting face with tongue',
      nameUrl: 'squinting-face-with-tongue',
    },
    {
      id: 27,
      code: 'U+1F911',
      emoji: '🤑',
      name: 'money-mouth face',
      nameUrl: 'money-mouth-face',
    },
    {
      id: 28,
      code: 'U+1F917',
      emoji: '🤗',
      name: 'hugging face',
      nameUrl: 'hugging-face',
    },
    {
      id: 29,
      code: 'U+1F92D',
      emoji: '🤭',
      name: 'face with hand over mouth',
      nameUrl: 'face-with-hand-over-mouth',
    },
    {
      id: 30,
      code: 'U+1F92B',
      emoji: '🤫',
      name: 'shushing face',
      nameUrl: 'shushing-face',
    },
    {
      id: 31,
      code: 'U+1F914',
      emoji: '🤔',
      name: 'thinking face',
      nameUrl: 'thinking-face',
    },
    {
      id: 32,
      code: 'U+1F910',
      emoji: '🤐',
      name: 'zipper-mouth face',
      nameUrl: 'zipper-mouth-face',
    },
    {
      id: 33,
      code: 'U+1F928',
      emoji: '🤨',
      name: 'face with raised eyebrow',
      nameUrl: 'face-with-raised-eyebrow',
    },
    {
      id: 34,
      code: 'U+1F610',
      emoji: '😐',
      name: 'neutral face',
      nameUrl: 'neutral-face',
    },
    {
      id: 35,
      code: 'U+1F611',
      emoji: '😑',
      name: 'expressionless face',
      nameUrl: 'expressionless-face',
    },
    {
      id: 36,
      code: 'U+1F636',
      emoji: '😶',
      name: 'face without mouth',
      nameUrl: 'face-without-mouth',
    },
    {
      id: 37,
      code: 'U+1F60F',
      emoji: '😏',
      name: 'smirking face',
      nameUrl: 'smirking-face',
    },
    {
      id: 38,
      code: 'U+1F612',
      emoji: '😒',
      name: 'unamused face',
      nameUrl: 'unamused-face',
    },
    {
      id: 39,
      code: 'U+1F644',
      emoji: '🙄',
      name: 'face with rolling eyes',
      nameUrl: 'face-with-rolling-eyes',
    },
    {
      id: 40,
      code: 'U+1F62C',
      emoji: '😬',
      name: 'grimacing face',
      nameUrl: 'grimacing-face',
    },
    {
      id: 41,
      code: 'U+1F925',
      emoji: '🤥',
      name: 'lying face',
      nameUrl: 'lying-face',
    },
    {
      id: 42,
      code: 'U+1F60C',
      emoji: '😌',
      name: 'relieved face',
      nameUrl: 'relieved-face',
    },
    {
      id: 43,
      code: 'U+1F614',
      emoji: '😔',
      name: 'pensive face',
      nameUrl: 'pensive-face',
    },
    {
      id: 44,
      code: 'U+1F62A',
      emoji: '😪',
      name: 'sleepy face',
      nameUrl: 'sleepy-face',
    },
    {
      id: 45,
      code: 'U+1F924',
      emoji: '🤤',
      name: 'drooling face',
      nameUrl: 'drooling-face',
    },
    {
      id: 46,
      code: 'U+1F634',
      emoji: '😴',
      name: 'sleeping face',
      nameUrl: 'sleeping-face',
    },
    {
      id: 47,
      code: 'U+1F637',
      emoji: '😷',
      name: 'face with medical mask',
      nameUrl: 'face-with-medical-mask',
    },
    {
      id: 48,
      code: 'U+1F912',
      emoji: '🤒',
      name: 'face with thermometer',
      nameUrl: 'face-with-thermometer',
    },
    {
      id: 49,
      code: 'U+1F915',
      emoji: '🤕',
      name: 'face with head-bandage',
      nameUrl: 'face-with-head-bandage',
    },
    {
      id: 50,
      code: 'U+1F922',
      emoji: '🤢',
      name: 'nauseated face',
      nameUrl: 'nauseated-face',
    },
    {
      id: 51,
      code: 'U+1F92E',
      emoji: '🤮',
      name: 'face vomiting',
      nameUrl: 'face-vomiting',
    },
    {
      id: 52,
      code: 'U+1F927',
      emoji: '🤧',
      name: 'sneezing face',
      nameUrl: 'sneezing-face',
    },
    {
      id: 53,
      code: 'U+1F975',
      emoji: '🥵',
      name: 'hot face',
      nameUrl: 'hot-face',
    },
    {
      id: 54,
      code: 'U+1F976',
      emoji: '🥶',
      name: 'cold face',
      nameUrl: 'cold-face',
    },
    {
      id: 55,
      code: 'U+1F974',
      emoji: '🥴',
      name: 'woozy face',
      nameUrl: 'woozy-face',
    },
    {
      id: 56,
      code: 'U+1F635',
      emoji: '😵',
      name: 'dizzy face',
      nameUrl: 'dizzy-face',
    },
    {
      id: 57,
      code: 'U+1F92F',
      emoji: '🤯',
      name: 'exploding head',
      nameUrl: 'exploding-head',
    },
    {
      id: 58,
      code: 'U+1F920',
      emoji: '🤠',
      name: 'cowboy hat face',
      nameUrl: 'cowboy-hat-face',
    },
    {
      id: 59,
      code: 'U+1F973',
      emoji: '🥳',
      name: 'partying face',
      nameUrl: 'partying-face',
    },
    {
      id: 60,
      code: 'U+1F60E',
      emoji: '😎',
      name: 'smiling face with sunglasses',
      nameUrl: 'smiling-face-with-sunglasses',
    },
    {
      id: 61,
      code: 'U+1F913',
      emoji: '🤓',
      name: 'nerd face',
      nameUrl: 'nerd-face',
    },
    {
      id: 62,
      code: 'U+1F9D0',
      emoji: '🧐',
      name: 'face with monocle',
      nameUrl: 'face-with-monocle',
    },
    {
      id: 63,
      code: 'U+1F615',
      emoji: '😕',
      name: 'confused face',
      nameUrl: 'confused-face',
    },
    {
      id: 64,
      code: 'U+1F61F',
      emoji: '😟',
      name: 'worried face',
      nameUrl: 'worried-face',
    },
    {
      id: 65,
      code: 'U+1F641',
      emoji: '🙁',
      name: 'slightly frowning face',
      nameUrl: 'slightly-frowning-face',
    },
    {
      id: 66,
      code: 'U+2639',
      emoji: '☹',
      name: 'frowning face',
      nameUrl: 'frowning-face',
    },
    {
      id: 67,
      code: 'U+1F62E',
      emoji: '😮',
      name: 'face with open mouth',
      nameUrl: 'face-with-open-mouth',
    },
    {
      id: 68,
      code: 'U+1F62F',
      emoji: '😯',
      name: 'hushed face',
      nameUrl: 'hushed-face',
    },
    {
      id: 69,
      code: 'U+1F632',
      emoji: '😲',
      name: 'astonished face',
      nameUrl: 'astonished-face',
    },
    {
      id: 70,
      code: 'U+1F633',
      emoji: '😳',
      name: 'flushed face',
      nameUrl: 'flushed-face',
    },
    {
      id: 71,
      code: 'U+1F97A',
      emoji: '🥺',
      name: 'pleading face',
      nameUrl: 'pleading-face',
    },
    {
      id: 72,
      code: 'U+1F626',
      emoji: '😦',
      name: 'frowning face with open mouth',
      nameUrl: 'frowning-face-with-open-mouth',
    },
    {
      id: 73,
      code: 'U+1F627',
      emoji: '😧',
      name: 'anguished face',
      nameUrl: 'anguished-face',
    },
    {
      id: 74,
      code: 'U+1F628',
      emoji: '😨',
      name: 'fearful face',
      nameUrl: 'fearful-face',
    },
    {
      id: 75,
      code: 'U+1F630',
      emoji: '😰',
      name: 'anxious face with sweat',
      nameUrl: 'anxious-face-with-sweat',
    },
    {
      id: 76,
      code: 'U+1F625',
      emoji: '😥',
      name: 'sad but relieved face',
      nameUrl: 'sad-but-relieved-face',
    },
    {
      id: 77,
      code: 'U+1F622',
      emoji: '😢',
      name: 'crying face',
      nameUrl: 'crying-face',
    },
    {
      id: 78,
      code: 'U+1F62D',
      emoji: '😭',
      name: 'loudly crying face',
      nameUrl: 'loudly-crying-face',
    },
    {
      id: 79,
      code: 'U+1F631',
      emoji: '😱',
      name: 'face screaming in fear',
      nameUrl: 'face-screaming-in-fear',
    },
    {
      id: 80,
      code: 'U+1F616',
      emoji: '😖',
      name: 'confounded face',
      nameUrl: 'confounded-face',
    },
    {
      id: 81,
      code: 'U+1F623',
      emoji: '😣',
      name: 'persevering face',
      nameUrl: 'persevering-face',
    },
    {
      id: 82,
      code: 'U+1F61E',
      emoji: '😞',
      name: 'disappointed face',
      nameUrl: 'disappointed-face',
    },
    {
      id: 83,
      code: 'U+1F613',
      emoji: '😓',
      name: 'downcast face with sweat',
      nameUrl: 'downcast-face-with-sweat',
    },
    {
      id: 84,
      code: 'U+1F629',
      emoji: '😩',
      name: 'weary face',
      nameUrl: 'weary-face',
    },
    {
      id: 85,
      code: 'U+1F62B',
      emoji: '😫',
      name: 'tired face',
      nameUrl: 'tired-face',
    },
    {
      id: 87,
      code: 'U+1F624',
      emoji: '😤',
      name: 'face with steam from nose',
      nameUrl: 'face-with-steam-from-nose',
    },
    {
      id: 88,
      code: 'U+1F621',
      emoji: '😡',
      name: 'pouting face',
      nameUrl: 'pouting-face',
    },
    {
      id: 89,
      code: 'U+1F620',
      emoji: '😠',
      name: 'angry face',
      nameUrl: 'angry-face',
    },
    {
      id: 90,
      code: 'U+1F92C',
      emoji: '🤬',
      name: 'face with symbols on mouth',
      nameUrl: 'face-with-symbols-on-mouth',
    },
    {
      id: 91,
      code: 'U+1F608',
      emoji: '😈',
      name: 'smiling face with horns',
      nameUrl: 'smiling-face-with-horns',
    },
    {
      id: 92,
      code: 'U+1F47F',
      emoji: '👿',
      name: 'angry face with horns',
      nameUrl: 'angry-face-with-horns',
    },
    {
      id: 93,
      code: 'U+1F480',
      emoji: '💀',
      name: 'skull',
      nameUrl: 'skull',
    },
    {
      id: 94,
      code: 'U+2620',
      emoji: '☠',
      name: 'skull and crossbones',
      nameUrl: 'skull-and-crossbones',
    },
    {
      id: 95,
      code: 'U+1F4A9',
      emoji: '💩',
      name: 'pile of poo',
      nameUrl: 'pile-of-poo',
    },
    {
      id: 96,
      code: 'U+1F921',
      emoji: '🤡',
      name: 'clown face',
      nameUrl: 'clown-face',
    },
    {
      id: 97,
      code: 'U+1F479',
      emoji: '👹',
      name: 'ogre',
      nameUrl: 'ogre',
    },
    {
      id: 98,
      code: 'U+1F47A',
      emoji: '👺',
      name: 'goblin',
      nameUrl: 'goblin',
    },
    {
      id: 99,
      code: 'U+1F47B',
      emoji: '👻',
      name: 'ghost',
      nameUrl: 'ghost',
    },
    {
      id: 100,
      code: 'U+1F47D',
      emoji: '👽',
      name: 'alien',
      nameUrl: 'alien',
    },
    {
      id: 101,
      code: 'U+1F47E',
      emoji: '👾',
      name: 'alien monster',
      nameUrl: 'alien-monster',
    },
    {
      id: 102,
      code: 'U+1F916',
      emoji: '🤖',
      name: 'robot',
      nameUrl: 'robot',
    },
    {
      id: 103,
      code: 'U+1F63A',
      emoji: '😺',
      name: 'grinning cat',
      nameUrl: 'grinning-cat',
    },
    {
      id: 104,
      code: 'U+1F638',
      emoji: '😸',
      name: 'grinning cat with smiling eyes',
      nameUrl: 'grinning-cat-with-smiling-eyes',
    },
    {
      id: 105,
      code: 'U+1F639',
      emoji: '😹',
      name: 'cat with tears of joy',
      nameUrl: 'cat-with-tears-of-joy',
    },
    {
      id: 106,
      code: 'U+1F63B',
      emoji: '😻',
      name: 'smiling cat with heart-eyes',
      nameUrl: 'smiling-cat-with-heart-eyes',
    },
    {
      id: 107,
      code: 'U+1F63C',
      emoji: '😼',
      name: 'cat with wry smile',
      nameUrl: 'cat-with-wry-smile',
    },
    {
      id: 108,
      code: 'U+1F63D',
      emoji: '😽',
      name: 'kissing cat',
      nameUrl: 'kissing-cat',
    },
    {
      id: 109,
      code: 'U+1F640',
      emoji: '🙀',
      name: 'weary cat',
      nameUrl: 'weary-cat',
    },
    {
      id: 110,
      code: 'U+1F63F',
      emoji: '😿',
      name: 'crying cat',
      nameUrl: 'crying-cat',
    },
    {
      id: 111,
      code: 'U+1F63E',
      emoji: '😾',
      name: 'pouting cat',
      nameUrl: 'pouting-cat',
    },
    {
      id: 112,
      code: 'U+1F648',
      emoji: '🙈',
      name: 'see-no-evil monkey',
      nameUrl: 'see-no-evil-monkey',
    },
    {
      id: 113,
      code: 'U+1F649',
      emoji: '🙉',
      name: 'hear-no-evil monkey',
      nameUrl: 'hear-no-evil-monkey',
    },
    {
      id: 114,
      code: 'U+1F64A',
      emoji: '🙊',
      name: 'speak-no-evil monkey',
      nameUrl: 'speak-no-evil-monkey',
    },
    {
      id: 115,
      code: 'U+1F48B',
      emoji: '💋',
      name: 'kiss mark',
      nameUrl: 'kiss-mark',
    },
    {
      id: 116,
      code: 'U+1F48C',
      emoji: '💌',
      name: 'love letter',
      nameUrl: 'love-letter',
    },
    {
      id: 117,
      code: 'U+1F498',
      emoji: '💘',
      name: 'heart with arrow',
      nameUrl: 'heart-with-arrow',
    },
    {
      id: 118,
      code: 'U+1F49D',
      emoji: '💝',
      name: 'heart with ribbon',
      nameUrl: 'heart-with-ribbon',
    },
    {
      id: 119,
      code: 'U+1F496',
      emoji: '💖',
      name: 'sparkling heart',
      nameUrl: 'sparkling-heart',
    },
    {
      id: 120,
      code: 'U+1F497',
      emoji: '💗',
      name: 'growing heart',
      nameUrl: 'growing-heart',
    },
    {
      id: 121,
      code: 'U+1F493',
      emoji: '💓',
      name: 'beating heart',
      nameUrl: 'beating-heart',
    },
    {
      id: 122,
      code: 'U+1F49E',
      emoji: '💞',
      name: 'revolving hearts',
      nameUrl: 'revolving-hearts',
    },
    {
      id: 123,
      code: 'U+1F495',
      emoji: '💕',
      name: 'two hearts',
      nameUrl: 'two-hearts',
    },
    {
      id: 124,
      code: 'U+1F49F',
      emoji: '💟',
      name: 'heart decoration',
      nameUrl: 'heart-decoration',
    },
    {
      id: 125,
      code: 'U+2763',
      emoji: '❣️',
      name: 'heart exclamation',
      nameUrl: 'heart-exclamation',
    },
    {
      id: 126,
      code: 'U+1F494',
      emoji: '💔',
      name: 'broken heart',
      nameUrl: 'broken-heart',
    },
    {
      id: 127,
      code: 'U+2764',
      emoji: '❤️️',
      name: 'red heart',
      nameUrl: 'red-heart',
    },
    {
      id: 128,
      code: 'U+1F9E1',
      emoji: '🧡',
      name: 'orange heart',
      nameUrl: 'orange-heart',
    },
    {
      id: 129,
      code: 'U+1F49B',
      emoji: '💛',
      name: 'yellow heart',
      nameUrl: 'yellow-heart',
    },
    {
      id: 130,
      code: 'U+1F49A',
      emoji: '💚',
      name: 'green heart',
      nameUrl: 'green-heart',
    },
    {
      id: 131,
      code: 'U+1F499',
      emoji: '💙',
      name: 'blue heart',
      nameUrl: 'blue-heart',
    },
    {
      id: 132,
      code: 'U+1F49C',
      emoji: '💜',
      name: 'purple heart',
      nameUrl: 'purple-heart',
    },
    {
      id: 134,
      code: 'U+1F5A4',
      emoji: '🖤',
      name: 'black heart',
      nameUrl: 'black-heart',
    },
    {
      id: 136,
      code: 'U+1F4AF',
      emoji: '💯',
      name: 'hundred points',
      nameUrl: 'hundred-points',
    },
    {
      id: 137,
      code: 'U+1F4A2',
      emoji: '💢',
      name: 'anger symbol',
      nameUrl: 'anger-symbol',
    },
    {
      id: 138,
      code: 'U+1F4A5',
      emoji: '💥',
      name: 'collision',
      nameUrl: 'collision',
    },
    {
      id: 139,
      code: 'U+1F4AB',
      emoji: '💫',
      name: 'dizzy',
      nameUrl: 'dizzy',
    },
    {
      id: 140,
      code: 'U+1F4A6',
      emoji: '💦',
      name: 'sweat droplets',
      nameUrl: 'sweat-droplets',
    },
    {
      id: 141,
      code: 'U+1F4A8',
      emoji: '💨',
      name: 'dashing away',
      nameUrl: 'dashing-away',
    },
    {
      id: 142,
      code: 'U+1F573',
      emoji: '🕳',
      name: 'hole',
      nameUrl: 'hole',
    },
    {
      id: 143,
      code: 'U+1F4A3',
      emoji: '💣',
      name: 'bomb',
      nameUrl: 'bomb',
    },
    {
      id: 144,
      code: 'U+1F4AC',
      emoji: '💬',
      name: 'speech balloon',
      nameUrl: 'speech-balloon',
    },
    {
      id: 145,
      code: 'U+1F441 U+FE0F U+200D U+1F5E8 U+FE0F',
      emoji: '👁️‍🗨️',
      name: 'eye in speech bubble',
      nameUrl: 'eye-in-speech-bubble',
    },
    {
      id: 146,
      code: 'U+1F5E8',
      emoji: '🗨',
      name: 'left speech bubble',
      nameUrl: 'left-speech-bubble',
    },
    {
      id: 147,
      code: 'U+1F5EF',
      emoji: '🗯',
      name: 'right anger bubble',
      nameUrl: 'right-anger-bubble',
    },
    {
      id: 148,
      code: 'U+1F4AD',
      emoji: '💭',
      name: 'thought balloon',
      nameUrl: 'thought-balloon',
    },
    {
      id: 149,
      code: 'U+1F4A4',
      emoji: '💤',
      name: 'zzz',
      nameUrl: 'zzz',
      },
    {
      id: 150,
      code: 'U+1F44B',
      emoji: '👋',
      name: 'waving hand',
      nameUrl: 'waving-hand',
    },
    {
      id: 151,
      code: 'U+1F91A',
      emoji: '🤚',
      name: 'raised back of hand',
      nameUrl: 'raised-back-of-hand',
    },
    {
      id: 152,
      code: 'U+1F590',
      emoji: '🖐',
      name: 'hand with fingers splayed',
      nameUrl: 'hand-with-fingers-splayed',
    },
    {
      id: 153,
      code: 'U+270B',
      emoji: '✋',
      name: 'raised hand',
      nameUrl: 'raised-hand',
    },
    {
      id: 154,
      code: 'U+1F596',
      emoji: '🖖',
      name: 'vulcan salute',
      nameUrl: 'vulcan-salute',
    },
    {
      id: 155,
      code: 'U+1F44C',
      emoji: '👌',
      name: 'OK hand',
      nameUrl: 'ok-hand',
    },
    {
      id: 157,
      code: 'U+270C',
      emoji: '✌',
      name: 'victory hand',
      nameUrl: 'victory-hand',
    },
    {
      id: 158,
      code: 'U+1F91E',
      emoji: '🤞',
      name: 'crossed fingers',
      nameUrl: 'crossed-fingers',
    },
    {
      id: 159,
      code: 'U+1F91F',
      emoji: '🤟',
      name: 'love-you gesture',
      nameUrl: 'love-you-gesture',
    },
    {
      id: 160,
      code: 'U+1F918',
      emoji: '🤘',
      name: 'sign of the horns',
      nameUrl: 'sign-of-the-horns',
    },
    {
      id: 161,
      code: 'U+1F919',
      emoji: '🤙',
      name: 'call me hand',
      nameUrl: 'call-me-hand',
    },
    {
      id: 162,
      code: 'U+1F448',
      emoji: '👈',
      name: 'backhand index pointing left',
      nameUrl: 'backhand-index-pointing-left',
    },
    {
      id: 163,
      code: 'U+1F449',
      emoji: '👉',
      name: 'backhand index pointing right',
      nameUrl: 'backhand-index-pointing-right',
    },
    {
      id: 164,
      code: 'U+1F446',
      emoji: '👆',
      name: 'backhand index pointing up',
      nameUrl: 'backhand-index-pointing-up',
    },
    {
      id: 165,
      code: 'U+1F595',
      emoji: '🖕',
      name: 'middle finger',
      nameUrl: 'middle-finger',
    },
    {
      id: 166,
      code: 'U+1F447',
      emoji: '👇',
      name: 'backhand index pointing down',
      nameUrl: 'backhand-index-pointing-down',
    },
    {
      id: 167,
      code: 'U+261D',
      emoji: '☝',
      name: 'index pointing up',
      nameUrl: 'index-pointing-up',
    },
    {
      id: 168,
      code: 'U+1F44D',
      emoji: '👍',
      name: 'thumbs up',
      nameUrl: 'thumbs-up',
    },
    {
      id: 169,
      code: 'U+1F44E',
      emoji: '👎',
      name: 'thumbs down',
      nameUrl: 'thumbs-down',
    },
    {
      id: 170,
      code: 'U+270A',
      emoji: '✊',
      name: 'raised fist',
      nameUrl: 'raised-fist',
    },
    {
      id: 171,
      code: 'U+1F44A',
      emoji: '👊',
      name: 'oncoming fist',
      nameUrl: 'oncoming-fist',
    },
    {
      id: 172,
      code: 'U+1F91B',
      emoji: '🤛',
      name: 'left-facing fist',
      nameUrl: 'left-facing-fist',
    },
    {
      id: 173,
      code: 'U+1F91C',
      emoji: '🤜',
      name: 'right-facing fist',
      nameUrl: 'right-facing-fist',
    },
    {
      id: 174,
      code: 'U+1F44F',
      emoji: '👏',
      name: 'clapping hands',
      nameUrl: 'clapping-hands',
    },
    {
      id: 175,
      code: 'U+1F64C',
      emoji: '🙌',
      name: 'raising hands',
      nameUrl: 'raising-hands',
    },
    {
      id: 176,
      code: 'U+1F450',
      emoji: '👐',
      name: 'open hands',
      nameUrl: 'open-hands',
    },
    {
      id: 177,
      code: 'U+1F932',
      emoji: '🤲',
      name: 'palms up together',
      nameUrl: 'palms-up-together',
    },
    {
      id: 178,
      code: 'U+1F91D',
      emoji: '🤝',
      name: 'handshake',
      nameUrl: 'handshake',
    },
    {
      id: 179,
      code: 'U+1F64F',
      emoji: '🙏',
      name: 'folded hands',
      nameUrl: 'folded-hands',
    },
    {
      id: 180,
      code: 'U+270D',
      emoji: '✍',
      name: 'writing hand',
      nameUrl: 'writing-hand',
    },
    {
      id: 181,
      code: 'U+1F485',
      emoji: '💅',
      name: 'nail polish',
      nameUrl: 'nail-polish',
    },
    {
      id: 182,
      code: 'U+1F933',
      emoji: '🤳',
      name: 'selfie',
      nameUrl: 'selfie',
    },
    {
      id: 183,
      code: 'U+1F4AA',
      emoji: '💪',
      name: 'flexed biceps',
      nameUrl: 'flexed-biceps',
    },
    {
      id: 186,
      code: 'U+1F9B5',
      emoji: '🦵',
      name: 'leg',
      nameUrl: 'leg',
    },
    {
      id: 187,
      code: 'U+1F9B6',
      emoji: '🦶',
      name: 'foot',
      nameUrl: 'foot',
    },
    {
      id: 188,
      code: 'U+1F442',
      emoji: '👂',
      name: 'ear',
      nameUrl: 'ear',
    },
    {
      id: 190,
      code: 'U+1F443',
      emoji: '👃',
      name: 'nose',
      nameUrl: 'nose',
    },
    {
      id: 191,
      code: 'U+1F9E0',
      emoji: '🧠',
      name: 'brain',
      nameUrl: 'brain',
    },
    {
      id: 192,
      code: 'U+1F9B7',
      emoji: '🦷',
      name: 'tooth',
      nameUrl: 'tooth',
    },
    {
      id: 193,
      code: 'U+1F9B4',
      emoji: '🦴',
      name: 'bone',
      nameUrl: 'bone',
    },
    {
      id: 194,
      code: 'U+1F440',
      emoji: '👀',
      name: 'eyes',
      nameUrl: 'eyes',
    },
    {
      id: 195,
      code: 'U+1F441',
      emoji: '👁',
      name: 'eye',
      nameUrl: 'eye',
    },
    {
      id: 196,
      code: 'U+1F445',
      emoji: '👅',
      name: 'tongue',
      nameUrl: 'tongue',
    },
    {
      id: 197,
      code: 'U+1F444',
      emoji: '👄',
      name: 'mouth',
      nameUrl: 'mouth',
    },
    {
      id: 198,
      code: 'U+1F476',
      emoji: '👶',
      name: 'baby',
      nameUrl: 'baby',
    },
    {
      id: 199,
      code: 'U+1F9D2',
      emoji: '🧒',
      name: 'child',
      nameUrl: 'child',
    },
    {
      id: 200,
      code: 'U+1F466',
      emoji: '👦',
      name: 'boy',
      nameUrl: 'boy',
    },
    {
      id: 201,
      code: 'U+1F467',
      emoji: '👧',
      name: 'girl',
      nameUrl: 'girl',
    },
    {
      id: 202,
      code: 'U+1F9D1',
      emoji: '🧑',
      name: 'person',
      nameUrl: 'person',
    },
    {
      id: 203,
      code: 'U+1F471',
      emoji: '👱',
      name: 'person: blond hair',
      nameUrl: 'person-blond-hair',
    },
    {
      id: 204,
      code: 'U+1F468',
      emoji: '👨',
      name: 'man',
      nameUrl: 'man',
    },
    {
      id: 205,
      code: 'U+1F9D4',
      emoji: '🧔',
      name: 'man: beard',
      nameUrl: 'man-beard',
    },
    {
      id: 206,
      code: 'U+1F471 U+200D U+2642 U+FE0F',
      emoji: '👱‍♂️',
      name: 'man: blond hair',
      nameUrl: 'man-blond-hair',
    },
    {
      id: 207,
      code: 'U+1F468 U+200D U+1F9B0',
      emoji: '👨‍🦰',
      name: 'man: red hair',
      nameUrl: 'man-red-hair',
    },
    {
      id: 208,
      code: 'U+1F468 U+200D U+1F9B1',
      emoji: '👨‍🦱',
      name: 'man: curly hair',
      nameUrl: 'man-curly-hair',
    },
    {
      id: 209,
      code: 'U+1F468 U+200D U+1F9B3',
      emoji: '👨‍🦳',
      name: 'man: white hair',
      nameUrl: 'man-white-hair',
    },
    {
      id: 210,
      code: 'U+1F468 U+200D U+1F9B2',
      emoji: '👨‍🦲',
      name: 'man: bald',
      nameUrl: 'man-bald',
    },
    {
      id: 211,
      code: 'U+1F469',
      emoji: '👩',
      name: 'woman',
      nameUrl: 'woman',
    },
    {
      id: 212,
      code: 'U+1F471 U+200D U+2640 U+FE0F',
      emoji: '👱‍♀️',
      name: 'woman: blond hair',
      nameUrl: 'woman-blond-hair',
    },
    {
      id: 213,
      code: 'U+1F469 U+200D U+1F9B0',
      emoji: '👩‍🦰',
      name: 'woman: red hair',
      nameUrl: 'woman-red-hair',
    },
    {
      id: 214,
      code: 'U+1F469 U+200D U+1F9B1',
      emoji: '👩‍🦱',
      name: 'woman: curly hair',
      nameUrl: 'woman-curly-hair',
    },
    {
      id: 215,
      code: 'U+1F469 U+200D U+1F9B3',
      emoji: '👩‍🦳',
      name: 'woman: white hair',
      nameUrl: 'woman-white-hair',
    },
    {
      id: 216,
      code: 'U+1F469 U+200D U+1F9B2',
      emoji: '👩‍🦲',
      name: 'woman: bald',
      nameUrl: 'woman-bald',
    },
    {
      id: 217,
      code: 'U+1F9D3',
      emoji: '🧓',
      name: 'older person',
      nameUrl: 'older-person',
    },
    {
      id: 218,
      code: 'U+1F474',
      emoji: '👴',
      name: 'old man',
      nameUrl: 'old-man',
    },
    {
      id: 219,
      code: 'U+1F475',
      emoji: '👵',
      name: 'old woman',
      nameUrl: 'old-woman',
    },
    {
      id: 220,
      code: 'U+1F64D',
      emoji: '🙍',
      name: 'person frowning',
      nameUrl: 'person-frowning',
    },
    {
      id: 221,
      code: 'U+1F64D U+200D U+2642 U+FE0F',
      emoji: '🙍‍♂️',
      name: 'man frowning',
      nameUrl: 'man-frowning',
    },
    {
      id: 222,
      code: 'U+1F64D U+200D U+2640 U+FE0F',
      emoji: '🙍‍♀️',
      name: 'woman frowning',
      nameUrl: 'woman-frowning',
    },
    {
      id: 223,
      code: 'U+1F64E',
      emoji: '🙎',
      name: 'person pouting',
      nameUrl: 'person-pouting',
    },
    {
      id: 224,
      code: 'U+1F64E U+200D U+2642 U+FE0F',
      emoji: '🙎‍♂️',
      name: 'man pouting',
      nameUrl: 'man-pouting',
    },
    {
      id: 225,
      code: 'U+1F64E U+200D U+2640 U+FE0F',
      emoji: '🙎‍♀️',
      name: 'woman pouting',
      nameUrl: 'woman-pouting',
    },
    {
      id: 226,
      code: 'U+1F645',
      emoji: '🙅',
      name: 'person gesturing NO',
      nameUrl: 'person-gesturing-no',
    },
    {
      id: 227,
      code: 'U+1F645 U+200D U+2642 U+FE0F',
      emoji: '🙅‍♂️',
      name: 'man gesturing NO',
      nameUrl: 'man-gesturing-no',
    },
    {
      id: 228,
      code: 'U+1F645 U+200D U+2640 U+FE0F',
      emoji: '🙅‍♀️',
      name: 'woman gesturing NO',
      nameUrl: 'woman-gesturing-no',
    },
    {
      id: 229,
      code: 'U+1F646',
      emoji: '🙆',
      name: 'person gesturing OK',
      nameUrl: 'person-gesturing-ok',
    },
    {
      id: 230,
      code: 'U+1F646 U+200D U+2642 U+FE0F',
      emoji: '🙆‍♂️',
      name: 'man gesturing OK',
      nameUrl: 'man-gesturing-ok',
    },
    {
      id: 231,
      code: 'U+1F646 U+200D U+2640 U+FE0F',
      emoji: '🙆‍♀️',
      name: 'woman gesturing OK',
      nameUrl: 'woman-gesturing-ok',
    },
    {
      id: 232,
      code: 'U+1F481',
      emoji: '💁',
      name: 'person tipping hand',
      nameUrl: 'person-tipping-hand',
    },
    {
      id: 233,
      code: 'U+1F481 U+200D U+2642 U+FE0F',
      emoji: '💁‍♂️',
      name: 'man tipping hand',
      nameUrl: 'man-tipping-hand',
    },
    {
      id: 234,
      code: 'U+1F481 U+200D U+2640 U+FE0F',
      emoji: '💁‍♀️',
      name: 'woman tipping hand',
      nameUrl: 'woman-tipping-hand',
    },
    {
      id: 235,
      code: 'U+1F64B',
      emoji: '🙋',
      name: 'person raising hand',
      nameUrl: 'person-raising-hand',
    },
    {
      id: 236,
      code: 'U+1F64B U+200D U+2642 U+FE0F',
      emoji: '🙋‍♂️',
      name: 'man raising hand',
      nameUrl: 'man-raising-hand',
    },
    {
      id: 237,
      code: 'U+1F64B U+200D U+2640 U+FE0F',
      emoji: '🙋‍♀️',
      name: 'woman raising hand',
      nameUrl: 'woman-raising-hand',
    },
    {
      id: 241,
      code: 'U+1F647',
      emoji: '🙇',
      name: 'person bowing',
      nameUrl: 'person-bowing',
    },
    {
      id: 242,
      code: 'U+1F647 U+200D U+2642 U+FE0F',
      emoji: '🙇‍♂️',
      name: 'man bowing',
      nameUrl: 'man-bowing',
    },
    {
      id: 243,
      code: 'U+1F647 U+200D U+2640 U+FE0F',
      emoji: '🙇‍♀️',
      name: 'woman bowing',
      nameUrl: 'woman-bowing',
    },
    {
      id: 244,
      code: 'U+1F926',
      emoji: '🤦',
      name: 'person facepalming',
      nameUrl: 'person-facepalming',
    },
    {
      id: 245,
      code: 'U+1F926 U+200D U+2642 U+FE0F',
      emoji: '🤦‍♂️',
      name: 'man facepalming',
      nameUrl: 'man-facepalming',
    },
    {
      id: 246,
      code: 'U+1F926 U+200D U+2640 U+FE0F',
      emoji: '🤦‍♀️',
      name: 'woman facepalming',
      nameUrl: 'woman-facepalming',
    },
    {
      id: 247,
      code: 'U+1F937',
      emoji: '🤷',
      name: 'person shrugging',
      nameUrl: 'person-shrugging',
    },
    {
      id: 248,
      code: 'U+1F937 U+200D U+2642 U+FE0F',
      emoji: '🤷‍♂️',
      name: 'man shrugging',
      nameUrl: 'man-shrugging',
    },
    {
      id: 249,
      code: 'U+1F937 U+200D U+2640 U+FE0F',
      emoji: '🤷‍♀️',
      name: 'woman shrugging',
      nameUrl: 'woman-shrugging',
    },
    {
      id: 250,
      code: 'U+1F468 U+200D U+2695 U+FE0F',
      emoji: '👨‍⚕️',
      name: 'man health worker',
      nameUrl: 'man-health-worker',
    },
    {
      id: 251,
      code: 'U+1F469 U+200D U+2695 U+FE0F',
      emoji: '👩‍⚕️',
      name: 'woman health worker',
      nameUrl: 'woman-health-worker',
    },
    {
      id: 252,
      code: 'U+1F468 U+200D U+1F393',
      emoji: '👨‍🎓',
      name: 'man student',
      nameUrl: 'man-student',
    },
    {
      id: 253,
      code: 'U+1F469 U+200D U+1F393',
      emoji: '👩‍🎓',
      name: 'woman student',
      nameUrl: 'woman-student',
    },
    {
      id: 254,
      code: 'U+1F468 U+200D U+1F3EB',
      emoji: '👨‍🏫',
      name: 'man teacher',
      nameUrl: 'man-teacher',
    },
    {
      id: 255,
      code: 'U+1F469 U+200D U+1F3EB',
      emoji: '👩‍🏫',
      name: 'woman teacher',
      nameUrl: 'woman-teacher',
    },
    {
      id: 256,
      code: 'U+1F468 U+200D U+2696 U+FE0F',
      emoji: '👨‍⚖️',
      name: 'man judge',
      nameUrl: 'man-judge',
    },
    {
      id: 257,
      code: 'U+1F469 U+200D U+2696 U+FE0F',
      emoji: '👩‍⚖️',
      name: 'woman judge',
      nameUrl: 'woman-judge',
    },
    {
      id: 258,
      code: 'U+1F468 U+200D U+1F33E',
      emoji: '👨‍🌾',
      name: 'man farmer',
      nameUrl: 'man-farmer',
    },
    {
      id: 259,
      code: 'U+1F469 U+200D U+1F33E',
      emoji: '👩‍🌾',
      name: 'woman farmer',
      nameUrl: 'woman-farmer',
    },
    {
      id: 260,
      code: 'U+1F468 U+200D U+1F373',
      emoji: '👨‍🍳',
      name: 'man cook',
      nameUrl: 'man-cook',
    },
    {
      id: 261,
      code: 'U+1F469 U+200D U+1F373',
      emoji: '👩‍🍳',
      name: 'woman cook',
      nameUrl: 'woman-cook',
    },
    {
      id: 262,
      code: 'U+1F468 U+200D U+1F527',
      emoji: '👨‍🔧',
      name: 'man mechanic',
      nameUrl: 'man-mechanic',
    },
    {
      id: 263,
      code: 'U+1F469 U+200D U+1F527',
      emoji: '👩‍🔧',
      name: 'woman mechanic',
      nameUrl: 'woman-mechanic',
    },
    {
      id: 264,
      code: 'U+1F468 U+200D U+1F3ED',
      emoji: '👨‍🏭',
      name: 'man factory worker',
      nameUrl: 'man-factory-worker',
    },
    {
      id: 265,
      code: 'U+1F469 U+200D U+1F3ED',
      emoji: '👩‍🏭',
      name: 'woman factory worker',
      nameUrl: 'woman-factory-worker',
    },
    {
      id: 266,
      code: 'U+1F468 U+200D U+1F4BC',
      emoji: '👨‍💼',
      name: 'man office worker',
      nameUrl: 'man-office-worker',
    },
    {
      id: 267,
      code: 'U+1F469 U+200D U+1F4BC',
      emoji: '👩‍💼',
      name: 'woman office worker',
      nameUrl: 'woman-office-worker',
    },
    {
      id: 268,
      code: 'U+1F468 U+200D U+1F52C',
      emoji: '👨‍🔬',
      name: 'man scientist',
      nameUrl: 'man-scientist',
    },
    {
      id: 269,
      code: 'U+1F469 U+200D U+1F52C',
      emoji: '👩‍🔬',
      name: 'woman scientist',
      nameUrl: 'woman-scientist',
    },
    {
      id: 270,
      code: 'U+1F468 U+200D U+1F4BB',
      emoji: '👨‍💻',
      name: 'man technologist',
      nameUrl: 'man-technologist',
    },
    {
      id: 271,
      code: 'U+1F469 U+200D U+1F4BB',
      emoji: '👩‍💻',
      name: 'woman technologist',
      nameUrl: 'woman-technologist',
    },
    {
      id: 272,
      code: 'U+1F468 U+200D U+1F3A4',
      emoji: '👨‍🎤',
      name: 'man singer',
      nameUrl: 'man-singer',
    },
    {
      id: 273,
      code: 'U+1F469 U+200D U+1F3A4',
      emoji: '👩‍🎤',
      name: 'woman singer',
      nameUrl: 'woman-singer',
    },
    {
      id: 274,
      code: 'U+1F468 U+200D U+1F3A8',
      emoji: '👨‍🎨',
      name: 'man artist',
      nameUrl: 'man-artist',
    },
    {
      id: 275,
      code: 'U+1F469 U+200D U+1F3A8',
      emoji: '👩‍🎨',
      name: 'woman artist',
      nameUrl: 'woman-artist',
    },
    {
      id: 276,
      code: 'U+1F468 U+200D U+2708 U+FE0F',
      emoji: '👨‍✈️',
      name: 'man pilot',
      nameUrl: 'man-pilot',
    },
    {
      id: 277,
      code: 'U+1F469 U+200D U+2708 U+FE0F',
      emoji: '👩‍✈️',
      name: 'woman pilot',
      nameUrl: 'woman-pilot',
    },
    {
      id: 278,
      code: 'U+1F468 U+200D U+1F680',
      emoji: '👨‍🚀',
      name: 'man astronaut',
      nameUrl: 'man-astronaut',
    },
    {
      id: 279,
      code: 'U+1F469 U+200D U+1F680',
      emoji: '👩‍🚀',
      name: 'woman astronaut',
      nameUrl: 'woman-astronaut',
    },
    {
      id: 280,
      code: 'U+1F468 U+200D U+1F692',
      emoji: '👨‍🚒',
      name: 'man firefighter',
      nameUrl: 'man-firefighter',
    },
    {
      id: 281,
      code: 'U+1F469 U+200D U+1F692',
      emoji: '👩‍🚒',
      name: 'woman firefighter',
      nameUrl: 'woman-firefighter',
    },
    {
      id: 282,
      code: 'U+1F46E',
      emoji: '👮',
      name: 'police officer',
      nameUrl: 'police-officer',
    },
    {
      id: 283,
      code: 'U+1F46E U+200D U+2642 U+FE0F',
      emoji: '👮‍♂️',
      name: 'man police officer',
      nameUrl: 'man-police-officer',
    },
    {
      id: 284,
      code: 'U+1F46E U+200D U+2640 U+FE0F',
      emoji: '👮‍♀️',
      name: 'woman police officer',
      nameUrl: 'woman-police-officer',
    },
    {
      id: 285,
      code: 'U+1F575',
      emoji: '🕵',
      name: 'detective',
      nameUrl: 'detective',
    },
    {
      id: 286,
      code: 'U+1F575 U+FE0F U+200D U+2642 U+FE0F',
      emoji: '🕵️‍♂️',
      name: 'man detective',
      nameUrl: 'man-detective',
    },
    {
      id: 287,
      code: 'U+1F575 U+FE0F U+200D U+2640 U+FE0F',
      emoji: '🕵️‍♀️',
      name: 'woman detective',
      nameUrl: 'woman-detective',
    },
    {
      id: 288,
      code: 'U+1F482',
      emoji: '💂',
      name: 'guard',
      nameUrl: 'guard',
    },
    {
      id: 289,
      code: 'U+1F482 U+200D U+2642 U+FE0F',
      emoji: '💂‍♂️',
      name: 'man guard',
      nameUrl: 'man-guard',
    },
    {
      id: 290,
      code: 'U+1F482 U+200D U+2640 U+FE0F',
      emoji: '💂‍♀️',
      name: 'woman guard',
      nameUrl: 'woman-guard',
    },
    {
      id: 291,
      code: 'U+1F477',
      emoji: '👷',
      name: 'construction worker',
      nameUrl: 'construction-worker',
    },
    {
      id: 292,
      code: 'U+1F477 U+200D U+2642 U+FE0F',
      emoji: '👷‍♂️',
      name: 'man construction worker',
      nameUrl: 'man-construction-worker',
    },
    {
      id: 293,
      code: 'U+1F477 U+200D U+2640 U+FE0F',
      emoji: '👷‍♀️',
      name: 'woman construction worker',
      nameUrl: 'woman-construction-worker',
    },
    {
      id: 294,
      code: 'U+1F934',
      emoji: '🤴',
      name: 'prince',
      nameUrl: 'prince',
    },
    {
      id: 295,
      code: 'U+1F478',
      emoji: '👸',
      name: 'princess',
      nameUrl: 'princess',
    },
    {
      id: 296,
      code: 'U+1F473',
      emoji: '👳',
      name: 'person wearing turban',
      nameUrl: 'person-wearing-turban',
    },
    {
      id: 297,
      code: 'U+1F473 U+200D U+2642 U+FE0F',
      emoji: '👳‍♂️',
      name: 'man wearing turban',
      nameUrl: 'man-wearing-turban',
    },
    {
      id: 298,
      code: 'U+1F473 U+200D U+2640 U+FE0F',
      emoji: '👳‍♀️',
      name: 'woman wearing turban',
      nameUrl: 'woman-wearing-turban',
    },
    {
      id: 299,
      code: 'U+1F472',
      emoji: '👲',
      name: 'man with Chinese cap',
      nameUrl: 'man-with-chinese-cap',
    },
    {
      id: 300,
      code: 'U+1F9D5',
      emoji: '🧕',
      name: 'woman with headscarf',
      nameUrl: 'woman-with-headscarf',
    },
    {
      id: 301,
      code: 'U+1F935',
      emoji: '🤵',
      name: 'man in tuxedo',
      nameUrl: 'man-in-tuxedo',
    },
    {
      id: 302,
      code: 'U+1F470',
      emoji: '👰',
      name: 'bride with veil',
      nameUrl: 'bride-with-veil',
    },
    {
      id: 303,
      code: 'U+1F930',
      emoji: '🤰',
      name: 'pregnant woman',
      nameUrl: 'pregnant-woman',
    },
    {
      id: 304,
      code: 'U+1F931',
      emoji: '🤱',
      name: 'breast-feeding',
      nameUrl: 'breast-feeding',
    },
    {
      id: 305,
      code: 'U+1F47C',
      emoji: '👼',
      name: 'baby angel',
      nameUrl: 'baby-angel',
    },
    {
      id: 306,
      code: 'U+1F385',
      emoji: '🎅',
      name: 'Santa Claus',
      nameUrl: 'santa-claus',
    },
    {
      id: 307,
      code: 'U+1F936',
      emoji: '🤶',
      name: 'Mrs. Claus',
      nameUrl: 'mrs-claus',
    },
    {
      id: 308,
      code: 'U+1F9B8',
      emoji: '🦸',
      name: 'superhero',
      nameUrl: 'superhero',
    },
    {
      id: 309,
      code: 'U+1F9B8 U+200D U+2642 U+FE0F',
      emoji: '🦸‍♂️',
      name: 'man superhero',
      nameUrl: 'man-superhero',
    },
    {
      id: 310,
      code: 'U+1F9B8 U+200D U+2640 U+FE0F',
      emoji: '🦸‍♀️',
      name: 'woman superhero',
      nameUrl: 'woman-superhero',
    },
    {
      id: 311,
      code: 'U+1F9B9',
      emoji: '🦹',
      name: 'supervillain',
      nameUrl: 'supervillain',
    },
    {
      id: 312,
      code: 'U+1F9B9 U+200D U+2642 U+FE0F',
      emoji: '🦹‍♂️',
      name: 'man supervillain',
      nameUrl: 'man-supervillain',
    },
    {
      id: 313,
      code: 'U+1F9B9 U+200D U+2640 U+FE0F',
      emoji: '🦹‍♀️',
      name: 'woman supervillain',
      nameUrl: 'woman-supervillain',
    },
    {
      id: 314,
      code: 'U+1F9D9',
      emoji: '🧙',
      name: 'mage',
      nameUrl: 'mage',
    },
    {
      id: 315,
      code: 'U+1F9D9 U+200D U+2642 U+FE0F',
      emoji: '🧙‍♂️',
      name: 'man mage',
      nameUrl: 'man-mage',
    },
    {
      id: 316,
      code: 'U+1F9D9 U+200D U+2640 U+FE0F',
      emoji: '🧙‍♀️',
      name: 'woman mage',
      nameUrl: 'woman-mage',
    },
    {
      id: 317,
      code: 'U+1F9DA',
      emoji: '🧚',
      name: 'fairy',
      nameUrl: 'fairy',
    },
    {
      id: 318,
      code: 'U+1F9DA U+200D U+2642 U+FE0F',
      emoji: '🧚‍♂️',
      name: 'man fairy',
      nameUrl: 'man-fairy',
    },
    {
      id: 319,
      code: 'U+1F9DA U+200D U+2640 U+FE0F',
      emoji: '🧚‍♀️',
      name: 'woman fairy',
      nameUrl: 'woman-fairy',
    },
    {
      id: 320,
      code: 'U+1F9DB',
      emoji: '🧛',
      name: 'vampire',
      nameUrl: 'vampire',
    },
    {
      id: 321,
      code: 'U+1F9DB U+200D U+2642 U+FE0F',
      emoji: '🧛‍♂️',
      name: 'man vampire',
      nameUrl: 'man-vampire',
    },
    {
      id: 322,
      code: 'U+1F9DB U+200D U+2640 U+FE0F',
      emoji: '🧛‍♀️',
      name: 'woman vampire',
      nameUrl: 'woman-vampire',
    },
    {
      id: 323,
      code: 'U+1F9DC',
      emoji: '🧜',
      name: 'merperson',
      nameUrl: 'merperson',
    },
    {
      id: 324,
      code: 'U+1F9DC U+200D U+2642 U+FE0F',
      emoji: '🧜‍♂️',
      name: 'merman',
      nameUrl: 'merman',
    },
    {
      id: 325,
      code: 'U+1F9DC U+200D U+2640 U+FE0F',
      emoji: '🧜‍♀️',
      name: 'mermaid',
      nameUrl: 'mermaid',
    },
    {
      id: 326,
      code: 'U+1F9DD',
      emoji: '🧝',
      name: 'elf',
      nameUrl: 'elf',
    },
    {
      id: 327,
      code: 'U+1F9DD U+200D U+2642 U+FE0F',
      emoji: '🧝‍♂️',
      name: 'man elf',
      nameUrl: 'man-elf',
    },
    {
      id: 328,
      code: 'U+1F9DD U+200D U+2640 U+FE0F',
      emoji: '🧝‍♀️',
      name: 'woman elf',
      nameUrl: 'woman-elf',
    },
    {
      id: 329,
      code: 'U+1F9DE',
      emoji: '🧞',
      name: 'genie',
      nameUrl: 'genie',
    },
    {
      id: 330,
      code: 'U+1F9DE U+200D U+2642 U+FE0F',
      emoji: '🧞‍♂️',
      name: 'man genie',
      nameUrl: 'man-genie',
    },
    {
      id: 331,
      code: 'U+1F9DE U+200D U+2640 U+FE0F',
      emoji: '🧞‍♀️',
      name: 'woman genie',
      nameUrl: 'woman-genie',
    },
    {
      id: 332,
      code: 'U+1F9DF',
      emoji: '🧟',
      name: 'zombie',
      nameUrl: 'zombie',
    },
    {
      id: 333,
      code: 'U+1F9DF U+200D U+2642 U+FE0F',
      emoji: '🧟‍♂️',
      name: 'man zombie',
      nameUrl: 'man-zombie',
    },
    {
      id: 334,
      code: 'U+1F9DF U+200D U+2640 U+FE0F',
      emoji: '🧟‍♀️',
      name: 'woman zombie',
      nameUrl: 'woman-zombie',
    },
    {
      id: 335,
      code: 'U+1F486',
      emoji: '💆',
      name: 'person getting massage',
      nameUrl: 'person-getting-massage',
    },
    {
      id: 336,
      code: 'U+1F486 U+200D U+2642 U+FE0F',
      emoji: '💆‍♂️',
      name: 'man getting massage',
      nameUrl: 'man-getting-massage',
    },
    {
      id: 337,
      code: 'U+1F486 U+200D U+2640 U+FE0F',
      emoji: '💆‍♀️',
      name: 'woman getting massage',
      nameUrl: 'woman-getting-massage',
    },
    {
      id: 338,
      code: 'U+1F487',
      emoji: '💇',
      name: 'person getting haircut',
      nameUrl: 'person-getting-haircut',
    },
    {
      id: 339,
      code: 'U+1F487 U+200D U+2642 U+FE0F',
      emoji: '💇‍♂️',
      name: 'man getting haircut',
      nameUrl: 'man-getting-haircut',
    },
    {
      id: 340,
      code: 'U+1F487 U+200D U+2640 U+FE0F',
      emoji: '💇‍♀️',
      name: 'woman getting haircut',
      nameUrl: 'woman-getting-haircut',
    },
    {
      id: 341,
      code: 'U+1F6B6',
      emoji: '🚶',
      name: 'person walking',
      nameUrl: 'person-walking',
    },
    {
      id: 342,
      code: 'U+1F6B6 U+200D U+2642 U+FE0F',
      emoji: '🚶‍♂️',
      name: 'man walking',
      nameUrl: 'man-walking',
    },
    {
      id: 343,
      code: 'U+1F6B6 U+200D U+2640 U+FE0F',
      emoji: '🚶‍♀️',
      name: 'woman walking',
      nameUrl: 'woman-walking',
    },
    {
      id: 356,
      code: 'U+1F3C3',
      emoji: '🏃',
      name: 'person running',
      nameUrl: 'person-running',
    },
    {
      id: 357,
      code: 'U+1F3C3 U+200D U+2642 U+FE0F',
      emoji: '🏃‍♂️',
      name: 'man running',
      nameUrl: 'man-running',
    },
    {
      id: 358,
      code: 'U+1F3C3 U+200D U+2640 U+FE0F',
      emoji: '🏃‍♀️',
      name: 'woman running',
      nameUrl: 'woman-running',
    },
    {
      id: 359,
      code: 'U+1F483',
      emoji: '💃',
      name: 'woman dancing',
      nameUrl: 'woman-dancing',
    },
    {
      id: 360,
      code: 'U+1F57A',
      emoji: '🕺',
      name: 'man dancing',
      nameUrl: 'man-dancing',
    },
    {
      id: 361,
      code: 'U+1F574',
      emoji: '🕴',
      name: 'man in suit levitating',
      nameUrl: 'man-in-suit-levitating',
    },
    {
      id: 362,
      code: 'U+1F46F',
      emoji: '👯',
      name: 'people with bunny ears',
      nameUrl: 'people-with-bunny-ears',
    },
    {
      id: 363,
      code: 'U+1F46F U+200D U+2642 U+FE0F',
      emoji: '👯‍♂️',
      name: 'men with bunny ears',
      nameUrl: 'men-with-bunny-ears',
    },
    {
      id: 364,
      code: 'U+1F46F U+200D U+2640 U+FE0F',
      emoji: '👯‍♀️',
      name: 'women with bunny ears',
      nameUrl: 'women-with-bunny-ears',
    },
    {
      id: 365,
      code: 'U+1F9D6',
      emoji: '🧖',
      name: 'person in steamy room',
      nameUrl: 'person-in-steamy-room',
    },
    {
      id: 366,
      code: 'U+1F9D6 U+200D U+2642 U+FE0F',
      emoji: '🧖‍♂️',
      name: 'man in steamy room',
      nameUrl: 'man-in-steamy-room',
    },
    {
      id: 367,
      code: 'U+1F9D6 U+200D U+2640 U+FE0F',
      emoji: '🧖‍♀️',
      name: 'woman in steamy room',
      nameUrl: 'woman-in-steamy-room',
    },
    {
      id: 368,
      code: 'U+1F9D7',
      emoji: '🧗',
      name: 'person climbing',
      nameUrl: 'person-climbing',
    },
    {
      id: 369,
      code: 'U+1F9D7 U+200D U+2642 U+FE0F',
      emoji: '🧗‍♂️',
      name: 'man climbing',
      nameUrl: 'man-climbing',
    },
    {
      id: 370,
      code: 'U+1F9D7 U+200D U+2640 U+FE0F',
      emoji: '🧗‍♀️',
      name: 'woman climbing',
      nameUrl: 'woman-climbing',
    },
    {
      id: 371,
      code: 'U+1F93A',
      emoji: '🤺',
      name: 'person fencing',
      nameUrl: 'person-fencing',
    },
    {
      id: 372,
      code: 'U+1F3C7',
      emoji: '🏇',
      name: 'horse racing',
      nameUrl: 'horse-racing',
    },
    {
      id: 373,
      code: 'U+26F7',
      emoji: '⛷',
      name: 'skier',
      nameUrl: 'skier',
    },
    {
      id: 374,
      code: 'U+1F3C2',
      emoji: '🏂',
      name: 'snowboarder',
      nameUrl: 'snowboarder',
    },
    {
      id: 375,
      code: 'U+1F3CC',
      emoji: '🏌',
      name: 'person golfing',
      nameUrl: 'person-golfing',
    },
    {
      id: 376,
      code: 'U+1F3CC U+FE0F U+200D U+2642 U+FE0F',
      emoji: '🏌️‍♂️',
      name: 'man golfing',
      nameUrl: 'man-golfing',
    },
    {
      id: 377,
      code: 'U+1F3CC U+FE0F U+200D U+2640 U+FE0F',
      emoji: '🏌️‍♀️',
      name: 'woman golfing',
      nameUrl: 'woman-golfing',
    },
    {
      id: 378,
      code: 'U+1F3C4',
      emoji: '🏄',
      name: 'person surfing',
      nameUrl: 'person-surfing',
    },
    {
      id: 379,
      code: 'U+1F3C4 U+200D U+2642 U+FE0F',
      emoji: '🏄‍♂️',
      name: 'man surfing',
      nameUrl: 'man-surfing',
    },
    {
      id: 380,
      code: 'U+1F3C4 U+200D U+2640 U+FE0F',
      emoji: '🏄‍♀️',
      name: 'woman surfing',
      nameUrl: 'woman-surfing',
    },
    {
      id: 381,
      code: 'U+1F6A3',
      emoji: '🚣',
      name: 'person rowing boat',
      nameUrl: 'person-rowing-boat',
    },
    {
      id: 382,
      code: 'U+1F6A3 U+200D U+2642 U+FE0F',
      emoji: '🚣‍♂️',
      name: 'man rowing boat',
      nameUrl: 'man-rowing-boat',
    },
    {
      id: 383,
      code: 'U+1F6A3 U+200D U+2640 U+FE0F',
      emoji: '🚣‍♀️',
      name: 'woman rowing boat',
      nameUrl: 'woman-rowing-boat',
    },
    {
      id: 384,
      code: 'U+1F3CA',
      emoji: '🏊',
      name: 'person swimming',
      nameUrl: 'person-swimming',
    },
    {
      id: 385,
      code: 'U+1F3CA U+200D U+2642 U+FE0F',
      emoji: '🏊‍♂️',
      name: 'man swimming',
      nameUrl: 'man-swimming',
    },
    {
      id: 386,
      code: 'U+1F3CA U+200D U+2640 U+FE0F',
      emoji: '🏊‍♀️',
      name: 'woman swimming',
      nameUrl: 'woman-swimming',
    },
    {
      id: 387,
      code: 'U+26F9',
      emoji: '⛹',
      name: 'person bouncing ball',
      nameUrl: 'person-bouncing-ball',
    },
    {
      id: 388,
      code: 'U+26F9 U+FE0F U+200D U+2642 U+FE0F',
      emoji: '⛹️‍♂️',
      name: 'man bouncing ball',
      nameUrl: 'man-bouncing-ball',
    },
    {
      id: 389,
      code: 'U+26F9 U+FE0F U+200D U+2640 U+FE0F',
      emoji: '⛹️‍♀️',
      name: 'woman bouncing ball',
      nameUrl: 'woman-bouncing-ball',
    },
    {
      id: 390,
      code: 'U+1F3CB',
      emoji: '🏋',
      name: 'person lifting weights',
      nameUrl: 'person-lifting-weights',
    },
    {
      id: 391,
      code: 'U+1F3CB U+FE0F U+200D U+2642 U+FE0F',
      emoji: '🏋️‍♂️',
      name: 'man lifting weights',
      nameUrl: 'man-lifting-weights',
    },
    {
      id: 392,
      code: 'U+1F3CB U+FE0F U+200D U+2640 U+FE0F',
      emoji: '🏋️‍♀️',
      name: 'woman lifting weights',
      nameUrl: 'woman-lifting-weights',
    },
    {
      id: 393,
      code: 'U+1F6B4',
      emoji: '🚴',
      name: 'person biking',
      nameUrl: 'person-biking',
    },
    {
      id: 394,
      code: 'U+1F6B4 U+200D U+2642 U+FE0F',
      emoji: '🚴‍♂️',
      name: 'man biking',
      nameUrl: 'man-biking',
    },
    {
      id: 395,
      code: 'U+1F6B4 U+200D U+2640 U+FE0F',
      emoji: '🚴‍♀️',
      name: 'woman biking',
      nameUrl: 'woman-biking',
    },
    {
      id: 396,
      code: 'U+1F6B5',
      emoji: '🚵',
      name: 'person mountain biking',
      nameUrl: 'person-mountain-biking',
    },
    {
      id: 397,
      code: 'U+1F6B5 U+200D U+2642 U+FE0F',
      emoji: '🚵‍♂️',
      name: 'man mountain biking',
      nameUrl: 'man-mountain-biking',
    },
    {
      id: 398,
      code: 'U+1F6B5 U+200D U+2640 U+FE0F',
      emoji: '🚵‍♀️',
      name: 'woman mountain biking',
      nameUrl: 'woman-mountain-biking',
    },
    {
      id: 399,
      code: 'U+1F938',
      emoji: '🤸',
      name: 'person cartwheeling',
      nameUrl: 'person-cartwheeling',
    },
    {
      id: 400,
      code: 'U+1F938 U+200D U+2642 U+FE0F',
      emoji: '🤸‍♂️',
      name: 'man cartwheeling',
      nameUrl: 'man-cartwheeling',
    },
    {
      id: 401,
      code: 'U+1F938 U+200D U+2640 U+FE0F',
      emoji: '🤸‍♀️',
      name: 'woman cartwheeling',
      nameUrl: 'woman-cartwheeling',
    },
    {
      id: 402,
      code: 'U+1F93C',
      emoji: '🤼',
      name: 'people wrestling',
      nameUrl: 'people-wrestling',
    },
    {
      id: 403,
      code: 'U+1F93C U+200D U+2642 U+FE0F',
      emoji: '🤼‍♂️',
      name: 'men wrestling',
      nameUrl: 'men-wrestling',
    },
    {
      id: 404,
      code: 'U+1F93C U+200D U+2640 U+FE0F',
      emoji: '🤼‍♀️',
      name: 'women wrestling',
      nameUrl: 'women-wrestling',
    },
    {
      id: 405,
      code: 'U+1F93D',
      emoji: '🤽',
      name: 'person playing water polo',
      nameUrl: 'person-playing-water-polo',
    },
    {
      id: 406,
      code: 'U+1F93D U+200D U+2642 U+FE0F',
      emoji: '🤽‍♂️',
      name: 'man playing water polo',
      nameUrl: 'man-playing-water-polo',
    },
    {
      id: 407,
      code: 'U+1F93D U+200D U+2640 U+FE0F',
      emoji: '🤽‍♀️',
      name: 'woman playing water polo',
      nameUrl: 'woman-playing-water-polo',
    },
    {
      id: 408,
      code: 'U+1F93E',
      emoji: '🤾',
      name: 'person playing handball',
      nameUrl: 'person-playing-handball',
    },
    {
      id: 409,
      code: 'U+1F93E U+200D U+2642 U+FE0F',
      emoji: '🤾‍♂️',
      name: 'man playing handball',
      nameUrl: 'man-playing-handball',
    },
    {
      id: 410,
      code: 'U+1F93E U+200D U+2640 U+FE0F',
      emoji: '🤾‍♀️',
      name: 'woman playing handball',
      nameUrl: 'woman-playing-handball',
    },
    {
      id: 411,
      code: 'U+1F939',
      emoji: '🤹',
      name: 'person juggling',
      nameUrl: 'person-juggling',
    },
    {
      id: 412,
      code: 'U+1F939 U+200D U+2642 U+FE0F',
      emoji: '🤹‍♂️',
      name: 'man juggling',
      nameUrl: 'man-juggling',
    },
    {
      id: 413,
      code: 'U+1F939 U+200D U+2640 U+FE0F',
      emoji: '🤹‍♀️',
      name: 'woman juggling',
      nameUrl: 'woman-juggling',
    },
    {
      id: 414,
      code: 'U+1F9D8',
      emoji: '🧘',
      name: 'person in lotus position',
      nameUrl: 'person-in-lotus-position',
    },
    {
      id: 415,
      code: 'U+1F9D8 U+200D U+2642 U+FE0F',
      emoji: '🧘‍♂️',
      name: 'man in lotus position',
      nameUrl: 'man-in-lotus-position',
    },
    {
      id: 416,
      code: 'U+1F9D8 U+200D U+2640 U+FE0F',
      emoji: '🧘‍♀️',
      name: 'woman in lotus position',
      nameUrl: 'woman-in-lotus-position',
    },
    {
      id: 417,
      code: 'U+1F6C0',
      emoji: '🛀',
      name: 'person taking bath',
      nameUrl: 'person-taking-bath',
    },
    {
      id: 418,
      code: 'U+1F6CC',
      emoji: '🛌',
      name: 'person in bed',
      nameUrl: 'person-in-bed',
    },
    {
      id: 420,
      code: 'U+1F46D',
      emoji: '👭',
      name: 'women holding hands',
      nameUrl: 'women-holding-hands',
    },
    {
      id: 421,
      code: 'U+1F46B',
      emoji: '👫',
      name: 'woman and man holding hands',
      nameUrl: 'woman-and-man-holding-hands',
    },
    {
      id: 422,
      code: 'U+1F46C',
      emoji: '👬',
      name: 'men holding hands',
      nameUrl: 'men-holding-hands',
    },
    {
      id: 423,
      code: 'U+1F48F',
      emoji: '💏',
      name: 'kiss',
      nameUrl: 'kiss',
    },
    {
      id: 424,
      code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
      emoji: '👩‍❤️‍💋‍👨',
      name: 'kiss: woman, man',
      nameUrl: 'kiss-woman-man',
    },
    {
      id: 425,
      code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468',
      emoji: '👨‍❤️‍💋‍👨',
      name: 'kiss: man, man',
      nameUrl: 'kiss-man-man',
    },
    {
      id: 426,
      code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F469',
      emoji: '👩‍❤️‍💋‍👩',
      name: 'kiss: woman, woman',
      nameUrl: 'kiss-woman-woman',
    },
    {
      id: 427,
      code: 'U+1F491',
      emoji: '💑',
      name: 'couple with heart',
      nameUrl: 'couple-with-heart',
    },
    {
      id: 428,
      code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F468',
      emoji: '👩‍❤️‍👨',
      name: 'couple with heart: woman, man',
      nameUrl: 'couple-with-heart-woman-man',
    },
    {
      id: 429,
      code: 'U+1F468 U+200D U+2764 U+FE0F U+200D U+1F468',
      emoji: '👨‍❤️‍👨',
      name: 'couple with heart: man, man',
      nameUrl: 'couple-with-heart-man-man',
    },
    {
      id: 430,
      code: 'U+1F469 U+200D U+2764 U+FE0F U+200D U+1F469',
      emoji: '👩‍❤️‍👩',
      name: 'couple with heart: woman, woman',
      nameUrl: 'couple-with-heart-woman-woman',
    },
    {
      id: 431,
      code: 'U+1F46A',
      emoji: '👪',
      name: 'family',
      nameUrl: 'family',
    },
    {
      id: 432,
      code: 'U+1F468 U+200D U+1F469 U+200D U+1F466',
      emoji: '👨‍👩‍👦',
      name: 'family: man, woman, boy',
      nameUrl: 'family-man-woman-boy',
    },
    {
      id: 433,
      code: 'U+1F468 U+200D U+1F469 U+200D U+1F467',
      emoji: '👨‍👩‍👧',
      name: 'family: man, woman, girl',
      nameUrl: 'family-man-woman-girl',
    },
    {
      id: 434,
      code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
      emoji: '👨‍👩‍👧‍👦',
      name: 'family: man, woman, girl, boy',
      nameUrl: 'family-man-woman-girl-boy',
    },
    {
      id: 435,
      code: 'U+1F468 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
      emoji: '👨‍👩‍👦‍👦',
      name: 'family: man, woman, boy, boy',
      nameUrl: 'family-man-woman-boy-boy',
    },
    {
      id: 436,
      code: 'U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
      emoji: '👨‍👩‍👧‍👧',
      name: 'family: man, woman, girl, girl',
      nameUrl: 'family-man-woman-girl-girl',
    },
    {
      id: 437,
      code: 'U+1F468 U+200D U+1F468 U+200D U+1F466',
      emoji: '👨‍👨‍👦',
      name: 'family: man, man, boy',
      nameUrl: 'family-man-man-boy',
    },
    {
      id: 438,
      code: 'U+1F468 U+200D U+1F468 U+200D U+1F467',
      emoji: '👨‍👨‍👧',
      name: 'family: man, man, girl',
      nameUrl: 'family-man-man-girl',
    },
    {
      id: 439,
      code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F466',
      emoji: '👨‍👨‍👧‍👦',
      name: 'family: man, man, girl, boy',
      nameUrl: 'family-man-man-girl-boy',
    },
    {
      id: 440,
      code: 'U+1F468 U+200D U+1F468 U+200D U+1F466 U+200D U+1F466',
      emoji: '👨‍👨‍👦‍👦',
      name: 'family: man, man, boy, boy',
      nameUrl: 'family-man-man-boy-boy',
    },
    {
      id: 441,
      code: 'U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F467',
      emoji: '👨‍👨‍👧‍👧',
      name: 'family: man, man, girl, girl',
      nameUrl: 'family-man-man-girl-girl',
    },
    {
      id: 442,
      code: 'U+1F469 U+200D U+1F469 U+200D U+1F466',
      emoji: '👩‍👩‍👦',
      name: 'family: woman, woman, boy',
      nameUrl: 'family-woman-woman-boy',
    },
    {
      id: 443,
      code: 'U+1F469 U+200D U+1F469 U+200D U+1F467',
      emoji: '👩‍👩‍👧',
      name: 'family: woman, woman, girl',
      nameUrl: 'family-woman-woman-girl',
    },
    {
      id: 444,
      code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466',
      emoji: '👩‍👩‍👧‍👦',
      name: 'family: woman, woman, girl, boy',
      nameUrl: 'family-woman-woman-girl-boy',
    },
    {
      id: 445,
      code: 'U+1F469 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466',
      emoji: '👩‍👩‍👦‍👦',
      name: 'family: woman, woman, boy, boy',
      nameUrl: 'family-woman-woman-boy-boy',
    },
    {
      id: 446,
      code: 'U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467',
      emoji: '👩‍👩‍👧‍👧',
      name: 'family: woman, woman, girl, girl',
      nameUrl: 'family-woman-woman-girl-girl',
    },
    {
      id: 447,
      code: 'U+1F468 U+200D U+1F466',
      emoji: '👨‍👦',
      name: 'family: man, boy',
      nameUrl: 'family-man-boy',
    },
    {
      id: 448,
      code: 'U+1F468 U+200D U+1F466 U+200D U+1F466',
      emoji: '👨‍👦‍👦',
      name: 'family: man, boy, boy',
      nameUrl: 'family-man-boy-boy',
    },
    {
      id: 449,
      code: 'U+1F468 U+200D U+1F467',
      emoji: '👨‍👧',
      name: 'family: man, girl',
      nameUrl: 'family-man-girl',
    },
    {
      id: 450,
      code: 'U+1F468 U+200D U+1F467 U+200D U+1F466',
      emoji: '👨‍👧‍👦',
      name: 'family: man, girl, boy',
      nameUrl: 'family-man-girl-boy',
    },
    {
      id: 451,
      code: 'U+1F468 U+200D U+1F467 U+200D U+1F467',
      emoji: '👨‍👧‍👧',
      name: 'family: man, girl, girl',
      nameUrl: 'family-man-girl-girl',
    },
    {
      id: 452,
      code: 'U+1F469 U+200D U+1F466',
      emoji: '👩‍👦',
      name: 'family: woman, boy',
      nameUrl: 'family-woman-boy',
    },
    {
      id: 453,
      code: 'U+1F469 U+200D U+1F466 U+200D U+1F466',
      emoji: '👩‍👦‍👦',
      name: 'family: woman, boy, boy',
      nameUrl: 'family-woman-boy-boy',
    },
    {
      id: 454,
      code: 'U+1F469 U+200D U+1F467',
      emoji: '👩‍👧',
      name: 'family: woman, girl',
      nameUrl: 'family-woman-girl',
    },
    {
      id: 455,
      code: 'U+1F469 U+200D U+1F467 U+200D U+1F466',
      emoji: '👩‍👧‍👦',
      name: 'family: woman, girl, boy',
      nameUrl: 'family-woman-girl-boy',
    },
    {
      id: 456,
      code: 'U+1F469 U+200D U+1F467 U+200D U+1F467',
      emoji: '👩‍👧‍👧',
      name: 'family: woman, girl, girl',
      nameUrl: 'family-woman-girl-girl',
    },
    {
      id: 457,
      code: 'U+1F5E3',
      emoji: '🗣',
      name: 'speaking head',
      nameUrl: 'speaking-head',
    },
    {
      id: 458,
      code: 'U+1F464',
      emoji: '👤',
      name: 'bust in silhouette',
      nameUrl: 'bust-in-silhouette',
    },
    {
      id: 459,
      code: 'U+1F465',
      emoji: '👥',
      name: 'busts in silhouette',
      nameUrl: 'busts-in-silhouette',
    },
    {
      id: 460,
      code: 'U+1F463',
      emoji: '👣',
      name: 'footprints',
      nameUrl: 'footprints',
      },
    {
      id: 461,
      code: 'U+1F9B0',
      emoji: '🦰',
      name: 'red hair',
      nameUrl: 'red-hair',
    },
    {
      id: 462,
      code: 'U+1F9B1',
      emoji: '🦱',
      name: 'curly hair',
      nameUrl: 'curly-hair',
    },
    {
      id: 463,
      code: 'U+1F9B3',
      emoji: '🦳',
      name: 'white hair',
      nameUrl: 'white-hair',
    },
    {
      id: 464,
      code: 'U+1F9B2',
      emoji: '🦲',
      name: 'bald',
      nameUrl: 'bald',
      },
    {
      id: 465,
      code: 'U+1F435',
      emoji: '🐵',
      name: 'monkey face',
      nameUrl: 'monkey-face',
    },
    {
      id: 466,
      code: 'U+1F412',
      emoji: '🐒',
      name: 'monkey',
      nameUrl: 'monkey',
    },
    {
      id: 467,
      code: 'U+1F98D',
      emoji: '🦍',
      name: 'gorilla',
      nameUrl: 'gorilla',
    },
    {
      id: 469,
      code: 'U+1F436',
      emoji: '🐶',
      name: 'dog face',
      nameUrl: 'dog-face',
    },
    {
      id: 470,
      code: 'U+1F415',
      emoji: '🐕',
      name: 'dog',
      nameUrl: 'dog',
    },
    {
      id: 473,
      code: 'U+1F429',
      emoji: '🐩',
      name: 'poodle',
      nameUrl: 'poodle',
    },
    {
      id: 474,
      code: 'U+1F43A',
      emoji: '🐺',
      name: 'wolf',
      nameUrl: 'wolf',
    },
    {
      id: 475,
      code: 'U+1F98A',
      emoji: '🦊',
      name: 'fox',
      nameUrl: 'fox',
    },
    {
      id: 476,
      code: 'U+1F99D',
      emoji: '🦝',
      name: 'raccoon',
      nameUrl: 'raccoon',
    },
    {
      id: 477,
      code: 'U+1F431',
      emoji: '🐱',
      name: 'cat face',
      nameUrl: 'cat-face',
    },
    {
      id: 478,
      code: 'U+1F408',
      emoji: '🐈',
      name: 'cat',
      nameUrl: 'cat',
    },
    {
      id: 479,
      code: 'U+1F981',
      emoji: '🦁',
      name: 'lion',
      nameUrl: 'lion',
    },
    {
      id: 480,
      code: 'U+1F42F',
      emoji: '🐯',
      name: 'tiger face',
      nameUrl: 'tiger-face',
    },
    {
      id: 481,
      code: 'U+1F405',
      emoji: '🐅',
      name: 'tiger',
      nameUrl: 'tiger',
    },
    {
      id: 482,
      code: 'U+1F406',
      emoji: '🐆',
      name: 'leopard',
      nameUrl: 'leopard',
    },
    {
      id: 483,
      code: 'U+1F434',
      emoji: '🐴',
      name: 'horse face',
      nameUrl: 'horse-face',
    },
    {
      id: 484,
      code: 'U+1F40E',
      emoji: '🐎',
      name: 'horse',
      nameUrl: 'horse',
    },
    {
      id: 485,
      code: 'U+1F984',
      emoji: '🦄',
      name: 'unicorn',
      nameUrl: 'unicorn',
    },
    {
      id: 486,
      code: 'U+1F993',
      emoji: '🦓',
      name: 'zebra',
      nameUrl: 'zebra',
    },
    {
      id: 487,
      code: 'U+1F98C',
      emoji: '🦌',
      name: 'deer',
      nameUrl: 'deer',
    },
    {
      id: 488,
      code: 'U+1F42E',
      emoji: '🐮',
      name: 'cow face',
      nameUrl: 'cow-face',
    },
    {
      id: 489,
      code: 'U+1F402',
      emoji: '🐂',
      name: 'ox',
      nameUrl: 'ox',
    },
    {
      id: 490,
      code: 'U+1F403',
      emoji: '🐃',
      name: 'water buffalo',
      nameUrl: 'water-buffalo',
    },
    {
      id: 491,
      code: 'U+1F404',
      emoji: '🐄',
      name: 'cow',
      nameUrl: 'cow',
    },
    {
      id: 492,
      code: 'U+1F437',
      emoji: '🐷',
      name: 'pig face',
      nameUrl: 'pig-face',
    },
    {
      id: 493,
      code: 'U+1F416',
      emoji: '🐖',
      name: 'pig',
      nameUrl: 'pig',
    },
    {
      id: 494,
      code: 'U+1F417',
      emoji: '🐗',
      name: 'boar',
      nameUrl: 'boar',
    },
    {
      id: 495,
      code: 'U+1F43D',
      emoji: '🐽',
      name: 'pig nose',
      nameUrl: 'pig-nose',
    },
    {
      id: 496,
      code: 'U+1F40F',
      emoji: '🐏',
      name: 'ram',
      nameUrl: 'ram',
    },
    {
      id: 497,
      code: 'U+1F411',
      emoji: '🐑',
      name: 'ewe',
      nameUrl: 'ewe',
    },
    {
      id: 498,
      code: 'U+1F410',
      emoji: '🐐',
      name: 'goat',
      nameUrl: 'goat',
    },
    {
      id: 499,
      code: 'U+1F42A',
      emoji: '🐪',
      name: 'camel',
      nameUrl: 'camel',
    },
    {
      id: 500,
      code: 'U+1F42B',
      emoji: '🐫',
      name: 'two-hump camel',
      nameUrl: 'two-hump-camel',
    },
    {
      id: 501,
      code: 'U+1F999',
      emoji: '🦙',
      name: 'llama',
      nameUrl: 'llama',
    },
    {
      id: 502,
      code: 'U+1F992',
      emoji: '🦒',
      name: 'giraffe',
      nameUrl: 'giraffe',
    },
    {
      id: 503,
      code: 'U+1F418',
      emoji: '🐘',
      name: 'elephant',
      nameUrl: 'elephant',
    },
    {
      id: 504,
      code: 'U+1F98F',
      emoji: '🦏',
      name: 'rhinoceros',
      nameUrl: 'rhinoceros',
    },
    {
      id: 505,
      code: 'U+1F99B',
      emoji: '🦛',
      name: 'hippopotamus',
      nameUrl: 'hippopotamus',
    },
    {
      id: 506,
      code: 'U+1F42D',
      emoji: '🐭',
      name: 'mouse face',
      nameUrl: 'mouse-face',
    },
    {
      id: 507,
      code: 'U+1F401',
      emoji: '🐁',
      name: 'mouse',
      nameUrl: 'mouse',
    },
    {
      id: 508,
      code: 'U+1F400',
      emoji: '🐀',
      name: 'rat',
      nameUrl: 'rat',
    },
    {
      id: 509,
      code: 'U+1F439',
      emoji: '🐹',
      name: 'hamster',
      nameUrl: 'hamster',
    },
    {
      id: 510,
      code: 'U+1F430',
      emoji: '🐰',
      name: 'rabbit face',
      nameUrl: 'rabbit-face',
    },
    {
      id: 511,
      code: 'U+1F407',
      emoji: '🐇',
      name: 'rabbit',
      nameUrl: 'rabbit',
    },
    {
      id: 512,
      code: 'U+1F43F',
      emoji: '🐿',
      name: 'chipmunk',
      nameUrl: 'chipmunk',
    },
    {
      id: 513,
      code: 'U+1F994',
      emoji: '🦔',
      name: 'hedgehog',
      nameUrl: 'hedgehog',
    },
    {
      id: 514,
      code: 'U+1F987',
      emoji: '🦇',
      name: 'bat',
      nameUrl: 'bat',
    },
    {
      id: 515,
      code: 'U+1F43B',
      emoji: '🐻',
      name: 'bear',
      nameUrl: 'bear',
    },
    {
      id: 516,
      code: 'U+1F428',
      emoji: '🐨',
      name: 'koala',
      nameUrl: 'koala',
    },
    {
      id: 517,
      code: 'U+1F43C',
      emoji: '🐼',
      name: 'panda',
      nameUrl: 'panda',
    },
    {
      id: 521,
      code: 'U+1F998',
      emoji: '🦘',
      name: 'kangaroo',
      nameUrl: 'kangaroo',
    },
    {
      id: 522,
      code: 'U+1F9A1',
      emoji: '🦡',
      name: 'badger',
      nameUrl: 'badger',
    },
    {
      id: 523,
      code: 'U+1F43E',
      emoji: '🐾',
      name: 'paw prints',
      nameUrl: 'paw-prints',
    },
    {
      id: 524,
      code: 'U+1F983',
      emoji: '🦃',
      name: 'turkey',
      nameUrl: 'turkey',
    },
    {
      id: 525,
      code: 'U+1F414',
      emoji: '🐔',
      name: 'chicken',
      nameUrl: 'chicken',
    },
    {
      id: 526,
      code: 'U+1F413',
      emoji: '🐓',
      name: 'rooster',
      nameUrl: 'rooster',
    },
    {
      id: 527,
      code: 'U+1F423',
      emoji: '🐣',
      name: 'hatching chick',
      nameUrl: 'hatching-chick',
    },
    {
      id: 528,
      code: 'U+1F424',
      emoji: '🐤',
      name: 'baby chick',
      nameUrl: 'baby-chick',
    },
    {
      id: 529,
      code: 'U+1F425',
      emoji: '🐥',
      name: 'front-facing baby chick',
      nameUrl: 'front-facing-baby-chick',
    },
    {
      id: 530,
      code: 'U+1F426',
      emoji: '🐦',
      name: 'bird',
      nameUrl: 'bird',
    },
    {
      id: 531,
      code: 'U+1F427',
      emoji: '🐧',
      name: 'penguin',
      nameUrl: 'penguin',
    },
    {
      id: 532,
      code: 'U+1F54A',
      emoji: '🕊',
      name: 'dove',
      nameUrl: 'dove',
    },
    {
      id: 533,
      code: 'U+1F985',
      emoji: '🦅',
      name: 'eagle',
      nameUrl: 'eagle',
    },
    {
      id: 534,
      code: 'U+1F986',
      emoji: '🦆',
      name: 'duck',
      nameUrl: 'duck',
    },
    {
      id: 535,
      code: 'U+1F9A2',
      emoji: '🦢',
      name: 'swan',
      nameUrl: 'swan',
    },
    {
      id: 536,
      code: 'U+1F989',
      emoji: '🦉',
      name: 'owl',
      nameUrl: 'owl',
    },
    {
      id: 538,
      code: 'U+1F99A',
      emoji: '🦚',
      name: 'peacock',
      nameUrl: 'peacock',
    },
    {
      id: 539,
      code: 'U+1F99C',
      emoji: '🦜',
      name: 'parrot',
      nameUrl: 'parrot',
    },
    {
      id: 540,
      code: 'U+1F438',
      emoji: '🐸',
      name: 'frog',
      nameUrl: 'frog',
    },
    {
      id: 541,
      code: 'U+1F40A',
      emoji: '🐊',
      name: 'crocodile',
      nameUrl: 'crocodile',
    },
    {
      id: 542,
      code: 'U+1F422',
      emoji: '🐢',
      name: 'turtle',
      nameUrl: 'turtle',
    },
    {
      id: 543,
      code: 'U+1F98E',
      emoji: '🦎',
      name: 'lizard',
      nameUrl: 'lizard',
    },
    {
      id: 544,
      code: 'U+1F40D',
      emoji: '🐍',
      name: 'snake',
      nameUrl: 'snake',
    },
    {
      id: 545,
      code: 'U+1F432',
      emoji: '🐲',
      name: 'dragon face',
      nameUrl: 'dragon-face',
    },
    {
      id: 546,
      code: 'U+1F409',
      emoji: '🐉',
      name: 'dragon',
      nameUrl: 'dragon',
    },
    {
      id: 547,
      code: 'U+1F995',
      emoji: '🦕',
      name: 'sauropod',
      nameUrl: 'sauropod',
    },
    {
      id: 548,
      code: 'U+1F996',
      emoji: '🦖',
      name: 'T-Rex',
      nameUrl: 't-rex',
    },
    {
      id: 549,
      code: 'U+1F433',
      emoji: '🐳',
      name: 'spouting whale',
      nameUrl: 'spouting-whale',
    },
    {
      id: 550,
      code: 'U+1F40B',
      emoji: '🐋',
      name: 'whale',
      nameUrl: 'whale',
    },
    {
      id: 551,
      code: 'U+1F42C',
      emoji: '🐬',
      name: 'dolphin',
      nameUrl: 'dolphin',
    },
    {
      id: 552,
      code: 'U+1F41F',
      emoji: '🐟',
      name: 'fish',
      nameUrl: 'fish',
    },
    {
      id: 553,
      code: 'U+1F420',
      emoji: '🐠',
      name: 'tropical fish',
      nameUrl: 'tropical-fish',
    },
    {
      id: 554,
      code: 'U+1F421',
      emoji: '🐡',
      name: 'blowfish',
      nameUrl: 'blowfish',
    },
    {
      id: 555,
      code: 'U+1F988',
      emoji: '🦈',
      name: 'shark',
      nameUrl: 'shark',
    },
    {
      id: 556,
      code: 'U+1F419',
      emoji: '🐙',
      name: 'octopus',
      nameUrl: 'octopus',
    },
    {
      id: 557,
      code: 'U+1F41A',
      emoji: '🐚',
      name: 'spiral shell',
      nameUrl: 'spiral-shell',
    },
    {
      id: 558,
      code: 'U+1F40C',
      emoji: '🐌',
      name: 'snail',
      nameUrl: 'snail',
    },
    {
      id: 559,
      code: 'U+1F98B',
      emoji: '🦋',
      name: 'butterfly',
      nameUrl: 'butterfly',
    },
    {
      id: 560,
      code: 'U+1F41B',
      emoji: '🐛',
      name: 'bug',
      nameUrl: 'bug',
    },
    {
      id: 561,
      code: 'U+1F41C',
      emoji: '🐜',
      name: 'ant',
      nameUrl: 'ant',
    },
    {
      id: 562,
      code: 'U+1F41D',
      emoji: '🐝',
      name: 'honeybee',
      nameUrl: 'honeybee',
    },
    {
      id: 563,
      code: 'U+1F41E',
      emoji: '🐞',
      name: 'lady beetle',
      nameUrl: 'lady-beetle',
    },
    {
      id: 564,
      code: 'U+1F997',
      emoji: '🦗',
      name: 'cricket',
      nameUrl: 'cricket',
    },
    {
      id: 565,
      code: 'U+1F577',
      emoji: '🕷',
      name: 'spider',
      nameUrl: 'spider',
    },
    {
      id: 566,
      code: 'U+1F578',
      emoji: '🕸',
      name: 'spider web',
      nameUrl: 'spider-web',
    },
    {
      id: 567,
      code: 'U+1F982',
      emoji: '🦂',
      name: 'scorpion',
      nameUrl: 'scorpion',
    },
    {
      id: 568,
      code: 'U+1F99F',
      emoji: '🦟',
      name: 'mosquito',
      nameUrl: 'mosquito',
    },
    {
      id: 569,
      code: 'U+1F9A0',
      emoji: '🦠',
      name: 'microbe',
      nameUrl: 'microbe',
    },
    {
      id: 570,
      code: 'U+1F490',
      emoji: '💐',
      name: 'bouquet',
      nameUrl: 'bouquet',
    },
    {
      id: 571,
      code: 'U+1F338',
      emoji: '🌸',
      name: 'cherry blossom',
      nameUrl: 'cherry-blossom',
    },
    {
      id: 572,
      code: 'U+1F4AE',
      emoji: '💮',
      name: 'white flower',
      nameUrl: 'white-flower',
    },
    {
      id: 573,
      code: 'U+1F3F5',
      emoji: '🏵',
      name: 'rosette',
      nameUrl: 'rosette',
    },
    {
      id: 574,
      code: 'U+1F339',
      emoji: '🌹',
      name: 'rose',
      nameUrl: 'rose',
    },
    {
      id: 575,
      code: 'U+1F940',
      emoji: '🥀',
      name: 'wilted flower',
      nameUrl: 'wilted-flower',
    },
    {
      id: 576,
      code: 'U+1F33A',
      emoji: '🌺',
      name: 'hibiscus',
      nameUrl: 'hibiscus',
    },
    {
      id: 577,
      code: 'U+1F33B',
      emoji: '🌻',
      name: 'sunflower',
      nameUrl: 'sunflower',
    },
    {
      id: 578,
      code: 'U+1F33C',
      emoji: '🌼',
      name: 'blossom',
      nameUrl: 'blossom',
    },
    {
      id: 579,
      code: 'U+1F337',
      emoji: '🌷',
      name: 'tulip',
      nameUrl: 'tulip',
    },
    {
      id: 580,
      code: 'U+1F331',
      emoji: '🌱',
      name: 'seedling',
      nameUrl: 'seedling',
    },
    {
      id: 581,
      code: 'U+1F332',
      emoji: '🌲',
      name: 'evergreen tree',
      nameUrl: 'evergreen-tree',
    },
    {
      id: 582,
      code: 'U+1F333',
      emoji: '🌳',
      name: 'deciduous tree',
      nameUrl: 'deciduous-tree',
    },
    {
      id: 583,
      code: 'U+1F334',
      emoji: '🌴',
      name: 'palm tree',
      nameUrl: 'palm-tree',
    },
    {
      id: 584,
      code: 'U+1F335',
      emoji: '🌵',
      name: 'cactus',
      nameUrl: 'cactus',
    },
    {
      id: 585,
      code: 'U+1F33E',
      emoji: '🌾',
      name: 'sheaf of rice',
      nameUrl: 'sheaf-of-rice',
    },
    {
      id: 586,
      code: 'U+1F33F',
      emoji: '🌿',
      name: 'herb',
      nameUrl: 'herb',
    },
    {
      id: 587,
      code: 'U+2618',
      emoji: '☘',
      name: 'shamrock',
      nameUrl: 'shamrock',
    },
    {
      id: 588,
      code: 'U+1F340',
      emoji: '🍀',
      name: 'four leaf clover',
      nameUrl: 'four-leaf-clover',
    },
    {
      id: 589,
      code: 'U+1F341',
      emoji: '🍁',
      name: 'maple leaf',
      nameUrl: 'maple-leaf',
    },
    {
      id: 590,
      code: 'U+1F342',
      emoji: '🍂',
      name: 'fallen leaf',
      nameUrl: 'fallen-leaf',
    },
    {
      id: 591,
      code: 'U+1F343',
      emoji: '🍃',
      name: 'leaf fluttering in wind',
      nameUrl: 'leaf-fluttering-in-wind',
      },
    {
      id: 592,
      code: 'U+1F347',
      emoji: '🍇',
      name: 'grapes',
      nameUrl: 'grapes',
    },
    {
      id: 593,
      code: 'U+1F348',
      emoji: '🍈',
      name: 'melon',
      nameUrl: 'melon',
    },
    {
      id: 594,
      code: 'U+1F349',
      emoji: '🍉',
      name: 'watermelon',
      nameUrl: 'watermelon',
    },
    {
      id: 595,
      code: 'U+1F34A',
      emoji: '🍊',
      name: 'tangerine',
      nameUrl: 'tangerine',
    },
    {
      id: 596,
      code: 'U+1F34B',
      emoji: '🍋',
      name: 'lemon',
      nameUrl: 'lemon',
    },
    {
      id: 597,
      code: 'U+1F34C',
      emoji: '🍌',
      name: 'banana',
      nameUrl: 'banana',
    },
    {
      id: 598,
      code: 'U+1F34D',
      emoji: '🍍',
      name: 'pineapple',
      nameUrl: 'pineapple',
    },
    {
      id: 599,
      code: 'U+1F96D',
      emoji: '🥭',
      name: 'mango',
      nameUrl: 'mango',
    },
    {
      id: 600,
      code: 'U+1F34E',
      emoji: '🍎',
      name: 'red apple',
      nameUrl: 'red-apple',
    },
    {
      id: 601,
      code: 'U+1F34F',
      emoji: '🍏',
      name: 'green apple',
      nameUrl: 'green-apple',
    },
    {
      id: 602,
      code: 'U+1F350',
      emoji: '🍐',
      name: 'pear',
      nameUrl: 'pear',
    },
    {
      id: 603,
      code: 'U+1F351',
      emoji: '🍑',
      name: 'peach',
      nameUrl: 'peach',
    },
    {
      id: 604,
      code: 'U+1F352',
      emoji: '🍒',
      name: 'cherries',
      nameUrl: 'cherries',
    },
    {
      id: 605,
      code: 'U+1F353',
      emoji: '🍓',
      name: 'strawberry',
      nameUrl: 'strawberry',
    },
    {
      id: 606,
      code: 'U+1F95D',
      emoji: '🥝',
      name: 'kiwi fruit',
      nameUrl: 'kiwi-fruit',
    },
    {
      id: 607,
      code: 'U+1F345',
      emoji: '🍅',
      name: 'tomato',
      nameUrl: 'tomato',
    },
    {
      id: 608,
      code: 'U+1F965',
      emoji: '🥥',
      name: 'coconut',
      nameUrl: 'coconut',
    },
    {
      id: 609,
      code: 'U+1F951',
      emoji: '🥑',
      name: 'avocado',
      nameUrl: 'avocado',
    },
    {
      id: 610,
      code: 'U+1F346',
      emoji: '🍆',
      name: 'eggplant',
      nameUrl: 'eggplant',
    },
    {
      id: 611,
      code: 'U+1F954',
      emoji: '🥔',
      name: 'potato',
      nameUrl: 'potato',
    },
    {
      id: 612,
      code: 'U+1F955',
      emoji: '🥕',
      name: 'carrot',
      nameUrl: 'carrot',
    },
    {
      id: 613,
      code: 'U+1F33D',
      emoji: '🌽',
      name: 'ear of corn',
      nameUrl: 'ear-of-corn',
    },
    {
      id: 614,
      code: 'U+1F336',
      emoji: '🌶',
      name: 'hot pepper',
      nameUrl: 'hot-pepper',
    },
    {
      id: 615,
      code: 'U+1F952',
      emoji: '🥒',
      name: 'cucumber',
      nameUrl: 'cucumber',
    },
    {
      id: 616,
      code: 'U+1F96C',
      emoji: '🥬',
      name: 'leafy green',
      nameUrl: 'leafy-green',
    },
    {
      id: 617,
      code: 'U+1F966',
      emoji: '🥦',
      name: 'broccoli',
      nameUrl: 'broccoli',
    },
    {
      id: 620,
      code: 'U+1F344',
      emoji: '🍄',
      name: 'mushroom',
      nameUrl: 'mushroom',
    },
    {
      id: 621,
      code: 'U+1F95C',
      emoji: '🥜',
      name: 'peanuts',
      nameUrl: 'peanuts',
    },
    {
      id: 622,
      code: 'U+1F330',
      emoji: '🌰',
      name: 'chestnut',
      nameUrl: 'chestnut',
    },
    {
      id: 623,
      code: 'U+1F35E',
      emoji: '🍞',
      name: 'bread',
      nameUrl: 'bread',
    },
    {
      id: 624,
      code: 'U+1F950',
      emoji: '🥐',
      name: 'croissant',
      nameUrl: 'croissant',
    },
    {
      id: 625,
      code: 'U+1F956',
      emoji: '🥖',
      name: 'baguette bread',
      nameUrl: 'baguette-bread',
    },
    {
      id: 626,
      code: 'U+1F968',
      emoji: '🥨',
      name: 'pretzel',
      nameUrl: 'pretzel',
    },
    {
      id: 627,
      code: 'U+1F96F',
      emoji: '🥯',
      name: 'bagel',
      nameUrl: 'bagel',
    },
    {
      id: 628,
      code: 'U+1F95E',
      emoji: '🥞',
      name: 'pancakes',
      nameUrl: 'pancakes',
    },
    {
      id: 630,
      code: 'U+1F9C0',
      emoji: '🧀',
      name: 'cheese wedge',
      nameUrl: 'cheese-wedge',
    },
    {
      id: 631,
      code: 'U+1F356',
      emoji: '🍖',
      name: 'meat on bone',
      nameUrl: 'meat-on-bone',
    },
    {
      id: 632,
      code: 'U+1F357',
      emoji: '🍗',
      name: 'poultry leg',
      nameUrl: 'poultry-leg',
    },
    {
      id: 633,
      code: 'U+1F969',
      emoji: '🥩',
      name: 'cut of meat',
      nameUrl: 'cut-of-meat',
    },
    {
      id: 634,
      code: 'U+1F953',
      emoji: '🥓',
      name: 'bacon',
      nameUrl: 'bacon',
    },
    {
      id: 635,
      code: 'U+1F354',
      emoji: '🍔',
      name: 'hamburger',
      nameUrl: 'hamburger',
    },
    {
      id: 636,
      code: 'U+1F35F',
      emoji: '🍟',
      name: 'french fries',
      nameUrl: 'french-fries',
    },
    {
      id: 637,
      code: 'U+1F355',
      emoji: '🍕',
      name: 'pizza',
      nameUrl: 'pizza',
    },
    {
      id: 638,
      code: 'U+1F32D',
      emoji: '🌭',
      name: 'hot dog',
      nameUrl: 'hot-dog',
    },
    {
      id: 639,
      code: 'U+1F96A',
      emoji: '🥪',
      name: 'sandwich',
      nameUrl: 'sandwich',
    },
    {
      id: 640,
      code: 'U+1F32E',
      emoji: '🌮',
      name: 'taco',
      nameUrl: 'taco',
    },
    {
      id: 641,
      code: 'U+1F32F',
      emoji: '🌯',
      name: 'burrito',
      nameUrl: 'burrito',
    },
    {
      id: 642,
      code: 'U+1F959',
      emoji: '🥙',
      name: 'stuffed flatbread',
      nameUrl: 'stuffed-flatbread',
    },
    {
      id: 644,
      code: 'U+1F95A',
      emoji: '🥚',
      name: 'egg',
      nameUrl: 'egg',
    },
    {
      id: 645,
      code: 'U+1F373',
      emoji: '🍳',
      name: 'cooking',
      nameUrl: 'cooking',
    },
    {
      id: 646,
      code: 'U+1F958',
      emoji: '🥘',
      name: 'shallow pan of food',
      nameUrl: 'shallow-pan-of-food',
    },
    {
      id: 647,
      code: 'U+1F372',
      emoji: '🍲',
      name: 'pot of food',
      nameUrl: 'pot-of-food',
    },
    {
      id: 648,
      code: 'U+1F963',
      emoji: '🥣',
      name: 'bowl with spoon',
      nameUrl: 'bowl-with-spoon',
    },
    {
      id: 649,
      code: 'U+1F957',
      emoji: '🥗',
      name: 'green salad',
      nameUrl: 'green-salad',
    },
    {
      id: 650,
      code: 'U+1F37F',
      emoji: '🍿',
      name: 'popcorn',
      nameUrl: 'popcorn',
    },
    {
      id: 652,
      code: 'U+1F9C2',
      emoji: '🧂',
      name: 'salt',
      nameUrl: 'salt',
    },
    {
      id: 653,
      code: 'U+1F96B',
      emoji: '🥫',
      name: 'canned food',
      nameUrl: 'canned-food',
    },
    {
      id: 654,
      code: 'U+1F371',
      emoji: '🍱',
      name: 'bento box',
      nameUrl: 'bento-box',
    },
    {
      id: 655,
      code: 'U+1F358',
      emoji: '🍘',
      name: 'rice cracker',
      nameUrl: 'rice-cracker',
    },
    {
      id: 656,
      code: 'U+1F359',
      emoji: '🍙',
      name: 'rice ball',
      nameUrl: 'rice-ball',
    },
    {
      id: 657,
      code: 'U+1F35A',
      emoji: '🍚',
      name: 'cooked rice',
      nameUrl: 'cooked-rice',
    },
    {
      id: 658,
      code: 'U+1F35B',
      emoji: '🍛',
      name: 'curry rice',
      nameUrl: 'curry-rice',
    },
    {
      id: 659,
      code: 'U+1F35C',
      emoji: '🍜',
      name: 'steaming bowl',
      nameUrl: 'steaming-bowl',
    },
    {
      id: 660,
      code: 'U+1F35D',
      emoji: '🍝',
      name: 'spaghetti',
      nameUrl: 'spaghetti',
    },
    {
      id: 661,
      code: 'U+1F360',
      emoji: '🍠',
      name: 'roasted sweet potato',
      nameUrl: 'roasted-sweet-potato',
    },
    {
      id: 662,
      code: 'U+1F362',
      emoji: '🍢',
      name: 'oden',
      nameUrl: 'oden',
    },
    {
      id: 663,
      code: 'U+1F363',
      emoji: '🍣',
      name: 'sushi',
      nameUrl: 'sushi',
    },
    {
      id: 664,
      code: 'U+1F364',
      emoji: '🍤',
      name: 'fried shrimp',
      nameUrl: 'fried-shrimp',
    },
    {
      id: 665,
      code: 'U+1F365',
      emoji: '🍥',
      name: 'fish cake with swirl',
      nameUrl: 'fish-cake-with-swirl',
    },
    {
      id: 666,
      code: 'U+1F96E',
      emoji: '🥮',
      name: 'moon cake',
      nameUrl: 'moon-cake',
    },
    {
      id: 667,
      code: 'U+1F361',
      emoji: '🍡',
      name: 'dango',
      nameUrl: 'dango',
    },
    {
      id: 668,
      code: 'U+1F95F',
      emoji: '🥟',
      name: 'dumpling',
      nameUrl: 'dumpling',
    },
    {
      id: 669,
      code: 'U+1F960',
      emoji: '🥠',
      name: 'fortune cookie',
      nameUrl: 'fortune-cookie',
    },
    {
      id: 670,
      code: 'U+1F961',
      emoji: '🥡',
      name: 'takeout box',
      nameUrl: 'takeout-box',
    },
    {
      id: 671,
      code: 'U+1F980',
      emoji: '🦀',
      name: 'crab',
      nameUrl: 'crab',
    },
    {
      id: 672,
      code: 'U+1F99E',
      emoji: '🦞',
      name: 'lobster',
      nameUrl: 'lobster',
    },
    {
      id: 673,
      code: 'U+1F990',
      emoji: '🦐',
      name: 'shrimp',
      nameUrl: 'shrimp',
    },
    {
      id: 674,
      code: 'U+1F991',
      emoji: '🦑',
      name: 'squid',
      nameUrl: 'squid',
    },
    {
      id: 676,
      code: 'U+1F366',
      emoji: '🍦',
      name: 'soft ice cream',
      nameUrl: 'soft-ice-cream',
    },
    {
      id: 677,
      code: 'U+1F367',
      emoji: '🍧',
      name: 'shaved ice',
      nameUrl: 'shaved-ice',
    },
    {
      id: 678,
      code: 'U+1F368',
      emoji: '🍨',
      name: 'ice cream',
      nameUrl: 'ice-cream',
    },
    {
      id: 679,
      code: 'U+1F369',
      emoji: '🍩',
      name: 'doughnut',
      nameUrl: 'doughnut',
    },
    {
      id: 680,
      code: 'U+1F36A',
      emoji: '🍪',
      name: 'cookie',
      nameUrl: 'cookie',
    },
    {
      id: 681,
      code: 'U+1F382',
      emoji: '🎂',
      name: 'birthday cake',
      nameUrl: 'birthday-cake',
    },
    {
      id: 682,
      code: 'U+1F370',
      emoji: '🍰',
      name: 'shortcake',
      nameUrl: 'shortcake',
    },
    {
      id: 683,
      code: 'U+1F9C1',
      emoji: '🧁',
      name: 'cupcake',
      nameUrl: 'cupcake',
    },
    {
      id: 684,
      code: 'U+1F967',
      emoji: '🥧',
      name: 'pie',
      nameUrl: 'pie',
    },
    {
      id: 685,
      code: 'U+1F36B',
      emoji: '🍫',
      name: 'chocolate bar',
      nameUrl: 'chocolate-bar',
    },
    {
      id: 686,
      code: 'U+1F36C',
      emoji: '🍬',
      name: 'candy',
      nameUrl: 'candy',
    },
    {
      id: 687,
      code: 'U+1F36D',
      emoji: '🍭',
      name: 'lollipop',
      nameUrl: 'lollipop',
    },
    {
      id: 688,
      code: 'U+1F36E',
      emoji: '🍮',
      name: 'custard',
      nameUrl: 'custard',
    },
    {
      id: 689,
      code: 'U+1F36F',
      emoji: '🍯',
      name: 'honey pot',
      nameUrl: 'honey-pot',
    },
    {
      id: 690,
      code: 'U+1F37C',
      emoji: '🍼',
      name: 'baby bottle',
      nameUrl: 'baby-bottle',
    },
    {
      id: 691,
      code: 'U+1F95B',
      emoji: '🥛',
      name: 'glass of milk',
      nameUrl: 'glass-of-milk',
    },
    {
      id: 692,
      code: 'U+2615',
      emoji: '☕',
      name: 'hot beverage',
      nameUrl: 'hot-beverage',
    },
    {
      id: 693,
      code: 'U+1F375',
      emoji: '🍵',
      name: 'teacup without handle',
      nameUrl: 'teacup-without-handle',
    },
    {
      id: 694,
      code: 'U+1F376',
      emoji: '🍶',
      name: 'sake',
      nameUrl: 'sake',
    },
    {
      id: 695,
      code: 'U+1F37E',
      emoji: '🍾',
      name: 'bottle with popping cork',
      nameUrl: 'bottle-with-popping-cork',
    },
    {
      id: 696,
      code: 'U+1F377',
      emoji: '🍷',
      name: 'wine glass',
      nameUrl: 'wine-glass',
    },
    {
      id: 697,
      code: 'U+1F378',
      emoji: '🍸',
      name: 'cocktail glass',
      nameUrl: 'cocktail-glass',
    },
    {
      id: 698,
      code: 'U+1F379',
      emoji: '🍹',
      name: 'tropical drink',
      nameUrl: 'tropical-drink',
    },
    {
      id: 699,
      code: 'U+1F37A',
      emoji: '🍺',
      name: 'beer mug',
      nameUrl: 'beer-mug',
    },
    {
      id: 700,
      code: 'U+1F37B',
      emoji: '🍻',
      name: 'clinking beer mugs',
      nameUrl: 'clinking-beer-mugs',
    },
    {
      id: 701,
      code: 'U+1F942',
      emoji: '🥂',
      name: 'clinking glasses',
      nameUrl: 'clinking-glasses',
    },
    {
      id: 702,
      code: 'U+1F943',
      emoji: '🥃',
      name: 'tumbler glass',
      nameUrl: 'tumbler-glass',
    },
    {
      id: 703,
      code: 'U+1F964',
      emoji: '🥤',
      name: 'cup with straw',
      nameUrl: 'cup-with-straw',
    },
    {
      id: 707,
      code: 'U+1F962',
      emoji: '🥢',
      name: 'chopsticks',
      nameUrl: 'chopsticks',
    },
    {
      id: 708,
      code: 'U+1F37D',
      emoji: '🍽',
      name: 'fork and knife with plate',
      nameUrl: 'fork-and-knife-with-plate',
    },
    {
      id: 709,
      code: 'U+1F374',
      emoji: '🍴',
      name: 'fork and knife',
      nameUrl: 'fork-and-knife',
    },
    {
      id: 710,
      code: 'U+1F944',
      emoji: '🥄',
      name: 'spoon',
      nameUrl: 'spoon',
    },
    {
      id: 711,
      code: 'U+1F52A',
      emoji: '🔪',
      name: 'kitchen knife',
      nameUrl: 'kitchen-knife',
    },
    {
      id: 712,
      code: 'U+1F3FA',
      emoji: '🏺',
      name: 'amphora',
      nameUrl: 'amphora',
      },
    {
      id: 713,
      code: 'U+1F30D',
      emoji: '🌍',
      name: 'globe showing Europe-Africa',
      nameUrl: 'globe-showing-europe-africa',
    },
    {
      id: 714,
      code: 'U+1F30E',
      emoji: '🌎',
      name: 'globe showing Americas',
      nameUrl: 'globe-showing-americas',
    },
    {
      id: 715,
      code: 'U+1F30F',
      emoji: '🌏',
      name: 'globe showing Asia-Australia',
      nameUrl: 'globe-showing-asia-australia',
    },
    {
      id: 716,
      code: 'U+1F310',
      emoji: '🌐',
      name: 'globe with meridians',
      nameUrl: 'globe-with-meridians',
    },
    {
      id: 717,
      code: 'U+1F5FA',
      emoji: '🗺',
      name: 'world map',
      nameUrl: 'world-map',
    },
    {
      id: 718,
      code: 'U+1F5FE',
      emoji: '🗾',
      name: 'map of Japan',
      nameUrl: 'map-of-japan',
    },
    {
      id: 719,
      code: 'U+1F9ED',
      emoji: '🧭',
      name: 'compass',
      nameUrl: 'compass',
    },
    {
      id: 720,
      code: 'U+1F3D4',
      emoji: '🏔',
      name: 'snow-capped mountain',
      nameUrl: 'snow-capped-mountain',
    },
    {
      id: 721,
      code: 'U+26F0',
      emoji: '⛰',
      name: 'mountain',
      nameUrl: 'mountain',
    },
    {
      id: 722,
      code: 'U+1F30B',
      emoji: '🌋',
      name: 'volcano',
      nameUrl: 'volcano',
    },
    {
      id: 723,
      code: 'U+1F5FB',
      emoji: '🗻',
      name: 'mount fuji',
      nameUrl: 'mount-fuji',
    },
    {
      id: 724,
      code: 'U+1F3D5',
      emoji: '🏕',
      name: 'camping',
      nameUrl: 'camping',
    },
    {
      id: 725,
      code: 'U+1F3D6',
      emoji: '🏖',
      name: 'beach with umbrella',
      nameUrl: 'beach-with-umbrella',
    },
    {
      id: 726,
      code: 'U+1F3DC',
      emoji: '🏜',
      name: 'desert',
      nameUrl: 'desert',
    },
    {
      id: 727,
      code: 'U+1F3DD',
      emoji: '🏝',
      name: 'desert island',
      nameUrl: 'desert-island',
    },
    {
      id: 728,
      code: 'U+1F3DE',
      emoji: '🏞',
      name: 'national park',
      nameUrl: 'national-park',
    },
    {
      id: 729,
      code: 'U+1F3DF',
      emoji: '🏟',
      name: 'stadium',
      nameUrl: 'stadium',
    },
    {
      id: 730,
      code: 'U+1F3DB',
      emoji: '🏛',
      name: 'classical building',
      nameUrl: 'classical-building',
    },
    {
      id: 731,
      code: 'U+1F3D7',
      emoji: '🏗',
      name: 'building construction',
      nameUrl: 'building-construction',
    },
    {
      id: 732,
      code: 'U+1F9F1',
      emoji: '🧱',
      name: 'brick',
      nameUrl: 'brick',
    },
    {
      id: 733,
      code: 'U+1F3D8',
      emoji: '🏘',
      name: 'houses',
      nameUrl: 'houses',
    },
    {
      id: 734,
      code: 'U+1F3DA',
      emoji: '🏚',
      name: 'derelict house',
      nameUrl: 'derelict-house',
    },
    {
      id: 735,
      code: 'U+1F3E0',
      emoji: '🏠',
      name: 'house',
      nameUrl: 'house',
    },
    {
      id: 736,
      code: 'U+1F3E1',
      emoji: '🏡',
      name: 'house with garden',
      nameUrl: 'house-with-garden',
    },
    {
      id: 737,
      code: 'U+1F3E2',
      emoji: '🏢',
      name: 'office building',
      nameUrl: 'office-building',
    },
    {
      id: 738,
      code: 'U+1F3E3',
      emoji: '🏣',
      name: 'Japanese post office',
      nameUrl: 'japanese-post-office',
    },
    {
      id: 739,
      code: 'U+1F3E4',
      emoji: '🏤',
      name: 'post office',
      nameUrl: 'post-office',
    },
    {
      id: 740,
      code: 'U+1F3E5',
      emoji: '🏥',
      name: 'hospital',
      nameUrl: 'hospital',
    },
    {
      id: 741,
      code: 'U+1F3E6',
      emoji: '🏦',
      name: 'bank',
      nameUrl: 'bank',
    },
    {
      id: 742,
      code: 'U+1F3E8',
      emoji: '🏨',
      name: 'hotel',
      nameUrl: 'hotel',
    },
    {
      id: 743,
      code: 'U+1F3E9',
      emoji: '🏩',
      name: 'love hotel',
      nameUrl: 'love-hotel',
    },
    {
      id: 744,
      code: 'U+1F3EA',
      emoji: '🏪',
      name: 'convenience store',
      nameUrl: 'convenience-store',
    },
    {
      id: 745,
      code: 'U+1F3EB',
      emoji: '🏫',
      name: 'school',
      nameUrl: 'school',
    },
    {
      id: 746,
      code: 'U+1F3EC',
      emoji: '🏬',
      name: 'department store',
      nameUrl: 'department-store',
    },
    {
      id: 747,
      code: 'U+1F3ED',
      emoji: '🏭',
      name: 'factory',
      nameUrl: 'factory',
    },
    {
      id: 748,
      code: 'U+1F3EF',
      emoji: '🏯',
      name: 'Japanese castle',
      nameUrl: 'japanese-castle',
    },
    {
      id: 749,
      code: 'U+1F3F0',
      emoji: '🏰',
      name: 'castle',
      nameUrl: 'castle',
    },
    {
      id: 750,
      code: 'U+1F492',
      emoji: '💒',
      name: 'wedding',
      nameUrl: 'wedding',
    },
    {
      id: 751,
      code: 'U+1F5FC',
      emoji: '🗼',
      name: 'Tokyo tower',
      nameUrl: 'tokyo-tower',
    },
    {
      id: 752,
      code: 'U+1F5FD',
      emoji: '🗽',
      name: 'Statue of Liberty',
      nameUrl: 'statue-of-liberty',
    },
    {
      id: 753,
      code: 'U+26EA',
      emoji: '⛪',
      name: 'church',
      nameUrl: 'church',
    },
    {
      id: 754,
      code: 'U+1F54C',
      emoji: '🕌',
      name: 'mosque',
      nameUrl: 'mosque',
    },
    {
      id: 756,
      code: 'U+1F54D',
      emoji: '🕍',
      name: 'synagogue',
      nameUrl: 'synagogue',
    },
    {
      id: 757,
      code: 'U+26E9',
      emoji: '⛩',
      name: 'shinto shrine',
      nameUrl: 'shinto-shrine',
    },
    {
      id: 758,
      code: 'U+1F54B',
      emoji: '🕋',
      name: 'kaaba',
      nameUrl: 'kaaba',
    },
    {
      id: 759,
      code: 'U+26F2',
      emoji: '⛲',
      name: 'fountain',
      nameUrl: 'fountain',
    },
    {
      id: 760,
      code: 'U+26FA',
      emoji: '⛺',
      name: 'tent',
      nameUrl: 'tent',
    },
    {
      id: 761,
      code: 'U+1F301',
      emoji: '🌁',
      name: 'foggy',
      nameUrl: 'foggy',
    },
    {
      id: 762,
      code: 'U+1F303',
      emoji: '🌃',
      name: 'night with stars',
      nameUrl: 'night-with-stars',
    },
    {
      id: 763,
      code: 'U+1F3D9',
      emoji: '🏙',
      name: 'cityscape',
      nameUrl: 'cityscape',
    },
    {
      id: 764,
      code: 'U+1F304',
      emoji: '🌄',
      name: 'sunrise over mountains',
      nameUrl: 'sunrise-over-mountains',
    },
    {
      id: 765,
      code: 'U+1F305',
      emoji: '🌅',
      name: 'sunrise',
      nameUrl: 'sunrise',
    },
    {
      id: 766,
      code: 'U+1F306',
      emoji: '🌆',
      name: 'cityscape at dusk',
      nameUrl: 'cityscape-at-dusk',
    },
    {
      id: 767,
      code: 'U+1F307',
      emoji: '🌇',
      name: 'sunset',
      nameUrl: 'sunset',
    },
    {
      id: 768,
      code: 'U+1F309',
      emoji: '🌉',
      name: 'bridge at night',
      nameUrl: 'bridge-at-night',
    },
    {
      id: 769,
      code: 'U+2668',
      emoji: '♨️',
      name: 'hot springs',
      nameUrl: 'hot-springs',
    },
    {
      id: 770,
      code: 'U+1F3A0',
      emoji: '🎠',
      name: 'carousel horse',
      nameUrl: 'carousel-horse',
    },
    {
      id: 771,
      code: 'U+1F3A1',
      emoji: '🎡',
      name: 'ferris wheel',
      nameUrl: 'ferris-wheel',
    },
    {
      id: 772,
      code: 'U+1F3A2',
      emoji: '🎢',
      name: 'roller coaster',
      nameUrl: 'roller-coaster',
    },
    {
      id: 773,
      code: 'U+1F488',
      emoji: '💈',
      name: 'barber pole',
      nameUrl: 'barber-pole',
    },
    {
      id: 774,
      code: 'U+1F3AA',
      emoji: '🎪',
      name: 'circus tent',
      nameUrl: 'circus-tent',
    },
    {
      id: 775,
      code: 'U+1F682',
      emoji: '🚂',
      name: 'locomotive',
      nameUrl: 'locomotive',
    },
    {
      id: 776,
      code: 'U+1F683',
      emoji: '🚃',
      name: 'railway car',
      nameUrl: 'railway-car',
    },
    {
      id: 777,
      code: 'U+1F684',
      emoji: '🚄',
      name: 'high-speed train',
      nameUrl: 'high-speed-train',
    },
    {
      id: 778,
      code: 'U+1F685',
      emoji: '🚅',
      name: 'bullet train',
      nameUrl: 'bullet-train',
    },
    {
      id: 779,
      code: 'U+1F686',
      emoji: '🚆',
      name: 'train',
      nameUrl: 'train',
    },
    {
      id: 780,
      code: 'U+1F687',
      emoji: '🚇',
      name: 'metro',
      nameUrl: 'metro',
    },
    {
      id: 781,
      code: 'U+1F688',
      emoji: '🚈',
      name: 'light rail',
      nameUrl: 'light-rail',
    },
    {
      id: 782,
      code: 'U+1F689',
      emoji: '🚉',
      name: 'station',
      nameUrl: 'station',
    },
    {
      id: 783,
      code: 'U+1F68A',
      emoji: '🚊',
      name: 'tram',
      nameUrl: 'tram',
    },
    {
      id: 784,
      code: 'U+1F69D',
      emoji: '🚝',
      name: 'monorail',
      nameUrl: 'monorail',
    },
    {
      id: 785,
      code: 'U+1F69E',
      emoji: '🚞',
      name: 'mountain railway',
      nameUrl: 'mountain-railway',
    },
    {
      id: 786,
      code: 'U+1F68B',
      emoji: '🚋',
      name: 'tram car',
      nameUrl: 'tram-car',
    },
    {
      id: 787,
      code: 'U+1F68C',
      emoji: '🚌',
      name: 'bus',
      nameUrl: 'bus',
    },
    {
      id: 788,
      code: 'U+1F68D',
      emoji: '🚍',
      name: 'oncoming bus',
      nameUrl: 'oncoming-bus',
    },
    {
      id: 789,
      code: 'U+1F68E',
      emoji: '🚎',
      name: 'trolleybus',
      nameUrl: 'trolleybus',
    },
    {
      id: 790,
      code: 'U+1F690',
      emoji: '🚐',
      name: 'minibus',
      nameUrl: 'minibus',
    },
    {
      id: 791,
      code: 'U+1F691',
      emoji: '🚑',
      name: 'ambulance',
      nameUrl: 'ambulance',
    },
    {
      id: 792,
      code: 'U+1F692',
      emoji: '🚒',
      name: 'fire engine',
      nameUrl: 'fire-engine',
    },
    {
      id: 793,
      code: 'U+1F693',
      emoji: '🚓',
      name: 'police car',
      nameUrl: 'police-car',
    },
    {
      id: 794,
      code: 'U+1F694',
      emoji: '🚔',
      name: 'oncoming police car',
      nameUrl: 'oncoming-police-car',
    },
    {
      id: 795,
      code: 'U+1F695',
      emoji: '🚕',
      name: 'taxi',
      nameUrl: 'taxi',
    },
    {
      id: 796,
      code: 'U+1F696',
      emoji: '🚖',
      name: 'oncoming taxi',
      nameUrl: 'oncoming-taxi',
    },
    {
      id: 797,
      code: 'U+1F697',
      emoji: '🚗',
      name: 'automobile',
      nameUrl: 'automobile',
    },
    {
      id: 798,
      code: 'U+1F698',
      emoji: '🚘',
      name: 'oncoming automobile',
      nameUrl: 'oncoming-automobile',
    },
    {
      id: 799,
      code: 'U+1F699',
      emoji: '🚙',
      name: 'sport utility vehicle',
      nameUrl: 'sport-utility-vehicle',
    },
    {
      id: 800,
      code: 'U+1F69A',
      emoji: '🚚',
      name: 'delivery truck',
      nameUrl: 'delivery-truck',
    },
    {
      id: 801,
      code: 'U+1F69B',
      emoji: '🚛',
      name: 'articulated lorry',
      nameUrl: 'articulated-lorry',
    },
    {
      id: 802,
      code: 'U+1F69C',
      emoji: '🚜',
      name: 'tractor',
      nameUrl: 'tractor',
    },
    {
      id: 803,
      code: 'U+1F3CE',
      emoji: '🏎',
      name: 'racing car',
      nameUrl: 'racing-car',
    },
    {
      id: 804,
      code: 'U+1F3CD',
      emoji: '🏍',
      name: 'motorcycle',
      nameUrl: 'motorcycle',
    },
    {
      id: 805,
      code: 'U+1F6F5',
      emoji: '🛵',
      name: 'motor scooter',
      nameUrl: 'motor-scooter',
    },
    {
      id: 809,
      code: 'U+1F6B2',
      emoji: '🚲',
      name: 'bicycle',
      nameUrl: 'bicycle',
    },
    {
      id: 810,
      code: 'U+1F6F4',
      emoji: '🛴',
      name: 'kick scooter',
      nameUrl: 'kick-scooter',
    },
    {
      id: 811,
      code: 'U+1F6F9',
      emoji: '🛹',
      name: 'skateboard',
      nameUrl: 'skateboard',
    },
    {
      id: 812,
      code: 'U+1F68F',
      emoji: '🚏',
      name: 'bus stop',
      nameUrl: 'bus-stop',
    },
    {
      id: 813,
      code: 'U+1F6E3',
      emoji: '🛣',
      name: 'motorway',
      nameUrl: 'motorway',
    },
    {
      id: 814,
      code: 'U+1F6E4',
      emoji: '🛤',
      name: 'railway track',
      nameUrl: 'railway-track',
    },
    {
      id: 815,
      code: 'U+1F6E2',
      emoji: '🛢',
      name: 'oil drum',
      nameUrl: 'oil-drum',
    },
    {
      id: 816,
      code: 'U+26FD',
      emoji: '⛽',
      name: 'fuel pump',
      nameUrl: 'fuel-pump',
    },
    {
      id: 817,
      code: 'U+1F6A8',
      emoji: '🚨',
      name: 'police car light',
      nameUrl: 'police-car-light',
    },
    {
      id: 818,
      code: 'U+1F6A5',
      emoji: '🚥',
      name: 'horizontal traffic light',
      nameUrl: 'horizontal-traffic-light',
    },
    {
      id: 819,
      code: 'U+1F6A6',
      emoji: '🚦',
      name: 'vertical traffic light',
      nameUrl: 'vertical-traffic-light',
    },
    {
      id: 820,
      code: 'U+1F6D1',
      emoji: '🛑',
      name: 'stop sign',
      nameUrl: 'stop-sign',
    },
    {
      id: 821,
      code: 'U+1F6A7',
      emoji: '🚧',
      name: 'construction',
      nameUrl: 'construction',
    },
    {
      id: 822,
      code: 'U+2693',
      emoji: '⚓',
      name: 'anchor',
      nameUrl: 'anchor',
    },
    {
      id: 823,
      code: 'U+26F5',
      emoji: '⛵',
      name: 'sailboat',
      nameUrl: 'sailboat',
    },
    {
      id: 824,
      code: 'U+1F6F6',
      emoji: '🛶',
      name: 'canoe',
      nameUrl: 'canoe',
    },
    {
      id: 825,
      code: 'U+1F6A4',
      emoji: '🚤',
      name: 'speedboat',
      nameUrl: 'speedboat',
    },
    {
      id: 826,
      code: 'U+1F6F3',
      emoji: '🛳',
      name: 'passenger ship',
      nameUrl: 'passenger-ship',
    },
    {
      id: 827,
      code: 'U+26F4',
      emoji: '⛴',
      name: 'ferry',
      nameUrl: 'ferry',
    },
    {
      id: 828,
      code: 'U+1F6E5',
      emoji: '🛥',
      name: 'motor boat',
      nameUrl: 'motor-boat',
    },
    {
      id: 829,
      code: 'U+1F6A2',
      emoji: '🚢',
      name: 'ship',
      nameUrl: 'ship',
    },
    {
      id: 830,
      code: 'U+2708',
      emoji: '✈️',
      name: 'airplane',
      nameUrl: 'airplane',
    },
    {
      id: 831,
      code: 'U+1F6E9',
      emoji: '🛩',
      name: 'small airplane',
      nameUrl: 'small-airplane',
    },
    {
      id: 832,
      code: 'U+1F6EB',
      emoji: '🛫',
      name: 'airplane departure',
      nameUrl: 'airplane-departure',
    },
    {
      id: 833,
      code: 'U+1F6EC',
      emoji: '🛬',
      name: 'airplane arrival',
      nameUrl: 'airplane-arrival',
    },
    {
      id: 835,
      code: 'U+1F4BA',
      emoji: '💺',
      name: 'seat',
      nameUrl: 'seat',
    },
    {
      id: 836,
      code: 'U+1F681',
      emoji: '🚁',
      name: 'helicopter',
      nameUrl: 'helicopter',
    },
    {
      id: 837,
      code: 'U+1F69F',
      emoji: '🚟',
      name: 'suspension railway',
      nameUrl: 'suspension-railway',
    },
    {
      id: 838,
      code: 'U+1F6A0',
      emoji: '🚠',
      name: 'mountain cableway',
      nameUrl: 'mountain-cableway',
    },
    {
      id: 839,
      code: 'U+1F6A1',
      emoji: '🚡',
      name: 'aerial tramway',
      nameUrl: 'aerial-tramway',
    },
    {
      id: 840,
      code: 'U+1F6F0',
      emoji: '🛰',
      name: 'satellite',
      nameUrl: 'satellite',
    },
    {
      id: 841,
      code: 'U+1F680',
      emoji: '🚀',
      name: 'rocket',
      nameUrl: 'rocket',
    },
    {
      id: 842,
      code: 'U+1F6F8',
      emoji: '🛸',
      name: 'flying saucer',
      nameUrl: 'flying-saucer',
    },
    {
      id: 843,
      code: 'U+1F6CE',
      emoji: '🛎',
      name: 'bellhop bell',
      nameUrl: 'bellhop-bell',
    },
    {
      id: 844,
      code: 'U+1F9F3',
      emoji: '🧳',
      name: 'luggage',
      nameUrl: 'luggage',
    },
    {
      id: 845,
      code: 'U+231B',
      emoji: '⌛',
      name: 'hourglass done',
      nameUrl: 'hourglass-done',
    },
    {
      id: 846,
      code: 'U+23F3',
      emoji: '⏳',
      name: 'hourglass not done',
      nameUrl: 'hourglass-not-done',
    },
    {
      id: 847,
      code: 'U+231A',
      emoji: '⌚',
      name: 'watch',
      nameUrl: 'watch',
    },
    {
      id: 848,
      code: 'U+23F0',
      emoji: '⏰',
      name: 'alarm clock',
      nameUrl: 'alarm-clock',
    },
    {
      id: 849,
      code: 'U+23F1',
      emoji: '⏱',
      name: 'stopwatch',
      nameUrl: 'stopwatch',
    },
    {
      id: 850,
      code: 'U+23F2',
      emoji: '⏲',
      name: 'timer clock',
      nameUrl: 'timer-clock',
    },
    {
      id: 851,
      code: 'U+1F570',
      emoji: '🕰',
      name: 'mantelpiece clock',
      nameUrl: 'mantelpiece-clock',
    },
    {
      id: 852,
      code: 'U+1F55B',
      emoji: '🕛',
      name: 'twelve o’clock',
      nameUrl: 'twelve-oclock',
    },
    {
      id: 853,
      code: 'U+1F567',
      emoji: '🕧',
      name: 'twelve-thirty',
      nameUrl: 'twelve-thirty',
    },
    {
      id: 854,
      code: 'U+1F550',
      emoji: '🕐',
      name: 'one o’clock',
      nameUrl: 'one-oclock',
    },
    {
      id: 855,
      code: 'U+1F55C',
      emoji: '🕜',
      name: 'one-thirty',
      nameUrl: 'one-thirty',
    },
    {
      id: 856,
      code: 'U+1F551',
      emoji: '🕑',
      name: 'two o’clock',
      nameUrl: 'two-oclock',
    },
    {
      id: 857,
      code: 'U+1F55D',
      emoji: '🕝',
      name: 'two-thirty',
      nameUrl: 'two-thirty',
    },
    {
      id: 858,
      code: 'U+1F552',
      emoji: '🕒',
      name: 'three o’clock',
      nameUrl: 'three-oclock',
    },
    {
      id: 859,
      code: 'U+1F55E',
      emoji: '🕞',
      name: 'three-thirty',
      nameUrl: 'three-thirty',
    },
    {
      id: 860,
      code: 'U+1F553',
      emoji: '🕓',
      name: 'four o’clock',
      nameUrl: 'four-oclock',
    },
    {
      id: 861,
      code: 'U+1F55F',
      emoji: '🕟',
      name: 'four-thirty',
      nameUrl: 'four-thirty',
    },
    {
      id: 862,
      code: 'U+1F554',
      emoji: '🕔',
      name: 'five o’clock',
      nameUrl: 'five-oclock',
    },
    {
      id: 863,
      code: 'U+1F560',
      emoji: '🕠',
      name: 'five-thirty',
      nameUrl: 'five-thirty',
    },
    {
      id: 864,
      code: 'U+1F555',
      emoji: '🕕',
      name: 'six o’clock',
      nameUrl: 'six-oclock',
    },
    {
      id: 865,
      code: 'U+1F561',
      emoji: '🕡',
      name: 'six-thirty',
      nameUrl: 'six-thirty',
    },
    {
      id: 866,
      code: 'U+1F556',
      emoji: '🕖',
      name: 'seven o’clock',
      nameUrl: 'seven-oclock',
    },
    {
      id: 867,
      code: 'U+1F562',
      emoji: '🕢',
      name: 'seven-thirty',
      nameUrl: 'seven-thirty',
    },
    {
      id: 868,
      code: 'U+1F557',
      emoji: '🕗',
      name: 'eight o’clock',
      nameUrl: 'eight-oclock',
    },
    {
      id: 869,
      code: 'U+1F563',
      emoji: '🕣',
      name: 'eight-thirty',
      nameUrl: 'eight-thirty',
    },
    {
      id: 870,
      code: 'U+1F558',
      emoji: '🕘',
      name: 'nine o’clock',
      nameUrl: 'nine-oclock',
    },
    {
      id: 871,
      code: 'U+1F564',
      emoji: '🕤',
      name: 'nine-thirty',
      nameUrl: 'nine-thirty',
    },
    {
      id: 872,
      code: 'U+1F559',
      emoji: '🕙',
      name: 'ten o’clock',
      nameUrl: 'ten-oclock',
    },
    {
      id: 873,
      code: 'U+1F565',
      emoji: '🕥',
      name: 'ten-thirty',
      nameUrl: 'ten-thirty',
    },
    {
      id: 874,
      code: 'U+1F55A',
      emoji: '🕚',
      name: 'eleven o’clock',
      nameUrl: 'eleven-oclock',
    },
    {
      id: 875,
      code: 'U+1F566',
      emoji: '🕦',
      name: 'eleven-thirty',
      nameUrl: 'eleven-thirty',
    },
    {
      id: 876,
      code: 'U+1F311',
      emoji: '🌑',
      name: 'new moon',
      nameUrl: 'new-moon',
    },
    {
      id: 877,
      code: 'U+1F312',
      emoji: '🌒',
      name: 'waxing crescent moon',
      nameUrl: 'waxing-crescent-moon',
    },
    {
      id: 878,
      code: 'U+1F313',
      emoji: '🌓',
      name: 'first quarter moon',
      nameUrl: 'first-quarter-moon',
    },
    {
      id: 879,
      code: 'U+1F314',
      emoji: '🌔',
      name: 'waxing gibbous moon',
      nameUrl: 'waxing-gibbous-moon',
    },
    {
      id: 880,
      code: 'U+1F315',
      emoji: '🌕',
      name: 'full moon',
      nameUrl: 'full-moon',
    },
    {
      id: 881,
      code: 'U+1F316',
      emoji: '🌖',
      name: 'waning gibbous moon',
      nameUrl: 'waning-gibbous-moon',
    },
    {
      id: 882,
      code: 'U+1F317',
      emoji: '🌗',
      name: 'last quarter moon',
      nameUrl: 'last-quarter-moon',
    },
    {
      id: 883,
      code: 'U+1F318',
      emoji: '🌘',
      name: 'waning crescent moon',
      nameUrl: 'waning-crescent-moon',
    },
    {
      id: 884,
      code: 'U+1F319',
      emoji: '🌙',
      name: 'crescent moon',
      nameUrl: 'crescent-moon',
    },
    {
      id: 885,
      code: 'U+1F31A',
      emoji: '🌚',
      name: 'new moon face',
      nameUrl: 'new-moon-face',
    },
    {
      id: 886,
      code: 'U+1F31B',
      emoji: '🌛',
      name: 'first quarter moon face',
      nameUrl: 'first-quarter-moon-face',
    },
    {
      id: 887,
      code: 'U+1F31C',
      emoji: '🌜',
      name: 'last quarter moon face',
      nameUrl: 'last-quarter-moon-face',
    },
    {
      id: 888,
      code: 'U+1F321',
      emoji: '🌡',
      name: 'thermometer',
      nameUrl: 'thermometer',
    },
    {
      id: 889,
      code: 'U+2600',
      emoji: '☀️',
      name: 'sun',
      nameUrl: 'sun',
    },
    {
      id: 890,
      code: 'U+1F31D',
      emoji: '🌝',
      name: 'full moon face',
      nameUrl: 'full-moon-face',
    },
    {
      id: 891,
      code: 'U+1F31E',
      emoji: '🌞',
      name: 'sun with face',
      nameUrl: 'sun-with-face',
    },
    {
      id: 893,
      code: 'U+2B50',
      emoji: '⭐',
      name: 'star',
      nameUrl: 'star',
    },
    {
      id: 894,
      code: 'U+1F31F',
      emoji: '🌟',
      name: 'glowing star',
      nameUrl: 'glowing-star',
    },
    {
      id: 895,
      code: 'U+1F320',
      emoji: '🌠',
      name: 'shooting star',
      nameUrl: 'shooting-star',
    },
    {
      id: 896,
      code: 'U+1F30C',
      emoji: '🌌',
      name: 'milky way',
      nameUrl: 'milky-way',
    },
    {
      id: 897,
      code: 'U+2601',
      emoji: '☁️',
      name: 'cloud',
      nameUrl: 'cloud',
    },
    {
      id: 898,
      code: 'U+26C5',
      emoji: '⛅',
      name: 'sun behind cloud',
      nameUrl: 'sun-behind-cloud',
    },
    {
      id: 899,
      code: 'U+26C8',
      emoji: '⛈',
      name: 'cloud with lightning and rain',
      nameUrl: 'cloud-with-lightning-and-rain',
    },
    {
      id: 900,
      code: 'U+1F324',
      emoji: '🌤',
      name: 'sun behind small cloud',
      nameUrl: 'sun-behind-small-cloud',
    },
    {
      id: 901,
      code: 'U+1F325',
      emoji: '🌥',
      name: 'sun behind large cloud',
      nameUrl: 'sun-behind-large-cloud',
    },
    {
      id: 902,
      code: 'U+1F326',
      emoji: '🌦',
      name: 'sun behind rain cloud',
      nameUrl: 'sun-behind-rain-cloud',
    },
    {
      id: 903,
      code: 'U+1F327',
      emoji: '🌧',
      name: 'cloud with rain',
      nameUrl: 'cloud-with-rain',
    },
    {
      id: 904,
      code: 'U+1F328',
      emoji: '🌨',
      name: 'cloud with snow',
      nameUrl: 'cloud-with-snow',
    },
    {
      id: 905,
      code: 'U+1F329',
      emoji: '🌩',
      name: 'cloud with lightning',
      nameUrl: 'cloud-with-lightning',
    },
    {
      id: 906,
      code: 'U+1F32A',
      emoji: '🌪',
      name: 'tornado',
      nameUrl: 'tornado',
    },
    {
      id: 907,
      code: 'U+1F32B',
      emoji: '🌫',
      name: 'fog',
      nameUrl: 'fog',
    },
    {
      id: 908,
      code: 'U+1F32C',
      emoji: '🌬',
      name: 'wind face',
      nameUrl: 'wind-face',
    },
    {
      id: 909,
      code: 'U+1F300',
      emoji: '🌀',
      name: 'cyclone',
      nameUrl: 'cyclone',
    },
    {
      id: 910,
      code: 'U+1F308',
      emoji: '🌈',
      name: 'rainbow',
      nameUrl: 'rainbow',
    },
    {
      id: 911,
      code: 'U+1F302',
      emoji: '🌂',
      name: 'closed umbrella',
      nameUrl: 'closed-umbrella',
    },
    {
      id: 912,
      code: 'U+2602',
      emoji: '☂️',
      name: 'umbrella',
      nameUrl: 'umbrella',
    },
    {
      id: 913,
      code: 'U+2614',
      emoji: '☔',
      name: 'umbrella with rain drops',
      nameUrl: 'umbrella-with-rain-drops',
    },
    {
      id: 914,
      code: 'U+26F1',
      emoji: '⛱',
      name: 'umbrella on ground',
      nameUrl: 'umbrella-on-ground',
    },
    {
      id: 915,
      code: 'U+26A1',
      emoji: '⚡',
      name: 'high voltage',
      nameUrl: 'high-voltage',
    },
    {
      id: 916,
      code: 'U+2744',
      emoji: '❄️',
      name: 'snowflake',
      nameUrl: 'snowflake',
    },
    {
      id: 917,
      code: 'U+2603',
      emoji: '☃️',
      name: 'snowman',
      nameUrl: 'snowman',
    },
    {
      id: 918,
      code: 'U+26C4',
      emoji: '⛄',
      name: 'snowman without snow',
      nameUrl: 'snowman-without-snow',
    },
    {
      id: 919,
      code: 'U+2604',
      emoji: '☄',
      name: 'comet',
      nameUrl: 'comet',
    },
    {
      id: 920,
      code: 'U+1F525',
      emoji: '🔥',
      name: 'fire',
      nameUrl: 'fire',
    },
    {
      id: 921,
      code: 'U+1F4A7',
      emoji: '💧',
      name: 'droplet',
      nameUrl: 'droplet',
    },
    {
      id: 922,
      code: 'U+1F30A',
      emoji: '🌊',
      name: 'water wave',
      nameUrl: 'water-wave',
      },
    {
      id: 923,
      code: 'U+1F383',
      emoji: '🎃',
      name: 'jack-o-lantern',
      nameUrl: 'jack-o-lantern',
    },
    {
      id: 924,
      code: 'U+1F384',
      emoji: '🎄',
      name: 'Christmas tree',
      nameUrl: 'christmas-tree',
    },
    {
      id: 925,
      code: 'U+1F386',
      emoji: '🎆',
      name: 'fireworks',
      nameUrl: 'fireworks',
    },
    {
      id: 926,
      code: 'U+1F387',
      emoji: '🎇',
      name: 'sparkler',
      nameUrl: 'sparkler',
    },
    {
      id: 927,
      code: 'U+1F9E8',
      emoji: '🧨',
      name: 'firecracker',
      nameUrl: 'firecracker',
    },
    {
      id: 928,
      code: 'U+2728',
      emoji: '✨',
      name: 'sparkles',
      nameUrl: 'sparkles',
    },
    {
      id: 929,
      code: 'U+1F388',
      emoji: '🎈',
      name: 'balloon',
      nameUrl: 'balloon',
    },
    {
      id: 930,
      code: 'U+1F389',
      emoji: '🎉',
      name: 'party popper',
      nameUrl: 'party-popper',
    },
    {
      id: 931,
      code: 'U+1F38A',
      emoji: '🎊',
      name: 'confetti ball',
      nameUrl: 'confetti-ball',
    },
    {
      id: 932,
      code: 'U+1F38B',
      emoji: '🎋',
      name: 'tanabata tree',
      nameUrl: 'tanabata-tree',
    },
    {
      id: 933,
      code: 'U+1F38D',
      emoji: '🎍',
      name: 'pine decoration',
      nameUrl: 'pine-decoration',
    },
    {
      id: 934,
      code: 'U+1F38E',
      emoji: '🎎',
      name: 'Japanese dolls',
      nameUrl: 'japanese-dolls',
    },
    {
      id: 935,
      code: 'U+1F38F',
      emoji: '🎏',
      name: 'carp streamer',
      nameUrl: 'carp-streamer',
    },
    {
      id: 936,
      code: 'U+1F390',
      emoji: '🎐',
      name: 'wind chime',
      nameUrl: 'wind-chime',
    },
    {
      id: 937,
      code: 'U+1F391',
      emoji: '🎑',
      name: 'moon viewing ceremony',
      nameUrl: 'moon-viewing-ceremony',
    },
    {
      id: 938,
      code: 'U+1F9E7',
      emoji: '🧧',
      name: 'red envelope',
      nameUrl: 'red-envelope',
    },
    {
      id: 939,
      code: 'U+1F380',
      emoji: '🎀',
      name: 'ribbon',
      nameUrl: 'ribbon',
    },
    {
      id: 940,
      code: 'U+1F381',
      emoji: '🎁',
      name: 'wrapped gift',
      nameUrl: 'wrapped-gift',
    },
    {
      id: 941,
      code: 'U+1F397',
      emoji: '🎗',
      name: 'reminder ribbon',
      nameUrl: 'reminder-ribbon',
    },
    {
      id: 942,
      code: 'U+1F39F',
      emoji: '🎟',
      name: 'admission tickets',
      nameUrl: 'admission-tickets',
    },
    {
      id: 943,
      code: 'U+1F3AB',
      emoji: '🎫',
      name: 'ticket',
      nameUrl: 'ticket',
    },
    {
      id: 944,
      code: 'U+1F396',
      emoji: '🎖',
      name: 'military medal',
      nameUrl: 'military-medal',
    },
    {
      id: 945,
      code: 'U+1F3C6',
      emoji: '🏆',
      name: 'trophy',
      nameUrl: 'trophy',
    },
    {
      id: 946,
      code: 'U+1F3C5',
      emoji: '🏅',
      name: 'sports medal',
      nameUrl: 'sports-medal',
    },
    {
      id: 947,
      code: 'U+1F947',
      emoji: '🥇',
      name: '1st place medal',
      nameUrl: '1st-place-medal',
    },
    {
      id: 948,
      code: 'U+1F948',
      emoji: '🥈',
      name: '2nd place medal',
      nameUrl: '2nd-place-medal',
    },
    {
      id: 949,
      code: 'U+1F949',
      emoji: '🥉',
      name: '3rd place medal',
      nameUrl: '3rd-place-medal',
    },
    {
      id: 950,
      code: 'U+26BD',
      emoji: '⚽',
      name: 'soccer ball',
      nameUrl: 'soccer-ball',
    },
    {
      id: 951,
      code: 'U+26BE',
      emoji: '⚾',
      name: 'baseball',
      nameUrl: 'baseball',
    },
    {
      id: 952,
      code: 'U+1F94E',
      emoji: '🥎',
      name: 'softball',
      nameUrl: 'softball',
    },
    {
      id: 953,
      code: 'U+1F3C0',
      emoji: '🏀',
      name: 'basketball',
      nameUrl: 'basketball',
    },
    {
      id: 954,
      code: 'U+1F3D0',
      emoji: '🏐',
      name: 'volleyball',
      nameUrl: 'volleyball',
    },
    {
      id: 955,
      code: 'U+1F3C8',
      emoji: '🏈',
      name: 'american football',
      nameUrl: 'american-football',
    },
    {
      id: 956,
      code: 'U+1F3C9',
      emoji: '🏉',
      name: 'rugby football',
      nameUrl: 'rugby-football',
    },
    {
      id: 957,
      code: 'U+1F3BE',
      emoji: '🎾',
      name: 'tennis',
      nameUrl: 'tennis',
    },
    {
      id: 958,
      code: 'U+1F94F',
      emoji: '🥏',
      name: 'flying disc',
      nameUrl: 'flying-disc',
    },
    {
      id: 959,
      code: 'U+1F3B3',
      emoji: '🎳',
      name: 'bowling',
      nameUrl: 'bowling',
    },
    {
      id: 960,
      code: 'U+1F3CF',
      emoji: '🏏',
      name: 'cricket game',
      nameUrl: 'cricket-game',
    },
    {
      id: 961,
      code: 'U+1F3D1',
      emoji: '🏑',
      name: 'field hockey',
      nameUrl: 'field-hockey',
    },
    {
      id: 962,
      code: 'U+1F3D2',
      emoji: '🏒',
      name: 'ice hockey',
      nameUrl: 'ice-hockey',
    },
    {
      id: 963,
      code: 'U+1F94D',
      emoji: '🥍',
      name: 'lacrosse',
      nameUrl: 'lacrosse',
    },
    {
      id: 964,
      code: 'U+1F3D3',
      emoji: '🏓',
      name: 'ping pong',
      nameUrl: 'ping-pong',
    },
    {
      id: 965,
      code: 'U+1F3F8',
      emoji: '🏸',
      name: 'badminton',
      nameUrl: 'badminton',
    },
    {
      id: 966,
      code: 'U+1F94A',
      emoji: '🥊',
      name: 'boxing glove',
      nameUrl: 'boxing-glove',
    },
    {
      id: 967,
      code: 'U+1F94B',
      emoji: '🥋',
      name: 'martial arts uniform',
      nameUrl: 'martial-arts-uniform',
    },
    {
      id: 968,
      code: 'U+1F945',
      emoji: '🥅',
      name: 'goal net',
      nameUrl: 'goal-net',
    },
    {
      id: 969,
      code: 'U+26F3',
      emoji: '⛳',
      name: 'flag in hole',
      nameUrl: 'flag-in-hole',
    },
    {
      id: 970,
      code: 'U+26F8',
      emoji: '⛸',
      name: 'ice skate',
      nameUrl: 'ice-skate',
    },
    {
      id: 971,
      code: 'U+1F3A3',
      emoji: '🎣',
      name: 'fishing pole',
      nameUrl: 'fishing-pole',
    },
    {
      id: 973,
      code: 'U+1F3BD',
      emoji: '🎽',
      name: 'running shirt',
      nameUrl: 'running-shirt',
    },
    {
      id: 974,
      code: 'U+1F3BF',
      emoji: '🎿',
      name: 'skis',
      nameUrl: 'skis',
    },
    {
      id: 975,
      code: 'U+1F6F7',
      emoji: '🛷',
      name: 'sled',
      nameUrl: 'sled',
    },
    {
      id: 976,
      code: 'U+1F94C',
      emoji: '🥌',
      name: 'curling stone',
      nameUrl: 'curling-stone',
    },
    {
      id: 977,
      code: 'U+1F3AF',
      emoji: '🎯',
      name: 'direct hit',
      nameUrl: 'direct-hit',
    },
    {
      id: 980,
      code: 'U+1F3B1',
      emoji: '🎱',
      name: 'pool 8 ball',
      nameUrl: 'pool-8-ball',
    },
    {
      id: 981,
      code: 'U+1F52E',
      emoji: '🔮',
      name: 'crystal ball',
      nameUrl: 'crystal-ball',
    },
    {
      id: 982,
      code: 'U+1F9FF',
      emoji: '🧿',
      name: 'nazar amulet',
      nameUrl: 'nazar-amulet',
    },
    {
      id: 983,
      code: 'U+1F3AE',
      emoji: '🎮',
      name: 'video game',
      nameUrl: 'video-game',
    },
    {
      id: 984,
      code: 'U+1F579',
      emoji: '🕹',
      name: 'joystick',
      nameUrl: 'joystick',
    },
    {
      id: 985,
      code: 'U+1F3B0',
      emoji: '🎰',
      name: 'slot machine',
      nameUrl: 'slot-machine',
    },
    {
      id: 986,
      code: 'U+1F3B2',
      emoji: '🎲',
      name: 'game die',
      nameUrl: 'game-die',
    },
    {
      id: 987,
      code: 'U+1F9E9',
      emoji: '🧩',
      name: 'puzzle piece',
      nameUrl: 'puzzle-piece',
    },
    {
      id: 988,
      code: 'U+1F9F8',
      emoji: '🧸',
      name: 'teddy bear',
      nameUrl: 'teddy-bear',
    },
    {
      id: 989,
      code: 'U+2660',
      emoji: '♠',
      name: 'spade suit',
      nameUrl: 'spade-suit',
    },
    {
      id: 990,
      code: 'U+2665',
      emoji: '♥',
      name: 'heart suit',
      nameUrl: 'heart-suit',
    },
    {
      id: 991,
      code: 'U+2666',
      emoji: '♦️',
      name: 'diamond suit',
      nameUrl: 'diamond-suit',
    },
    {
      id: 992,
      code: 'U+2663',
      emoji: '♣️',
      name: 'club suit',
      nameUrl: 'club-suit',
    },
    {
      id: 993,
      code: 'U+265F',
      emoji: '♟',
      name: 'chess pawn',
      nameUrl: 'chess-pawn',
    },
    {
      id: 994,
      code: 'U+1F0CF',
      emoji: '🃏',
      name: 'joker',
      nameUrl: 'joker',
    },
    {
      id: 995,
      code: 'U+1F004',
      emoji: '🀄',
      name: 'mahjong red dragon',
      nameUrl: 'mahjong-red-dragon',
    },
    {
      id: 996,
      code: 'U+1F3B4',
      emoji: '🎴',
      name: 'flower playing cards',
      nameUrl: 'flower-playing-cards',
    },
    {
      id: 997,
      code: 'U+1F3AD',
      emoji: '🎭',
      name: 'performing arts',
      nameUrl: 'performing-arts',
    },
    {
      id: 998,
      code: 'U+1F5BC',
      emoji: '🖼',
      name: 'framed picture',
      nameUrl: 'framed-picture',
    },
    {
      id: 999,
      code: 'U+1F3A8',
      emoji: '🎨',
      name: 'artist palette',
      nameUrl: 'artist-palette',
    },
    {
      id: 1000,
      code: 'U+1F9F5',
      emoji: '🧵',
      name: 'thread',
      nameUrl: 'thread',
    },
    {
      id: 1001,
      code: 'U+1F9F6',
      emoji: '🧶',
      name: 'yarn',
      nameUrl: 'yarn',
      },
    {
      id: 1002,
      code: 'U+1F453',
      emoji: '👓',
      name: 'glasses',
      nameUrl: 'glasses',
    },
    {
      id: 1003,
      code: 'U+1F576',
      emoji: '🕶',
      name: 'sunglasses',
      nameUrl: 'sunglasses',
    },
    {
      id: 1004,
      code: 'U+1F97D',
      emoji: '🥽',
      name: 'goggles',
      nameUrl: 'goggles',
    },
    {
      id: 1005,
      code: 'U+1F97C',
      emoji: '🥼',
      name: 'lab coat',
      nameUrl: 'lab-coat',
    },
    {
      id: 1007,
      code: 'U+1F454',
      emoji: '👔',
      name: 'necktie',
      nameUrl: 'necktie',
    },
    {
      id: 1008,
      code: 'U+1F455',
      emoji: '👕',
      name: 't-shirt',
      nameUrl: 't-shirt',
    },
    {
      id: 1009,
      code: 'U+1F456',
      emoji: '👖',
      name: 'jeans',
      nameUrl: 'jeans',
    },
    {
      id: 1010,
      code: 'U+1F9E3',
      emoji: '🧣',
      name: 'scarf',
      nameUrl: 'scarf',
    },
    {
      id: 1011,
      code: 'U+1F9E4',
      emoji: '🧤',
      name: 'gloves',
      nameUrl: 'gloves',
    },
    {
      id: 1012,
      code: 'U+1F9E5',
      emoji: '🧥',
      name: 'coat',
      nameUrl: 'coat',
    },
    {
      id: 1013,
      code: 'U+1F9E6',
      emoji: '🧦',
      name: 'socks',
      nameUrl: 'socks',
    },
    {
      id: 1014,
      code: 'U+1F457',
      emoji: '👗',
      name: 'dress',
      nameUrl: 'dress',
    },
    {
      id: 1015,
      code: 'U+1F458',
      emoji: '👘',
      name: 'kimono',
      nameUrl: 'kimono',
    },
    {
      id: 1020,
      code: 'U+1F459',
      emoji: '👙',
      name: 'bikini',
      nameUrl: 'bikini',
    },
    {
      id: 1021,
      code: 'U+1F45A',
      emoji: '👚',
      name: 'woman’s clothes',
      nameUrl: 'womans-clothes',
    },
    {
      id: 1022,
      code: 'U+1F45B',
      emoji: '👛',
      name: 'purse',
      nameUrl: 'purse',
    },
    {
      id: 1023,
      code: 'U+1F45C',
      emoji: '👜',
      name: 'handbag',
      nameUrl: 'handbag',
    },
    {
      id: 1024,
      code: 'U+1F45D',
      emoji: '👝',
      name: 'clutch bag',
      nameUrl: 'clutch-bag',
    },
    {
      id: 1025,
      code: 'U+1F6CD',
      emoji: '🛍',
      name: 'shopping bags',
      nameUrl: 'shopping-bags',
    },
    {
      id: 1026,
      code: 'U+1F392',
      emoji: '🎒',
      name: 'backpack',
      nameUrl: 'backpack',
    },
    {
      id: 1027,
      code: 'U+1F45E',
      emoji: '👞',
      name: 'man’s shoe',
      nameUrl: 'mans-shoe',
    },
    {
      id: 1028,
      code: 'U+1F45F',
      emoji: '👟',
      name: 'running shoe',
      nameUrl: 'running-shoe',
    },
    {
      id: 1029,
      code: 'U+1F97E',
      emoji: '🥾',
      name: 'hiking boot',
      nameUrl: 'hiking-boot',
    },
    {
      id: 1030,
      code: 'U+1F97F',
      emoji: '🥿',
      name: 'flat shoe',
      nameUrl: 'flat-shoe',
    },
    {
      id: 1031,
      code: 'U+1F460',
      emoji: '👠',
      name: 'high-heeled shoe',
      nameUrl: 'high-heeled-shoe',
    },
    {
      id: 1032,
      code: 'U+1F461',
      emoji: '👡',
      name: 'woman’s sandal',
      nameUrl: 'womans-sandal',
    },
    {
      id: 1034,
      code: 'U+1F462',
      emoji: '👢',
      name: 'woman’s boot',
      nameUrl: 'womans-boot',
    },
    {
      id: 1035,
      code: 'U+1F451',
      emoji: '👑',
      name: 'crown',
      nameUrl: 'crown',
    },
    {
      id: 1036,
      code: 'U+1F452',
      emoji: '👒',
      name: 'woman’s hat',
      nameUrl: 'womans-hat',
    },
    {
      id: 1037,
      code: 'U+1F3A9',
      emoji: '🎩',
      name: 'top hat',
      nameUrl: 'top-hat',
    },
    {
      id: 1038,
      code: 'U+1F393',
      emoji: '🎓',
      name: 'graduation cap',
      nameUrl: 'graduation-cap',
    },
    {
      id: 1039,
      code: 'U+1F9E2',
      emoji: '🧢',
      name: 'billed cap',
      nameUrl: 'billed-cap',
    },
    {
      id: 1040,
      code: 'U+26D1',
      emoji: '⛑',
      name: 'rescue worker’s helmet',
      nameUrl: 'rescue-workers-helmet',
    },
    {
      id: 1041,
      code: 'U+1F4FF',
      emoji: '📿',
      name: 'prayer beads',
      nameUrl: 'prayer-beads',
    },
    {
      id: 1042,
      code: 'U+1F484',
      emoji: '💄',
      name: 'lipstick',
      nameUrl: 'lipstick',
    },
    {
      id: 1043,
      code: 'U+1F48D',
      emoji: '💍',
      name: 'ring',
      nameUrl: 'ring',
    },
    {
      id: 1044,
      code: 'U+1F48E',
      emoji: '💎',
      name: 'gem stone',
      nameUrl: 'gem-stone',
    },
    {
      id: 1045,
      code: 'U+1F507',
      emoji: '🔇',
      name: 'muted speaker',
      nameUrl: 'muted-speaker',
    },
    {
      id: 1046,
      code: 'U+1F508',
      emoji: '🔈',
      name: 'speaker low volume',
      nameUrl: 'speaker-low-volume',
    },
    {
      id: 1047,
      code: 'U+1F509',
      emoji: '🔉',
      name: 'speaker medium volume',
      nameUrl: 'speaker-medium-volume',
    },
    {
      id: 1048,
      code: 'U+1F50A',
      emoji: '🔊',
      name: 'speaker high volume',
      nameUrl: 'speaker-high-volume',
    },
    {
      id: 1049,
      code: 'U+1F4E2',
      emoji: '📢',
      name: 'loudspeaker',
      nameUrl: 'loudspeaker',
    },
    {
      id: 1050,
      code: 'U+1F4E3',
      emoji: '📣',
      name: 'megaphone',
      nameUrl: 'megaphone',
    },
    {
      id: 1051,
      code: 'U+1F4EF',
      emoji: '📯',
      name: 'postal horn',
      nameUrl: 'postal-horn',
    },
    {
      id: 1052,
      code: 'U+1F514',
      emoji: '🔔',
      name: 'bell',
      nameUrl: 'bell',
    },
    {
      id: 1053,
      code: 'U+1F515',
      emoji: '🔕',
      name: 'bell with slash',
      nameUrl: 'bell-with-slash',
    },
    {
      id: 1054,
      code: 'U+1F3BC',
      emoji: '🎼',
      name: 'musical score',
      nameUrl: 'musical-score',
    },
    {
      id: 1055,
      code: 'U+1F3B5',
      emoji: '🎵',
      name: 'musical note',
      nameUrl: 'musical-note',
    },
    {
      id: 1056,
      code: 'U+1F3B6',
      emoji: '🎶',
      name: 'musical notes',
      nameUrl: 'musical-notes',
    },
    {
      id: 1057,
      code: 'U+1F399',
      emoji: '🎙',
      name: 'studio microphone',
      nameUrl: 'studio-microphone',
    },
    {
      id: 1058,
      code: 'U+1F39A',
      emoji: '🎚',
      name: 'level slider',
      nameUrl: 'level-slider',
    },
    {
      id: 1059,
      code: 'U+1F39B',
      emoji: '🎛',
      name: 'control knobs',
      nameUrl: 'control-knobs',
    },
    {
      id: 1060,
      code: 'U+1F3A4',
      emoji: '🎤',
      name: 'microphone',
      nameUrl: 'microphone',
    },
    {
      id: 1061,
      code: 'U+1F3A7',
      emoji: '🎧',
      name: 'headphone',
      nameUrl: 'headphone',
    },
    {
      id: 1062,
      code: 'U+1F4FB',
      emoji: '📻',
      name: 'radio',
      nameUrl: 'radio',
    },
    {
      id: 1063,
      code: 'U+1F3B7',
      emoji: '🎷',
      name: 'saxophone',
      nameUrl: 'saxophone',
    },
    {
      id: 1064,
      code: 'U+1F3B8',
      emoji: '🎸',
      name: 'guitar',
      nameUrl: 'guitar',
    },
    {
      id: 1065,
      code: 'U+1F3B9',
      emoji: '🎹',
      name: 'musical keyboard',
      nameUrl: 'musical-keyboard',
    },
    {
      id: 1066,
      code: 'U+1F3BA',
      emoji: '🎺',
      name: 'trumpet',
      nameUrl: 'trumpet',
    },
    {
      id: 1067,
      code: 'U+1F3BB',
      emoji: '🎻',
      name: 'violin',
      nameUrl: 'violin',
    },
    {
      id: 1069,
      code: 'U+1F941',
      emoji: '🥁',
      name: 'drum',
      nameUrl: 'drum',
    },
    {
      id: 1070,
      code: 'U+1F4F1',
      emoji: '📱',
      name: 'mobile phone',
      nameUrl: 'mobile-phone',
    },
    {
      id: 1071,
      code: 'U+1F4F2',
      emoji: '📲',
      name: 'mobile phone with arrow',
      nameUrl: 'mobile-phone-with-arrow',
    },
    {
      id: 1072,
      code: 'U+260E',
      emoji: '☎️',
      name: 'telephone',
      nameUrl: 'telephone',
    },
    {
      id: 1073,
      code: 'U+1F4DE',
      emoji: '📞',
      name: 'telephone receiver',
      nameUrl: 'telephone-receiver',
    },
    {
      id: 1074,
      code: 'U+1F4DF',
      emoji: '📟',
      name: 'pager',
      nameUrl: 'pager',
    },
    {
      id: 1075,
      code: 'U+1F4E0',
      emoji: '📠',
      name: 'fax machine',
      nameUrl: 'fax-machine',
    },
    {
      id: 1076,
      code: 'U+1F50B',
      emoji: '🔋',
      name: 'battery',
      nameUrl: 'battery',
    },
    {
      id: 1077,
      code: 'U+1F50C',
      emoji: '🔌',
      name: 'electric plug',
      nameUrl: 'electric-plug',
    },
    {
      id: 1078,
      code: 'U+1F4BB',
      emoji: '💻',
      name: 'laptop computer',
      nameUrl: 'laptop-computer',
    },
    {
      id: 1079,
      code: 'U+1F5A5',
      emoji: '🖥',
      name: 'desktop computer',
      nameUrl: 'desktop-computer',
    },
    {
      id: 1080,
      code: 'U+1F5A8',
      emoji: '🖨',
      name: 'printer',
      nameUrl: 'printer',
    },
    {
      id: 1081,
      code: 'U+2328',
      emoji: '⌨',
      name: 'keyboard',
      nameUrl: 'keyboard',
    },
    {
      id: 1082,
      code: 'U+1F5B1',
      emoji: '🖱',
      name: 'computer mouse',
      nameUrl: 'computer-mouse',
    },
    {
      id: 1083,
      code: 'U+1F5B2',
      emoji: '🖲',
      name: 'trackball',
      nameUrl: 'trackball',
    },
    {
      id: 1084,
      code: 'U+1F4BD',
      emoji: '💽',
      name: 'computer disk',
      nameUrl: 'computer-disk',
    },
    {
      id: 1085,
      code: 'U+1F4BE',
      emoji: '💾',
      name: 'floppy disk',
      nameUrl: 'floppy-disk',
    },
    {
      id: 1086,
      code: 'U+1F4BF',
      emoji: '💿',
      name: 'optical disk',
      nameUrl: 'optical-disk',
    },
    {
      id: 1087,
      code: 'U+1F4C0',
      emoji: '📀',
      name: 'dvd',
      nameUrl: 'dvd',
    },
    {
      id: 1088,
      code: 'U+1F9EE',
      emoji: '🧮',
      name: 'abacus',
      nameUrl: 'abacus',
    },
    {
      id: 1089,
      code: 'U+1F3A5',
      emoji: '🎥',
      name: 'movie camera',
      nameUrl: 'movie-camera',
    },
    {
      id: 1090,
      code: 'U+1F39E',
      emoji: '🎞',
      name: 'film frames',
      nameUrl: 'film-frames',
    },
    {
      id: 1091,
      code: 'U+1F4FD',
      emoji: '📽',
      name: 'film projector',
      nameUrl: 'film-projector',
    },
    {
      id: 1092,
      code: 'U+1F3AC',
      emoji: '🎬',
      name: 'clapper board',
      nameUrl: 'clapper-board',
    },
    {
      id: 1093,
      code: 'U+1F4FA',
      emoji: '📺',
      name: 'television',
      nameUrl: 'television',
    },
    {
      id: 1094,
      code: 'U+1F4F7',
      emoji: '📷',
      name: 'camera',
      nameUrl: 'camera',
    },
    {
      id: 1095,
      code: 'U+1F4F8',
      emoji: '📸',
      name: 'camera with flash',
      nameUrl: 'camera-with-flash',
    },
    {
      id: 1096,
      code: 'U+1F4F9',
      emoji: '📹',
      name: 'video camera',
      nameUrl: 'video-camera',
    },
    {
      id: 1097,
      code: 'U+1F4FC',
      emoji: '📼',
      name: 'videocassette',
      nameUrl: 'videocassette',
    },
    {
      id: 1098,
      code: 'U+1F50D',
      emoji: '🔍',
      name: 'magnifying glass tilted left',
      nameUrl: 'magnifying-glass-tilted-left',
    },
    {
      id: 1099,
      code: 'U+1F50E',
      emoji: '🔎',
      name: 'magnifying glass tilted right',
      nameUrl: 'magnifying-glass-tilted-right',
    },
    {
      id: 1100,
      code: 'U+1F56F',
      emoji: '🕯',
      name: 'candle',
      nameUrl: 'candle',
    },
    {
      id: 1101,
      code: 'U+1F4A1',
      emoji: '💡',
      name: 'light bulb',
      nameUrl: 'light-bulb',
    },
    {
      id: 1102,
      code: 'U+1F526',
      emoji: '🔦',
      name: 'flashlight',
      nameUrl: 'flashlight',
    },
    {
      id: 1103,
      code: 'U+1F3EE',
      emoji: '🏮',
      name: 'red paper lantern',
      nameUrl: 'red-paper-lantern',
    },
    {
      id: 1105,
      code: 'U+1F4D4',
      emoji: '📔',
      name: 'notebook with decorative cover',
      nameUrl: 'notebook-with-decorative-cover',
    },
    {
      id: 1106,
      code: 'U+1F4D5',
      emoji: '📕',
      name: 'closed book',
      nameUrl: 'closed-book',
    },
    {
      id: 1107,
      code: 'U+1F4D6',
      emoji: '📖',
      name: 'open book',
      nameUrl: 'open-book',
    },
    {
      id: 1108,
      code: 'U+1F4D7',
      emoji: '📗',
      name: 'green book',
      nameUrl: 'green-book',
    },
    {
      id: 1109,
      code: 'U+1F4D8',
      emoji: '📘',
      name: 'blue book',
      nameUrl: 'blue-book',
    },
    {
      id: 1110,
      code: 'U+1F4D9',
      emoji: '📙',
      name: 'orange book',
      nameUrl: 'orange-book',
    },
    {
      id: 1111,
      code: 'U+1F4DA',
      emoji: '📚',
      name: 'books',
      nameUrl: 'books',
    },
    {
      id: 1112,
      code: 'U+1F4D3',
      emoji: '📓',
      name: 'notebook',
      nameUrl: 'notebook',
    },
    {
      id: 1113,
      code: 'U+1F4D2',
      emoji: '📒',
      name: 'ledger',
      nameUrl: 'ledger',
    },
    {
      id: 1114,
      code: 'U+1F4C3',
      emoji: '📃',
      name: 'page with curl',
      nameUrl: 'page-with-curl',
    },
    {
      id: 1115,
      code: 'U+1F4DC',
      emoji: '📜',
      name: 'scroll',
      nameUrl: 'scroll',
    },
    {
      id: 1116,
      code: 'U+1F4C4',
      emoji: '📄',
      name: 'page facing up',
      nameUrl: 'page-facing-up',
    },
    {
      id: 1117,
      code: 'U+1F4F0',
      emoji: '📰',
      name: 'newspaper',
      nameUrl: 'newspaper',
    },
    {
      id: 1118,
      code: 'U+1F5DE',
      emoji: '🗞',
      name: 'rolled-up newspaper',
      nameUrl: 'rolled-up-newspaper',
    },
    {
      id: 1119,
      code: 'U+1F4D1',
      emoji: '📑',
      name: 'bookmark tabs',
      nameUrl: 'bookmark-tabs',
    },
    {
      id: 1120,
      code: 'U+1F516',
      emoji: '🔖',
      name: 'bookmark',
      nameUrl: 'bookmark',
    },
    {
      id: 1121,
      code: 'U+1F3F7',
      emoji: '🏷',
      name: 'label',
      nameUrl: 'label',
    },
    {
      id: 1122,
      code: 'U+1F4B0',
      emoji: '💰',
      name: 'money bag',
      nameUrl: 'money-bag',
    },
    {
      id: 1123,
      code: 'U+1F4B4',
      emoji: '💴',
      name: 'yen banknote',
      nameUrl: 'yen-banknote',
    },
    {
      id: 1124,
      code: 'U+1F4B5',
      emoji: '💵',
      name: 'dollar banknote',
      nameUrl: 'dollar-banknote',
    },
    {
      id: 1125,
      code: 'U+1F4B6',
      emoji: '💶',
      name: 'euro banknote',
      nameUrl: 'euro-banknote',
    },
    {
      id: 1126,
      code: 'U+1F4B7',
      emoji: '💷',
      name: 'pound banknote',
      nameUrl: 'pound-banknote',
    },
    {
      id: 1127,
      code: 'U+1F4B8',
      emoji: '💸',
      name: 'money with wings',
      nameUrl: 'money-with-wings',
    },
    {
      id: 1128,
      code: 'U+1F4B3',
      emoji: '💳',
      name: 'credit card',
      nameUrl: 'credit-card',
    },
    {
      id: 1129,
      code: 'U+1F9FE',
      emoji: '🧾',
      name: 'receipt',
      nameUrl: 'receipt',
    },
    {
      id: 1130,
      code: 'U+1F4B9',
      emoji: '💹',
      name: 'chart increasing with yen',
      nameUrl: 'chart-increasing-with-yen',
    },
    {
      id: 1131,
      code: 'U+1F4B1',
      emoji: '💱',
      name: 'currency exchange',
      nameUrl: 'currency-exchange',
    },
    {
      id: 1132,
      code: 'U+1F4B2',
      emoji: '💲',
      name: 'heavy dollar sign',
      nameUrl: 'heavy-dollar-sign',
    },
    {
      id: 1133,
      code: 'U+2709',
      emoji: '✉️',
      name: 'envelope',
      nameUrl: 'envelope',
    },
    {
      id: 1134,
      code: 'U+1F4E7',
      emoji: '📧',
      name: 'e-mail',
      nameUrl: 'e-mail',
    },
    {
      id: 1135,
      code: 'U+1F4E8',
      emoji: '📨',
      name: 'incoming envelope',
      nameUrl: 'incoming-envelope',
    },
    {
      id: 1136,
      code: 'U+1F4E9',
      emoji: '📩',
      name: 'envelope with arrow',
      nameUrl: 'envelope-with-arrow',
    },
    {
      id: 1137,
      code: 'U+1F4E4',
      emoji: '📤',
      name: 'outbox tray',
      nameUrl: 'outbox-tray',
    },
    {
      id: 1138,
      code: 'U+1F4E5',
      emoji: '📥',
      name: 'inbox tray',
      nameUrl: 'inbox-tray',
    },
    {
      id: 1139,
      code: 'U+1F4E6',
      emoji: '📦',
      name: 'package',
      nameUrl: 'package',
    },
    {
      id: 1140,
      code: 'U+1F4EB',
      emoji: '📫',
      name: 'closed mailbox with raised flag',
      nameUrl: 'closed-mailbox-with-raised-flag',
    },
    {
      id: 1141,
      code: 'U+1F4EA',
      emoji: '📪',
      name: 'closed mailbox with lowered flag',
      nameUrl: 'closed-mailbox-with-lowered-flag',
    },
    {
      id: 1142,
      code: 'U+1F4EC',
      emoji: '📬',
      name: 'open mailbox with raised flag',
      nameUrl: 'open-mailbox-with-raised-flag',
    },
    {
      id: 1143,
      code: 'U+1F4ED',
      emoji: '📭',
      name: 'open mailbox with lowered flag',
      nameUrl: 'open-mailbox-with-lowered-flag',
    },
    {
      id: 1144,
      code: 'U+1F4EE',
      emoji: '📮',
      name: 'postbox',
      nameUrl: 'postbox',
    },
    {
      id: 1145,
      code: 'U+1F5F3',
      emoji: '🗳',
      name: 'ballot box with ballot',
      nameUrl: 'ballot-box-with-ballot',
    },
    {
      id: 1146,
      code: 'U+270F',
      emoji: '✏️',
      name: 'pencil',
      nameUrl: 'pencil',
    },
    {
      id: 1147,
      code: 'U+2712',
      emoji: '✒️',
      name: 'black nib',
      nameUrl: 'black-nib',
    },
    {
      id: 1148,
      code: 'U+1F58B',
      emoji: '🖋',
      name: 'fountain pen',
      nameUrl: 'fountain-pen',
    },
    {
      id: 1149,
      code: 'U+1F58A',
      emoji: '🖊',
      name: 'pen',
      nameUrl: 'pen',
    },
    {
      id: 1150,
      code: 'U+1F58C',
      emoji: '🖌',
      name: 'paintbrush',
      nameUrl: 'paintbrush',
    },
    {
      id: 1151,
      code: 'U+1F58D',
      emoji: '🖍',
      name: 'crayon',
      nameUrl: 'crayon',
    },
    {
      id: 1152,
      code: 'U+1F4DD',
      emoji: '📝',
      name: 'memo',
      nameUrl: 'memo',
    },
    {
      id: 1153,
      code: 'U+1F4BC',
      emoji: '💼',
      name: 'briefcase',
      nameUrl: 'briefcase',
    },
    {
      id: 1154,
      code: 'U+1F4C1',
      emoji: '📁',
      name: 'file folder',
      nameUrl: 'file-folder',
    },
    {
      id: 1155,
      code: 'U+1F4C2',
      emoji: '📂',
      name: 'open file folder',
      nameUrl: 'open-file-folder',
    },
    {
      id: 1156,
      code: 'U+1F5C2',
      emoji: '🗂',
      name: 'card index dividers',
      nameUrl: 'card-index-dividers',
    },
    {
      id: 1157,
      code: 'U+1F4C5',
      emoji: '📅',
      name: 'calendar',
      nameUrl: 'calendar',
    },
    {
      id: 1158,
      code: 'U+1F4C6',
      emoji: '📆',
      name: 'tear-off calendar',
      nameUrl: 'tear-off-calendar',
    },
    {
      id: 1159,
      code: 'U+1F5D2',
      emoji: '🗒',
      name: 'spiral notepad',
      nameUrl: 'spiral-notepad',
    },
    {
      id: 1160,
      code: 'U+1F5D3',
      emoji: '🗓',
      name: 'spiral calendar',
      nameUrl: 'spiral-calendar',
    },
    {
      id: 1161,
      code: 'U+1F4C7',
      emoji: '📇',
      name: 'card index',
      nameUrl: 'card-index',
    },
    {
      id: 1162,
      code: 'U+1F4C8',
      emoji: '📈',
      name: 'chart increasing',
      nameUrl: 'chart-increasing',
    },
    {
      id: 1163,
      code: 'U+1F4C9',
      emoji: '📉',
      name: 'chart decreasing',
      nameUrl: 'chart-decreasing',
    },
    {
      id: 1164,
      code: 'U+1F4CA',
      emoji: '📊',
      name: 'bar chart',
      nameUrl: 'bar-chart',
    },
    {
      id: 1165,
      code: 'U+1F4CB',
      emoji: '📋',
      name: 'clipboard',
      nameUrl: 'clipboard',
    },
    {
      id: 1166,
      code: 'U+1F4CC',
      emoji: '📌',
      name: 'pushpin',
      nameUrl: 'pushpin',
    },
    {
      id: 1167,
      code: 'U+1F4CD',
      emoji: '📍',
      name: 'round pushpin',
      nameUrl: 'round-pushpin',
    },
    {
      id: 1168,
      code: 'U+1F4CE',
      emoji: '📎',
      name: 'paperclip',
      nameUrl: 'paperclip',
    },
    {
      id: 1169,
      code: 'U+1F587',
      emoji: '🖇',
      name: 'linked paperclips',
      nameUrl: 'linked-paperclips',
    },
    {
      id: 1170,
      code: 'U+1F4CF',
      emoji: '📏',
      name: 'straight ruler',
      nameUrl: 'straight-ruler',
    },
    {
      id: 1171,
      code: 'U+1F4D0',
      emoji: '📐',
      name: 'triangular ruler',
      nameUrl: 'triangular-ruler',
    },
    {
      id: 1172,
      code: 'U+2702',
      emoji: '✂️',
      name: 'scissors',
      nameUrl: 'scissors',
    },
    {
      id: 1173,
      code: 'U+1F5C3',
      emoji: '🗃',
      name: 'card file box',
      nameUrl: 'card-file-box',
    },
    {
      id: 1174,
      code: 'U+1F5C4',
      emoji: '🗄',
      name: 'file cabinet',
      nameUrl: 'file-cabinet',
    },
    {
      id: 1175,
      code: 'U+1F5D1',
      emoji: '🗑',
      name: 'wastebasket',
      nameUrl: 'wastebasket',
    },
    {
      id: 1176,
      code: 'U+1F512',
      emoji: '🔒',
      name: 'locked',
      nameUrl: 'locked',
    },
    {
      id: 1177,
      code: 'U+1F513',
      emoji: '🔓',
      name: 'unlocked',
      nameUrl: 'unlocked',
    },
    {
      id: 1178,
      code: 'U+1F50F',
      emoji: '🔏',
      name: 'locked with pen',
      nameUrl: 'locked-with-pen',
    },
    {
      id: 1179,
      code: 'U+1F510',
      emoji: '🔐',
      name: 'locked with key',
      nameUrl: 'locked-with-key',
    },
    {
      id: 1180,
      code: 'U+1F511',
      emoji: '🔑',
      name: 'key',
      nameUrl: 'key',
    },
    {
      id: 1181,
      code: 'U+1F5DD',
      emoji: '🗝',
      name: 'old key',
      nameUrl: 'old-key',
    },
    {
      id: 1182,
      code: 'U+1F528',
      emoji: '🔨',
      name: 'hammer',
      nameUrl: 'hammer',
    },
    {
      id: 1184,
      code: 'U+26CF',
      emoji: '⛏',
      name: 'pick',
      nameUrl: 'pick',
    },
    {
      id: 1185,
      code: 'U+2692',
      emoji: '⚒',
      name: 'hammer and pick',
      nameUrl: 'hammer-and-pick',
    },
    {
      id: 1186,
      code: 'U+1F6E0',
      emoji: '🛠',
      name: 'hammer and wrench',
      nameUrl: 'hammer-and-wrench',
    },
    {
      id: 1187,
      code: 'U+1F5E1',
      emoji: '🗡',
      name: 'dagger',
      nameUrl: 'dagger',
    },
    {
      id: 1188,
      code: 'U+2694',
      emoji: '⚔',
      name: 'crossed swords',
      nameUrl: 'crossed-swords',
    },
    {
      id: 1189,
      code: 'U+1F52B',
      emoji: '🔫',
      name: 'pistol',
      nameUrl: 'pistol',
    },
    {
      id: 1190,
      code: 'U+1F3F9',
      emoji: '🏹',
      name: 'bow and arrow',
      nameUrl: 'bow-and-arrow',
    },
    {
      id: 1191,
      code: 'U+1F6E1',
      emoji: '🛡',
      name: 'shield',
      nameUrl: 'shield',
    },
    {
      id: 1192,
      code: 'U+1F527',
      emoji: '🔧',
      name: 'wrench',
      nameUrl: 'wrench',
    },
    {
      id: 1193,
      code: 'U+1F529',
      emoji: '🔩',
      name: 'nut and bolt',
      nameUrl: 'nut-and-bolt',
    },
    {
      id: 1194,
      code: 'U+2699',
      emoji: '⚙',
      name: 'gear',
      nameUrl: 'gear',
    },
    {
      id: 1195,
      code: 'U+1F5DC',
      emoji: '🗜',
      name: 'clamp',
      nameUrl: 'clamp',
    },
    {
      id: 1196,
      code: 'U+2696',
      emoji: '⚖',
      name: 'balance scale',
      nameUrl: 'balance-scale',
    },
    {
      id: 1198,
      code: 'U+1F517',
      emoji: '🔗',
      name: 'link',
      nameUrl: 'link',
    },
    {
      id: 1199,
      code: 'U+26D3',
      emoji: '⛓',
      name: 'chains',
      nameUrl: 'chains',
    },
    {
      id: 1200,
      code: 'U+1F9F0',
      emoji: '🧰',
      name: 'toolbox',
      nameUrl: 'toolbox',
    },
    {
      id: 1201,
      code: 'U+1F9F2',
      emoji: '🧲',
      name: 'magnet',
      nameUrl: 'magnet',
    },
    {
      id: 1202,
      code: 'U+2697',
      emoji: '⚗',
      name: 'alembic',
      nameUrl: 'alembic',
    },
    {
      id: 1203,
      code: 'U+1F9EA',
      emoji: '🧪',
      name: 'test tube',
      nameUrl: 'test-tube',
    },
    {
      id: 1204,
      code: 'U+1F9EB',
      emoji: '🧫',
      name: 'petri dish',
      nameUrl: 'petri-dish',
    },
    {
      id: 1205,
      code: 'U+1F9EC',
      emoji: '🧬',
      name: 'dna',
      nameUrl: 'dna',
    },
    {
      id: 1206,
      code: 'U+1F52C',
      emoji: '🔬',
      name: 'microscope',
      nameUrl: 'microscope',
    },
    {
      id: 1207,
      code: 'U+1F52D',
      emoji: '🔭',
      name: 'telescope',
      nameUrl: 'telescope',
    },
    {
      id: 1208,
      code: 'U+1F4E1',
      emoji: '📡',
      name: 'satellite antenna',
      nameUrl: 'satellite-antenna',
    },
    {
      id: 1209,
      code: 'U+1F489',
      emoji: '💉',
      name: 'syringe',
      nameUrl: 'syringe',
    },
    {
      id: 1211,
      code: 'U+1F48A',
      emoji: '💊',
      name: 'pill',
      nameUrl: 'pill',
    },
    {
      id: 1214,
      code: 'U+1F6AA',
      emoji: '🚪',
      name: 'door',
      nameUrl: 'door',
    },
    {
      id: 1215,
      code: 'U+1F6CF',
      emoji: '🛏',
      name: 'bed',
      nameUrl: 'bed',
    },
    {
      id: 1216,
      code: 'U+1F6CB',
      emoji: '🛋',
      name: 'couch and lamp',
      nameUrl: 'couch-and-lamp',
    },
    {
      id: 1218,
      code: 'U+1F6BD',
      emoji: '🚽',
      name: 'toilet',
      nameUrl: 'toilet',
    },
    {
      id: 1219,
      code: 'U+1F6BF',
      emoji: '🚿',
      name: 'shower',
      nameUrl: 'shower',
    },
    {
      id: 1220,
      code: 'U+1F6C1',
      emoji: '🛁',
      name: 'bathtub',
      nameUrl: 'bathtub',
    },
    {
      id: 1222,
      code: 'U+1F9F4',
      emoji: '🧴',
      name: 'lotion bottle',
      nameUrl: 'lotion-bottle',
    },
    {
      id: 1223,
      code: 'U+1F9F7',
      emoji: '🧷',
      name: 'safety pin',
      nameUrl: 'safety-pin',
    },
    {
      id: 1224,
      code: 'U+1F9F9',
      emoji: '🧹',
      name: 'broom',
      nameUrl: 'broom',
    },
    {
      id: 1225,
      code: 'U+1F9FA',
      emoji: '🧺',
      name: 'basket',
      nameUrl: 'basket',
    },
    {
      id: 1226,
      code: 'U+1F9FB',
      emoji: '🧻',
      name: 'roll of paper',
      nameUrl: 'roll-of-paper',
    },
    {
      id: 1227,
      code: 'U+1F9FC',
      emoji: '🧼',
      name: 'soap',
      nameUrl: 'soap',
    },
    {
      id: 1228,
      code: 'U+1F9FD',
      emoji: '🧽',
      name: 'sponge',
      nameUrl: 'sponge',
    },
    {
      id: 1229,
      code: 'U+1F9EF',
      emoji: '🧯',
      name: 'fire extinguisher',
      nameUrl: 'fire-extinguisher',
    },
    {
      id: 1230,
      code: 'U+1F6D2',
      emoji: '🛒',
      name: 'shopping cart',
      nameUrl: 'shopping-cart',
    },
    {
      id: 1231,
      code: 'U+1F6AC',
      emoji: '🚬',
      name: 'cigarette',
      nameUrl: 'cigarette',
    },
    {
      id: 1232,
      code: 'U+26B0',
      emoji: '⚰',
      name: 'coffin',
      nameUrl: 'coffin',
    },
    {
      id: 1233,
      code: 'U+26B1',
      emoji: '⚱',
      name: 'funeral urn',
      nameUrl: 'funeral-urn',
    },
    {
      id: 1234,
      code: 'U+1F5FF',
      emoji: '🗿',
      name: 'moai',
      nameUrl: 'moai',
      },
    {
      id: 1235,
      code: 'U+1F3E7',
      emoji: '🏧',
      name: 'ATM sign',
      nameUrl: 'atm-sign',
    },
    {
      id: 1236,
      code: 'U+1F6AE',
      emoji: '🚮',
      name: 'litter in bin sign',
      nameUrl: 'litter-in-bin-sign',
    },
    {
      id: 1237,
      code: 'U+1F6B0',
      emoji: '🚰',
      name: 'potable water',
      nameUrl: 'potable-water',
    },
    {
      id: 1238,
      code: 'U+267F',
      emoji: '♿',
      name: 'wheelchair symbol',
      nameUrl: 'wheelchair-symbol',
    },
    {
      id: 1239,
      code: 'U+1F6B9',
      emoji: '🚹',
      name: 'men’s room',
      nameUrl: 'mens-room',
    },
    {
      id: 1240,
      code: 'U+1F6BA',
      emoji: '🚺',
      name: 'women’s room',
      nameUrl: 'womens-room',
    },
    {
      id: 1241,
      code: 'U+1F6BB',
      emoji: '🚻',
      name: 'restroom',
      nameUrl: 'restroom',
    },
    {
      id: 1242,
      code: 'U+1F6BC',
      emoji: '🚼',
      name: 'baby symbol',
      nameUrl: 'baby-symbol',
    },
    {
      id: 1243,
      code: 'U+1F6BE',
      emoji: '🚾',
      name: 'water closet',
      nameUrl: 'water-closet',
    },
    {
      id: 1244,
      code: 'U+1F6C2',
      emoji: '🛂',
      name: 'passport control',
      nameUrl: 'passport-control',
    },
    {
      id: 1245,
      code: 'U+1F6C3',
      emoji: '🛃',
      name: 'customs',
      nameUrl: 'customs',
    },
    {
      id: 1246,
      code: 'U+1F6C4',
      emoji: '🛄',
      name: 'baggage claim',
      nameUrl: 'baggage-claim',
    },
    {
      id: 1247,
      code: 'U+1F6C5',
      emoji: '🛅',
      name: 'left luggage',
      nameUrl: 'left-luggage',
    },
    {
      id: 1248,
      code: 'U+26A0',
      emoji: '⚠️',
      name: 'warning',
      nameUrl: 'warning',
    },
    {
      id: 1249,
      code: 'U+1F6B8',
      emoji: '🚸',
      name: 'children crossing',
      nameUrl: 'children-crossing',
    },
    {
      id: 1250,
      code: 'U+26D4',
      emoji: '⛔',
      name: 'no entry',
      nameUrl: 'no-entry',
    },
    {
      id: 1251,
      code: 'U+1F6AB',
      emoji: '🚫',
      name: 'prohibited',
      nameUrl: 'prohibited',
    },
    {
      id: 1252,
      code: 'U+1F6B3',
      emoji: '🚳',
      name: 'no bicycles',
      nameUrl: 'no-bicycles',
    },
    {
      id: 1253,
      code: 'U+1F6AD',
      emoji: '🚭',
      name: 'no smoking',
      nameUrl: 'no-smoking',
    },
    {
      id: 1254,
      code: 'U+1F6AF',
      emoji: '🚯',
      name: 'no littering',
      nameUrl: 'no-littering',
    },
    {
      id: 1255,
      code: 'U+1F6B1',
      emoji: '🚱',
      name: 'non-potable water',
      nameUrl: 'non-potable-water',
    },
    {
      id: 1256,
      code: 'U+1F6B7',
      emoji: '🚷',
      name: 'no pedestrians',
      nameUrl: 'no-pedestrians',
    },
    {
      id: 1257,
      code: 'U+1F4F5',
      emoji: '📵',
      name: 'no mobile phones',
      nameUrl: 'no-mobile-phones',
    },
    {
      id: 1258,
      code: 'U+1F51E',
      emoji: '🔞',
      name: 'no one under eighteen',
      nameUrl: 'no-one-under-eighteen',
    },
    {
      id: 1259,
      code: 'U+2622',
      emoji: '☢',
      name: 'radioactive',
      nameUrl: 'radioactive',
    },
    {
      id: 1260,
      code: 'U+2623',
      emoji: '☣',
      name: 'biohazard',
      nameUrl: 'biohazard',
    },
    {
      id: 1261,
      code: 'U+2B06',
      emoji: '⬆️',
      name: 'up arrow',
      nameUrl: 'up-arrow',
    },
    {
      id: 1262,
      code: 'U+2197',
      emoji: '↗️',
      name: 'up-right arrow',
      nameUrl: 'up-right-arrow',
    },
    {
      id: 1263,
      code: 'U+27A1',
      emoji: '➡️',
      name: 'right arrow',
      nameUrl: 'right-arrow',
    },
    {
      id: 1264,
      code: 'U+2198',
      emoji: '↘️',
      name: 'down-right arrow',
      nameUrl: 'down-right-arrow',
    },
    {
      id: 1265,
      code: 'U+2B07',
      emoji: '⬇️',
      name: 'down arrow',
      nameUrl: 'down-arrow',
    },
    {
      id: 1266,
      code: 'U+2199',
      emoji: '↙️',
      name: 'down-left arrow',
      nameUrl: 'down-left-arrow',
    },
    {
      id: 1267,
      code: 'U+2B05',
      emoji: '⬅️',
      name: 'left arrow',
      nameUrl: 'left-arrow',
    },
    {
      id: 1268,
      code: 'U+2196',
      emoji: '↖️',
      name: 'up-left arrow',
      nameUrl: 'up-left-arrow',
    },
    {
      id: 1269,
      code: 'U+2195',
      emoji: '↕',
      name: 'up-down arrow',
      nameUrl: 'up-down-arrow',
    },
    {
      id: 1270,
      code: 'U+2194',
      emoji: '↔',
      name: 'left-right arrow',
      nameUrl: 'left-right-arrow',
    },
    {
      id: 1271,
      code: 'U+21A9',
      emoji: '↩️',
      name: 'right arrow curving left',
      nameUrl: 'right-arrow-curving-left',
    },
    {
      id: 1272,
      code: 'U+21AA',
      emoji: '↪️',
      name: 'left arrow curving right',
      nameUrl: 'left-arrow-curving-right',
    },
    {
      id: 1273,
      code: 'U+2934',
      emoji: '⤴️',
      name: 'right arrow curving up',
      nameUrl: 'right-arrow-curving-up',
    },
    {
      id: 1274,
      code: 'U+2935',
      emoji: '⤵️',
      name: 'right arrow curving down',
      nameUrl: 'right-arrow-curving-down',
    },
    {
      id: 1275,
      code: 'U+1F503',
      emoji: '🔃',
      name: 'clockwise vertical arrows',
      nameUrl: 'clockwise-vertical-arrows',
    },
    {
      id: 1276,
      code: 'U+1F504',
      emoji: '🔄',
      name: 'counterclockwise arrows button',
      nameUrl: 'counterclockwise-arrows-button',
    },
    {
      id: 1277,
      code: 'U+1F519',
      emoji: '🔙',
      name: 'BACK arrow',
      nameUrl: 'back-arrow',
    },
    {
      id: 1278,
      code: 'U+1F51A',
      emoji: '🔚',
      name: 'END arrow',
      nameUrl: 'end-arrow',
    },
    {
      id: 1279,
      code: 'U+1F51B',
      emoji: '🔛',
      name: 'ON! arrow',
      nameUrl: 'on-arrow',
    },
    {
      id: 1280,
      code: 'U+1F51C',
      emoji: '🔜',
      name: 'SOON arrow',
      nameUrl: 'soon-arrow',
    },
    {
      id: 1281,
      code: 'U+1F51D',
      emoji: '🔝',
      name: 'TOP arrow',
      nameUrl: 'top-arrow',
    },
    {
      id: 1282,
      code: 'U+1F6D0',
      emoji: '🛐',
      name: 'place of worship',
      nameUrl: 'place-of-worship',
    },
    {
      id: 1283,
      code: 'U+269B',
      emoji: '⚛',
      name: 'atom symbol',
      nameUrl: 'atom-symbol',
    },
    {
      id: 1284,
      code: 'U+1F549',
      emoji: '🕉',
      name: 'om',
      nameUrl: 'om',
    },
    {
      id: 1285,
      code: 'U+2721',
      emoji: '✡️',
      name: 'star of David',
      nameUrl: 'star-of-david',
    },
    {
      id: 1286,
      code: 'U+2638',
      emoji: '☸',
      name: 'wheel of dharma',
      nameUrl: 'wheel-of-dharma',
    },
    {
      id: 1287,
      code: 'U+262F',
      emoji: '☯',
      name: 'yin yang',
      nameUrl: 'yin-yang',
    },
    {
      id: 1288,
      code: 'U+271D',
      emoji: '✝️',
      name: 'latin cross',
      nameUrl: 'latin-cross',
    },
    {
      id: 1289,
      code: 'U+2626',
      emoji: '☦',
      name: 'orthodox cross',
      nameUrl: 'orthodox-cross',
    },
    {
      id: 1290,
      code: 'U+262A',
      emoji: '☪',
      name: 'star and crescent',
      nameUrl: 'star-and-crescent',
    },
    {
      id: 1291,
      code: 'U+262E',
      emoji: '☮',
      name: 'peace symbol',
      nameUrl: 'peace-symbol',
    },
    {
      id: 1292,
      code: 'U+1F54E',
      emoji: '🕎',
      name: 'menorah',
      nameUrl: 'menorah',
    },
    {
      id: 1293,
      code: 'U+1F52F',
      emoji: '🔯',
      name: 'dotted six-pointed star',
      nameUrl: 'dotted-six-pointed-star',
    },
    {
      id: 1294,
      code: 'U+2648',
      emoji: '♈',
      name: 'Aries',
      nameUrl: 'aries',
    },
    {
      id: 1295,
      code: 'U+2649',
      emoji: '♉',
      name: 'Taurus',
      nameUrl: 'taurus',
    },
    {
      id: 1296,
      code: 'U+264A',
      emoji: '♊',
      name: 'Gemini',
      nameUrl: 'gemini',
    },
    {
      id: 1297,
      code: 'U+264B',
      emoji: '♋',
      name: 'Cancer',
      nameUrl: 'cancer',
    },
    {
      id: 1298,
      code: 'U+264C',
      emoji: '♌',
      name: 'Leo',
      nameUrl: 'leo',
    },
    {
      id: 1299,
      code: 'U+264D',
      emoji: '♍',
      name: 'Virgo',
      nameUrl: 'virgo',
    },
    {
      id: 1300,
      code: 'U+264E',
      emoji: '♎',
      name: 'Libra',
      nameUrl: 'libra',
    },
    {
      id: 1301,
      code: 'U+264F',
      emoji: '♏',
      name: 'Scorpio',
      nameUrl: 'scorpio',
    },
    {
      id: 1302,
      code: 'U+2650',
      emoji: '♐',
      name: 'Sagittarius',
      nameUrl: 'sagittarius',
    },
    {
      id: 1303,
      code: 'U+2651',
      emoji: '♑',
      name: 'Capricorn',
      nameUrl: 'capricorn',
    },
    {
      id: 1304,
      code: 'U+2652',
      emoji: '♒',
      name: 'Aquarius',
      nameUrl: 'aquarius',
    },
    {
      id: 1305,
      code: 'U+2653',
      emoji: '♓',
      name: 'Pisces',
      nameUrl: 'pisces',
    },
    {
      id: 1306,
      code: 'U+26CE',
      emoji: '⛎',
      name: 'Ophiuchus',
      nameUrl: 'ophiuchus',
    },
    {
      id: 1307,
      code: 'U+1F500',
      emoji: '🔀',
      name: 'shuffle tracks button',
      nameUrl: 'shuffle-tracks-button',
    },
    {
      id: 1308,
      code: 'U+1F501',
      emoji: '🔁',
      name: 'repeat button',
      nameUrl: 'repeat-button',
    },
    {
      id: 1309,
      code: 'U+1F502',
      emoji: '🔂',
      name: 'repeat single button',
      nameUrl: 'repeat-single-button',
    },
    {
      id: 1310,
      code: 'U+25B6',
      emoji: '▶️',
      name: 'play button',
      nameUrl: 'play-button',
    },
    {
      id: 1311,
      code: 'U+23E9',
      emoji: '⏩',
      name: 'fast-forward button',
      nameUrl: 'fast-forward-button',
    },
    {
      id: 1312,
      code: 'U+23ED',
      emoji: '⏭',
      name: 'next track button',
      nameUrl: 'next-track-button',
    },
    {
      id: 1313,
      code: 'U+23EF',
      emoji: '⏯',
      name: 'play or pause button',
      nameUrl: 'play-or-pause-button',
    },
    {
      id: 1314,
      code: 'U+25C0',
      emoji: '◀️',
      name: 'reverse button',
      nameUrl: 'reverse-button',
    },
    {
      id: 1315,
      code: 'U+23EA',
      emoji: '⏪',
      name: 'fast reverse button',
      nameUrl: 'fast-reverse-button',
    },
    {
      id: 1316,
      code: 'U+23EE',
      emoji: '⏮',
      name: 'last track button',
      nameUrl: 'last-track-button',
    },
    {
      id: 1317,
      code: 'U+1F53C',
      emoji: '🔼',
      name: 'upwards button',
      nameUrl: 'upwards-button',
    },
    {
      id: 1318,
      code: 'U+23EB',
      emoji: '⏫',
      name: 'fast up button',
      nameUrl: 'fast-up-button',
    },
    {
      id: 1319,
      code: 'U+1F53D',
      emoji: '🔽',
      name: 'downwards button',
      nameUrl: 'downwards-button',
    },
    {
      id: 1320,
      code: 'U+23EC',
      emoji: '⏬',
      name: 'fast down button',
      nameUrl: 'fast-down-button',
    },
    {
      id: 1321,
      code: 'U+23F8',
      emoji: '⏸',
      name: 'pause button',
      nameUrl: 'pause-button',
    },
    {
      id: 1322,
      code: 'U+23F9',
      emoji: '⏹',
      name: 'stop button',
      nameUrl: 'stop-button',
    },
    {
      id: 1323,
      code: 'U+23FA',
      emoji: '⏺',
      name: 'record button',
      nameUrl: 'record-button',
    },
    {
      id: 1324,
      code: 'U+23CF',
      emoji: '⏏',
      name: 'eject button',
      nameUrl: 'eject-button',
    },
    {
      id: 1325,
      code: 'U+1F3A6',
      emoji: '🎦',
      name: 'cinema',
      nameUrl: 'cinema',
    },
    {
      id: 1326,
      code: 'U+1F505',
      emoji: '🔅',
      name: 'dim button',
      nameUrl: 'dim-button',
    },
    {
      id: 1327,
      code: 'U+1F506',
      emoji: '🔆',
      name: 'bright button',
      nameUrl: 'bright-button',
    },
    {
      id: 1328,
      code: 'U+1F4F6',
      emoji: '📶',
      name: 'antenna bars',
      nameUrl: 'antenna-bars',
    },
    {
      id: 1329,
      code: 'U+1F4F3',
      emoji: '📳',
      name: 'vibration mode',
      nameUrl: 'vibration-mode',
    },
    {
      id: 1330,
      code: 'U+1F4F4',
      emoji: '📴',
      name: 'mobile phone off',
      nameUrl: 'mobile-phone-off',
    },
    {
      id: 1331,
      code: 'U+2640',
      emoji: '♀',
      name: 'female sign',
      nameUrl: 'female-sign',
    },
    {
      id: 1332,
      code: 'U+2642',
      emoji: '♂',
      name: 'male sign',
      nameUrl: 'male-sign',
    },
    {
      id: 1333,
      code: 'U+2695',
      emoji: '⚕',
      name: 'medical symbol',
      nameUrl: 'medical-symbol',
    },
    {
      id: 1334,
      code: 'U+267E',
      emoji: '♾',
      name: 'infinity',
      nameUrl: 'infinity',
    },
    {
      id: 1335,
      code: 'U+267B',
      emoji: '♻️',
      name: 'recycling symbol',
      nameUrl: 'recycling-symbol',
    },
    {
      id: 1336,
      code: 'U+269C',
      emoji: '⚜',
      name: 'fleur-de-lis',
      nameUrl: 'fleur-de-lis',
    },
    {
      id: 1337,
      code: 'U+1F531',
      emoji: '🔱',
      name: 'trident emblem',
      nameUrl: 'trident-emblem',
    },
    {
      id: 1338,
      code: 'U+1F4DB',
      emoji: '📛',
      name: 'name badge',
      nameUrl: 'name-badge',
    },
    {
      id: 1339,
      code: 'U+1F530',
      emoji: '🔰',
      name: 'Japanese symbol for beginner',
      nameUrl: 'japanese-symbol-for-beginner',
    },
    {
      id: 1340,
      code: 'U+2B55',
      emoji: '⭕',
      name: 'hollow red circle',
      nameUrl: 'hollow-red-circle',
    },
    {
      id: 1341,
      code: 'U+2705',
      emoji: '✅',
      name: 'check mark button',
      nameUrl: 'check-mark-button',
    },
    {
      id: 1342,
      code: 'U+2611',
      emoji: '☑️',
      name: 'check box with check',
      nameUrl: 'check-box-with-check',
    },
    {
      id: 1343,
      code: 'U+2714',
      emoji: '✔️',
      name: 'check mark',
      nameUrl: 'check-mark',
    },
    {
      id: 1344,
      code: 'U+2716',
      emoji: '✖️',
      name: 'multiplication sign',
      nameUrl: 'multiplication-sign',
    },
    {
      id: 1345,
      code: 'U+274C',
      emoji: '❌',
      name: 'cross mark',
      nameUrl: 'cross-mark',
    },
    {
      id: 1346,
      code: 'U+274E',
      emoji: '❎',
      name: 'cross mark button',
      nameUrl: 'cross-mark-button',
    },
    {
      id: 1347,
      code: 'U+2795',
      emoji: '➕',
      name: 'plus sign',
      nameUrl: 'plus-sign',
    },
    {
      id: 1348,
      code: 'U+2796',
      emoji: '➖',
      name: 'minus sign',
      nameUrl: 'minus-sign',
    },
    {
      id: 1349,
      code: 'U+2797',
      emoji: '➗',
      name: 'division sign',
      nameUrl: 'division-sign',
    },
    {
      id: 1350,
      code: 'U+27B0',
      emoji: '➰',
      name: 'curly loop',
      nameUrl: 'curly-loop',
    },
    {
      id: 1351,
      code: 'U+27BF',
      emoji: '➿',
      name: 'double curly loop',
      nameUrl: 'double-curly-loop',
    },
    {
      id: 1352,
      code: 'U+303D',
      emoji: '〽',
      name: 'part alternation mark',
      nameUrl: 'part-alternation-mark',
    },
    {
      id: 1353,
      code: 'U+2733',
      emoji: '✳️',
      name: 'eight-spoked asterisk',
      nameUrl: 'eight-spoked-asterisk',
    },
    {
      id: 1354,
      code: 'U+2734',
      emoji: '✴️',
      name: 'eight-pointed star',
      nameUrl: 'eight-pointed-star',
    },
    {
      id: 1355,
      code: 'U+2747',
      emoji: '❇️',
      name: 'sparkle',
      nameUrl: 'sparkle',
    },
    {
      id: 1356,
      code: 'U+203C',
      emoji: '‼',
      name: 'double exclamation mark',
      nameUrl: 'double-exclamation-mark',
    },
    {
      id: 1357,
      code: 'U+2049',
      emoji: '⁉',
      name: 'exclamation question mark',
      nameUrl: 'exclamation-question-mark',
    },
    {
      id: 1358,
      code: 'U+2753',
      emoji: '❓',
      name: 'question mark',
      nameUrl: 'question-mark',
    },
    {
      id: 1359,
      code: 'U+2754',
      emoji: '❔',
      name: 'white question mark',
      nameUrl: 'white-question-mark',
    },
    {
      id: 1360,
      code: 'U+2755',
      emoji: '❕',
      name: 'white exclamation mark',
      nameUrl: 'white-exclamation-mark',
    },
    {
      id: 1361,
      code: 'U+2757',
      emoji: '❗',
      name: 'exclamation mark',
      nameUrl: 'exclamation-mark',
    },
    {
      id: 1362,
      code: 'U+3030',
      emoji: '〰',
      name: 'wavy dash',
      nameUrl: 'wavy-dash',
    },
    {
      id: 1363,
      code: 'U+00A9',
      emoji: '©',
      name: 'copyright',
      nameUrl: 'copyright',
    },
    {
      id: 1364,
      code: 'U+00AE',
      emoji: '®',
      name: 'registered',
      nameUrl: 'registered',
    },
    {
      id: 1365,
      code: 'U+2122',
      emoji: '™',
      name: 'trade mark',
      nameUrl: 'trade-mark',
    },
    {
      id: 1366,
      code: 'U+0023 U+FE0F U+20E3',
      emoji: '#️⃣',
      name: 'keycap: #',
      nameUrl: 'keycap-hash',
    },
    {
      id: 1367,
      code: 'U+002A U+FE0F U+20E3',
      emoji: '*️⃣',
      name: 'keycap: *',
      nameUrl: 'keycap-asterisk',
    },
    {
      id: 1368,
      code: 'U+0030 U+FE0F U+20E3',
      emoji: '0️⃣',
      name: 'keycap: 0',
      nameUrl: 'keycap-0',
    },
    {
      id: 1369,
      code: 'U+0031 U+FE0F U+20E3',
      emoji: '1️⃣',
      name: 'keycap: 1',
      nameUrl: 'keycap-1',
    },
    {
      id: 1370,
      code: 'U+0032 U+FE0F U+20E3',
      emoji: '2️⃣',
      name: 'keycap: 2',
      nameUrl: 'keycap-2',
    },
    {
      id: 1371,
      code: 'U+0033 U+FE0F U+20E3',
      emoji: '3️⃣',
      name: 'keycap: 3',
      nameUrl: 'keycap-3',
    },
    {
      id: 1372,
      code: 'U+0034 U+FE0F U+20E3',
      emoji: '4️⃣',
      name: 'keycap: 4',
      nameUrl: 'keycap-4',
    },
    {
      id: 1373,
      code: 'U+0035 U+FE0F U+20E3',
      emoji: '5️⃣',
      name: 'keycap: 5',
      nameUrl: 'keycap-5',
    },
    {
      id: 1374,
      code: 'U+0036 U+FE0F U+20E3',
      emoji: '6️⃣',
      name: 'keycap: 6',
      nameUrl: 'keycap-6',
    },
    {
      id: 1375,
      code: 'U+0037 U+FE0F U+20E3',
      emoji: '7️⃣',
      name: 'keycap: 7',
      nameUrl: 'keycap-7',
    },
    {
      id: 1376,
      code: 'U+0038 U+FE0F U+20E3',
      emoji: '8️⃣',
      name: 'keycap: 8',
      nameUrl: 'keycap-8',
    },
    {
      id: 1377,
      code: 'U+0039 U+FE0F U+20E3',
      emoji: '9️⃣',
      name: 'keycap: 9',
      nameUrl: 'keycap-9',
    },
    {
      id: 1378,
      code: 'U+1F51F',
      emoji: '🔟',
      name: 'keycap: 10',
      nameUrl: 'keycap-10',
    },
    {
      id: 1379,
      code: 'U+1F520',
      emoji: '🔠',
      name: 'input latin uppercase',
      nameUrl: 'input-latin-uppercase',
    },
    {
      id: 1380,
      code: 'U+1F521',
      emoji: '🔡',
      name: 'input latin lowercase',
      nameUrl: 'input-latin-lowercase',
    },
    {
      id: 1381,
      code: 'U+1F522',
      emoji: '🔢',
      name: 'input numbers',
      nameUrl: 'input-numbers',
    },
    {
      id: 1382,
      code: 'U+1F523',
      emoji: '🔣',
      name: 'input symbols',
      nameUrl: 'input-symbols',
    },
    {
      id: 1383,
      code: 'U+1F524',
      emoji: '🔤',
      name: 'input latin letters',
      nameUrl: 'input-latin-letters',
    },
    {
      id: 1384,
      code: 'U+1F170',
      emoji: '🅰',
      name: 'A button (blood type)',
      nameUrl: 'a-button-blood-type',
    },
    {
      id: 1385,
      code: 'U+1F18E',
      emoji: '🆎',
      name: 'AB button (blood type)',
      nameUrl: 'ab-button-blood-type',
    },
    {
      id: 1386,
      code: 'U+1F171',
      emoji: '🅱',
      name: 'B button (blood type)',
      nameUrl: 'b-button-blood-type',
    },
    {
      id: 1387,
      code: 'U+1F191',
      emoji: '🆑',
      name: 'CL button',
      nameUrl: 'cl-button',
    },
    {
      id: 1388,
      code: 'U+1F192',
      emoji: '🆒',
      name: 'COOL button',
      nameUrl: 'cool-button',
    },
    {
      id: 1389,
      code: 'U+1F193',
      emoji: '🆓',
      name: 'FREE button',
      nameUrl: 'free-button',
    },
    {
      id: 1390,
      code: 'U+2139',
      emoji: 'ℹ',
      name: 'information',
      nameUrl: 'information',
    },
    {
      id: 1391,
      code: 'U+1F194',
      emoji: '🆔',
      name: 'ID button',
      nameUrl: 'id-button',
    },
    {
      id: 1392,
      code: 'U+24C2',
      emoji: 'Ⓜ️',
      name: 'circled M',
      nameUrl: 'circled-m',
    },
    {
      id: 1393,
      code: 'U+1F195',
      emoji: '🆕',
      name: 'NEW button',
      nameUrl: 'new-button',
    },
    {
      id: 1394,
      code: 'U+1F196',
      emoji: '🆖',
      name: 'NG button',
      nameUrl: 'ng-button',
    },
    {
      id: 1395,
      code: 'U+1F17E',
      emoji: '🅾',
      name: 'O button (blood type)',
      nameUrl: 'o-button-blood-type',
    },
    {
      id: 1396,
      code: 'U+1F197',
      emoji: '🆗',
      name: 'OK button',
      nameUrl: 'ok-button',
    },
    {
      id: 1397,
      code: 'U+1F17F',
      emoji: '🅿',
      name: 'P button',
      nameUrl: 'p-button',
    },
    {
      id: 1398,
      code: 'U+1F198',
      emoji: '🆘',
      name: 'SOS button',
      nameUrl: 'sos-button',
    },
    {
      id: 1399,
      code: 'U+1F199',
      emoji: '🆙',
      name: 'UP! button',
      nameUrl: 'up-button',
    },
    {
      id: 1400,
      code: 'U+1F19A',
      emoji: '🆚',
      name: 'VS button',
      nameUrl: 'vs-button',
    },
    {
      id: 1401,
      code: 'U+1F201',
      emoji: '🈁',
      name: 'Japanese “here” button',
      nameUrl: 'japanese-here-button',
    },
    {
      id: 1402,
      code: 'U+1F202',
      emoji: '🈂',
      name: 'Japanese “service charge” button',
      nameUrl: 'japanese-service-charge-button',
    },
    {
      id: 1403,
      code: 'U+1F237',
      emoji: '🈷',
      name: 'Japanese “monthly amount” button',
      nameUrl: 'japanese-monthly-amount-button',
    },
    {
      id: 1404,
      code: 'U+1F236',
      emoji: '🈶',
      name: 'Japanese “not free of charge” button',
      nameUrl: 'japanese-not-free-of-charge-button',
    },
    {
      id: 1405,
      code: 'U+1F22F',
      emoji: '🈯',
      name: 'Japanese “reserved” button',
      nameUrl: 'japanese-reserved-button',
    },
    {
      id: 1406,
      code: 'U+1F250',
      emoji: '🉐',
      name: 'Japanese “bargain” button',
      nameUrl: 'japanese-bargain-button',
    },
    {
      id: 1407,
      code: 'U+1F239',
      emoji: '🈹',
      name: 'Japanese “discount” button',
      nameUrl: 'japanese-discount-button',
    },
    {
      id: 1408,
      code: 'U+1F21A',
      emoji: '🈚',
      name: 'Japanese “free of charge” button',
      nameUrl: 'japanese-free-of-charge-button',
    },
    {
      id: 1409,
      code: 'U+1F232',
      emoji: '🈲',
      name: 'Japanese “prohibited” button',
      nameUrl: 'japanese-prohibited-button',
    },
    {
      id: 1410,
      code: 'U+1F251',
      emoji: '🉑',
      name: 'Japanese “acceptable” button',
      nameUrl: 'japanese-acceptable-button',
    },
    {
      id: 1411,
      code: 'U+1F238',
      emoji: '🈸',
      name: 'Japanese “application” button',
      nameUrl: 'japanese-application-button',
    },
    {
      id: 1412,
      code: 'U+1F234',
      emoji: '🈴',
      name: 'Japanese “passing grade” button',
      nameUrl: 'japanese-passing-grade-button',
    },
    {
      id: 1413,
      code: 'U+1F233',
      emoji: '🈳',
      name: 'Japanese “vacancy” button',
      nameUrl: 'japanese-vacancy-button',
    },
    {
      id: 1414,
      code: 'U+3297',
      emoji: '㊗',
      name: 'Japanese “congratulations” button',
      nameUrl: 'japanese-congratulations-button',
    },
    {
      id: 1415,
      code: 'U+3299',
      emoji: '㊙',
      name: 'Japanese “secret” button',
      nameUrl: 'japanese-secret-button',
    },
    {
      id: 1416,
      code: 'U+1F23A',
      emoji: '🈺',
      name: 'Japanese “open for business” button',
      nameUrl: 'japanese-open-for-business-button',
    },
    {
      id: 1417,
      code: 'U+1F235',
      emoji: '🈵',
      name: 'Japanese “no vacancy” button',
      nameUrl: 'japanese-no-vacancy-button',
    },
    {
      id: 1418,
      code: 'U+1F534',
      emoji: '🔴',
      name: 'red circle',
      nameUrl: 'red-circle',
    },
    {
      id: 1422,
      code: 'U+1F535',
      emoji: '🔵',
      name: 'blue circle',
      nameUrl: 'blue-circle',
    },
    {
      id: 1425,
      code: 'U+26AB',
      emoji: '⚫',
      name: 'black circle',
      nameUrl: 'black-circle',
    },
    {
      id: 1426,
      code: 'U+26AA',
      emoji: '⚪',
      name: 'white circle',
      nameUrl: 'white-circle',
    },
    {
      id: 1434,
      code: 'U+2B1B',
      emoji: '⬛',
      name: 'black large square',
      nameUrl: 'black-large-square',
    },
    {
      id: 1435,
      code: 'U+2B1C',
      emoji: '⬜',
      name: 'white large square',
      nameUrl: 'white-large-square',
    },
    {
      id: 1436,
      code: 'U+25FC',
      emoji: '◼',
      name: 'black medium square',
      nameUrl: 'black-medium-square',
    },
    {
      id: 1437,
      code: 'U+25FB',
      emoji: '◻',
      name: 'white medium square',
      nameUrl: 'white-medium-square',
    },
    {
      id: 1438,
      code: 'U+25FE',
      emoji: '◾',
      name: 'black medium-small square',
      nameUrl: 'black-medium-small-square',
    },
    {
      id: 1439,
      code: 'U+25FD',
      emoji: '◽',
      name: 'white medium-small square',
      nameUrl: 'white-medium-small-square',
    },
    {
      id: 1440,
      code: 'U+25AA',
      emoji: '▪',
      name: 'black small square',
      nameUrl: 'black-small-square',
    },
    {
      id: 1441,
      code: 'U+25AB',
      emoji: '▫',
      name: 'white small square',
      nameUrl: 'white-small-square',
    },
    {
      id: 1442,
      code: 'U+1F536',
      emoji: '🔶',
      name: 'large orange diamond',
      nameUrl: 'large-orange-diamond',
    },
    {
      id: 1443,
      code: 'U+1F537',
      emoji: '🔷',
      name: 'large blue diamond',
      nameUrl: 'large-blue-diamond',
    },
    {
      id: 1444,
      code: 'U+1F538',
      emoji: '🔸',
      name: 'small orange diamond',
      nameUrl: 'small-orange-diamond',
    },
    {
      id: 1445,
      code: 'U+1F539',
      emoji: '🔹',
      name: 'small blue diamond',
      nameUrl: 'small-blue-diamond',
    },
    {
      id: 1446,
      code: 'U+1F53A',
      emoji: '🔺',
      name: 'red triangle pointed up',
      nameUrl: 'red-triangle-pointed-up',
    },
    {
      id: 1447,
      code: 'U+1F53B',
      emoji: '🔻',
      name: 'red triangle pointed down',
      nameUrl: 'red-triangle-pointed-down',
    },
    {
      id: 1448,
      code: 'U+1F4A0',
      emoji: '💠',
      name: 'diamond with a dot',
      nameUrl: 'diamond-with-a-dot',
    },
    {
      id: 1449,
      code: 'U+1F518',
      emoji: '🔘',
      name: 'radio button',
      nameUrl: 'radio-button',
    },
    {
      id: 1450,
      code: 'U+1F533',
      emoji: '🔳',
      name: 'white square button',
      nameUrl: 'white-square-button',
    },
    {
      id: 1451,
      code: 'U+1F532',
      emoji: '🔲',
      name: 'black square button',
      nameUrl: 'black-square-button',
      },
    {
      id: 1452,
      code: 'U+1F3C1',
      emoji: '🏁',
      name: 'chequered flag',
      nameUrl: 'chequered-flag',
    },
    {
      id: 1453,
      code: 'U+1F6A9',
      emoji: '🚩',
      name: 'triangular flag',
      nameUrl: 'triangular-flag',
    },
    {
      id: 1454,
      code: 'U+1F38C',
      emoji: '🎌',
      name: 'crossed flags',
      nameUrl: 'crossed-flags',
    },
    {
      id: 1455,
      code: 'U+1F3F4',
      emoji: '🏴',
      name: 'black flag',
      nameUrl: 'black-flag',
    },
    {
      id: 1456,
      code: 'U+1F3F3',
      emoji: '🏳',
      name: 'white flag',
      nameUrl: 'white-flag',
    },
    {
      id: 1457,
      code: 'U+1F3F3 U+FE0F U+200D U+1F308',
      emoji: '🏳️‍🌈',
      name: 'rainbow flag',
      nameUrl: 'rainbow-flag',
    },
    {
      id: 1458,
      code: 'U+1F3F4 U+200D U+2620 U+FE0F',
      emoji: '🏴‍☠️',
      name: 'pirate flag',
      nameUrl: 'pirate-flag',
    },
    {
      id: 1459,
      code: 'U+1F1E6 U+1F1E8',
      emoji: '🇦🇨',
      name: 'flag: Ascension Island',
      nameUrl: 'flag-ascension-island',
    },
    {
      id: 1460,
      code: 'U+1F1E6 U+1F1E9',
      emoji: '🇦🇩',
      name: 'flag: Andorra',
      nameUrl: 'flag-andorra',
    },
    {
      id: 1461,
      code: 'U+1F1E6 U+1F1EA',
      emoji: '🇦🇪',
      name: 'flag: United Arab Emirates',
      nameUrl: 'flag-united-arab-emirates',
    },
    {
      id: 1462,
      code: 'U+1F1E6 U+1F1EB',
      emoji: '🇦🇫',
      name: 'flag: Afghanistan',
      nameUrl: 'flag-afghanistan',
    },
    {
      id: 1463,
      code: 'U+1F1E6 U+1F1EC',
      emoji: '🇦🇬',
      name: 'flag: Antigua &amp; Barbuda',
      nameUrl: 'flag-antigua-&-barbuda',
    },
    {
      id: 1464,
      code: 'U+1F1E6 U+1F1EE',
      emoji: '🇦🇮',
      name: 'flag: Anguilla',
      nameUrl: 'flag-anguilla',
    },
    {
      id: 1465,
      code: 'U+1F1E6 U+1F1F1',
      emoji: '🇦🇱',
      name: 'flag: Albania',
      nameUrl: 'flag-albania',
    },
    {
      id: 1466,
      code: 'U+1F1E6 U+1F1F2',
      emoji: '🇦🇲',
      name: 'flag: Armenia',
      nameUrl: 'flag-armenia',
    },
    {
      id: 1467,
      code: 'U+1F1E6 U+1F1F4',
      emoji: '🇦🇴',
      name: 'flag: Angola',
      nameUrl: 'flag-angola',
    },
    {
      id: 1468,
      code: 'U+1F1E6 U+1F1F6',
      emoji: '🇦🇶',
      name: 'flag: Antarctica',
      nameUrl: 'flag-antarctica',
    },
    {
      id: 1469,
      code: 'U+1F1E6 U+1F1F7',
      emoji: '🇦🇷',
      name: 'flag: Argentina',
      nameUrl: 'flag-argentina',
    },
    {
      id: 1470,
      code: 'U+1F1E6 U+1F1F8',
      emoji: '🇦🇸',
      name: 'flag: American Samoa',
      nameUrl: 'flag-american-samoa',
    },
    {
      id: 1471,
      code: 'U+1F1E6 U+1F1F9',
      emoji: '🇦🇹',
      name: 'flag: Austria',
      nameUrl: 'flag-austria',
    },
    {
      id: 1472,
      code: 'U+1F1E6 U+1F1FA',
      emoji: '🇦🇺',
      name: 'flag: Australia',
      nameUrl: 'flag-australia',
    },
    {
      id: 1473,
      code: 'U+1F1E6 U+1F1FC',
      emoji: '🇦🇼',
      name: 'flag: Aruba',
      nameUrl: 'flag-aruba',
    },
    {
      id: 1474,
      code: 'U+1F1E6 U+1F1FD',
      emoji: '🇦🇽',
      name: 'flag: Åland Islands',
      nameUrl: 'flag-aland-islands',
    },
    {
      id: 1475,
      code: 'U+1F1E6 U+1F1FF',
      emoji: '🇦🇿',
      name: 'flag: Azerbaijan',
      nameUrl: 'flag-azerbaijan',
    },
    {
      id: 1476,
      code: 'U+1F1E7 U+1F1E6',
      emoji: '🇧🇦',
      name: 'flag: Bosnia &amp; Herzegovina',
      nameUrl: 'flag-bosnia-&-herzegovina',
    },
    {
      id: 1477,
      code: 'U+1F1E7 U+1F1E7',
      emoji: '🇧🇧',
      name: 'flag: Barbados',
      nameUrl: 'flag-barbados',
    },
    {
      id: 1478,
      code: 'U+1F1E7 U+1F1E9',
      emoji: '🇧🇩',
      name: 'flag: Bangladesh',
      nameUrl: 'flag-bangladesh',
    },
    {
      id: 1479,
      code: 'U+1F1E7 U+1F1EA',
      emoji: '🇧🇪',
      name: 'flag: Belgium',
      nameUrl: 'flag-belgium',
    },
    {
      id: 1480,
      code: 'U+1F1E7 U+1F1EB',
      emoji: '🇧🇫',
      name: 'flag: Burkina Faso',
      nameUrl: 'flag-burkina-faso',
    },
    {
      id: 1481,
      code: 'U+1F1E7 U+1F1EC',
      emoji: '🇧🇬',
      name: 'flag: Bulgaria',
      nameUrl: 'flag-bulgaria',
    },
    {
      id: 1482,
      code: 'U+1F1E7 U+1F1ED',
      emoji: '🇧🇭',
      name: 'flag: Bahrain',
      nameUrl: 'flag-bahrain',
    },
    {
      id: 1483,
      code: 'U+1F1E7 U+1F1EE',
      emoji: '🇧🇮',
      name: 'flag: Burundi',
      nameUrl: 'flag-burundi',
    },
    {
      id: 1484,
      code: 'U+1F1E7 U+1F1EF',
      emoji: '🇧🇯',
      name: 'flag: Benin',
      nameUrl: 'flag-benin',
    },
    {
      id: 1485,
      code: 'U+1F1E7 U+1F1F1',
      emoji: '🇧🇱',
      name: 'flag: St. Barthélemy',
      nameUrl: 'flag-st-barthelemy',
    },
    {
      id: 1486,
      code: 'U+1F1E7 U+1F1F2',
      emoji: '🇧🇲',
      name: 'flag: Bermuda',
      nameUrl: 'flag-bermuda',
    },
    {
      id: 1487,
      code: 'U+1F1E7 U+1F1F3',
      emoji: '🇧🇳',
      name: 'flag: Brunei',
      nameUrl: 'flag-brunei',
    },
    {
      id: 1488,
      code: 'U+1F1E7 U+1F1F4',
      emoji: '🇧🇴',
      name: 'flag: Bolivia',
      nameUrl: 'flag-bolivia',
    },
    {
      id: 1489,
      code: 'U+1F1E7 U+1F1F6',
      emoji: '🇧🇶',
      name: 'flag: Caribbean Netherlands',
      nameUrl: 'flag-caribbean-netherlands',
    },
    {
      id: 1490,
      code: 'U+1F1E7 U+1F1F7',
      emoji: '🇧🇷',
      name: 'flag: Brazil',
      nameUrl: 'flag-brazil',
    },
    {
      id: 1491,
      code: 'U+1F1E7 U+1F1F8',
      emoji: '🇧🇸',
      name: 'flag: Bahamas',
      nameUrl: 'flag-bahamas',
    },
    {
      id: 1492,
      code: 'U+1F1E7 U+1F1F9',
      emoji: '🇧🇹',
      name: 'flag: Bhutan',
      nameUrl: 'flag-bhutan',
    },
    {
      id: 1493,
      code: 'U+1F1E7 U+1F1FB',
      emoji: '🇧🇻',
      name: 'flag: Bouvet Island',
      nameUrl: 'flag-bouvet-island',
    },
    {
      id: 1494,
      code: 'U+1F1E7 U+1F1FC',
      emoji: '🇧🇼',
      name: 'flag: Botswana',
      nameUrl: 'flag-botswana',
    },
    {
      id: 1495,
      code: 'U+1F1E7 U+1F1FE',
      emoji: '🇧🇾',
      name: 'flag: Belarus',
      nameUrl: 'flag-belarus',
    },
    {
      id: 1496,
      code: 'U+1F1E7 U+1F1FF',
      emoji: '🇧🇿',
      name: 'flag: Belize',
      nameUrl: 'flag-belize',
    },
    {
      id: 1497,
      code: 'U+1F1E8 U+1F1E6',
      emoji: '🇨🇦',
      name: 'flag: Canada',
      nameUrl: 'flag-canada',
    },
    {
      id: 1498,
      code: 'U+1F1E8 U+1F1E8',
      emoji: '🇨🇨',
      name: 'flag: Cocos (Keeling) Islands',
      nameUrl: 'flag-cocos-keeling-islands',
    },
    {
      id: 1499,
      code: 'U+1F1E8 U+1F1E9',
      emoji: '🇨🇩',
      name: 'flag: Congo - Kinshasa',
      nameUrl: 'flag-congo-kinshasa',
    },
    {
      id: 1500,
      code: 'U+1F1E8 U+1F1EB',
      emoji: '🇨🇫',
      name: 'flag: Central African Republic',
      nameUrl: 'flag-central-african-republic',
    },
    {
      id: 1501,
      code: 'U+1F1E8 U+1F1EC',
      emoji: '🇨🇬',
      name: 'flag: Congo - Brazzaville',
      nameUrl: 'flag-congo-brazzaville',
    },
    {
      id: 1502,
      code: 'U+1F1E8 U+1F1ED',
      emoji: '🇨🇭',
      name: 'flag: Switzerland',
      nameUrl: 'flag-switzerland',
    },
    {
      id: 1503,
      code: 'U+1F1E8 U+1F1EE',
      emoji: '🇨🇮',
      name: 'flag: Côte d’Ivoire',
      nameUrl: 'flag-cote-divoire',
    },
    {
      id: 1504,
      code: 'U+1F1E8 U+1F1F0',
      emoji: '🇨🇰',
      name: 'flag: Cook Islands',
      nameUrl: 'flag-cook-islands',
    },
    {
      id: 1505,
      code: 'U+1F1E8 U+1F1F1',
      emoji: '🇨🇱',
      name: 'flag: Chile',
      nameUrl: 'flag-chile',
    },
    {
      id: 1506,
      code: 'U+1F1E8 U+1F1F2',
      emoji: '🇨🇲',
      name: 'flag: Cameroon',
      nameUrl: 'flag-cameroon',
    },
    {
      id: 1507,
      code: 'U+1F1E8 U+1F1F3',
      emoji: '🇨🇳',
      name: 'flag: China',
      nameUrl: 'flag-china',
    },
    {
      id: 1508,
      code: 'U+1F1E8 U+1F1F4',
      emoji: '🇨🇴',
      name: 'flag: Colombia',
      nameUrl: 'flag-colombia',
    },
    {
      id: 1509,
      code: 'U+1F1E8 U+1F1F5',
      emoji: '🇨🇵',
      name: 'flag: Clipperton Island',
      nameUrl: 'flag-clipperton-island',
    },
    {
      id: 1510,
      code: 'U+1F1E8 U+1F1F7',
      emoji: '🇨🇷',
      name: 'flag: Costa Rica',
      nameUrl: 'flag-costa-rica',
    },
    {
      id: 1511,
      code: 'U+1F1E8 U+1F1FA',
      emoji: '🇨🇺',
      name: 'flag: Cuba',
      nameUrl: 'flag-cuba',
    },
    {
      id: 1512,
      code: 'U+1F1E8 U+1F1FB',
      emoji: '🇨🇻',
      name: 'flag: Cape Verde',
      nameUrl: 'flag-cape-verde',
    },
    {
      id: 1513,
      code: 'U+1F1E8 U+1F1FC',
      emoji: '🇨🇼',
      name: 'flag: Curaçao',
      nameUrl: 'flag-curacao',
    },
    {
      id: 1514,
      code: 'U+1F1E8 U+1F1FD',
      emoji: '🇨🇽',
      name: 'flag: Christmas Island',
      nameUrl: 'flag-christmas-island',
    },
    {
      id: 1515,
      code: 'U+1F1E8 U+1F1FE',
      emoji: '🇨🇾',
      name: 'flag: Cyprus',
      nameUrl: 'flag-cyprus',
    },
    {
      id: 1516,
      code: 'U+1F1E8 U+1F1FF',
      emoji: '🇨🇿',
      name: 'flag: Czechia',
      nameUrl: 'flag-czechia',
    },
    {
      id: 1517,
      code: 'U+1F1E9 U+1F1EA',
      emoji: '🇩🇪',
      name: 'flag: Germany',
      nameUrl: 'flag-germany',
    },
    {
      id: 1518,
      code: 'U+1F1E9 U+1F1EC',
      emoji: '🇩🇬',
      name: 'flag: Diego Garcia',
      nameUrl: 'flag-diego-garcia',
    },
    {
      id: 1519,
      code: 'U+1F1E9 U+1F1EF',
      emoji: '🇩🇯',
      name: 'flag: Djibouti',
      nameUrl: 'flag-djibouti',
    },
    {
      id: 1520,
      code: 'U+1F1E9 U+1F1F0',
      emoji: '🇩🇰',
      name: 'flag: Denmark',
      nameUrl: 'flag-denmark',
    },
    {
      id: 1521,
      code: 'U+1F1E9 U+1F1F2',
      emoji: '🇩🇲',
      name: 'flag: Dominica',
      nameUrl: 'flag-dominica',
    },
    {
      id: 1522,
      code: 'U+1F1E9 U+1F1F4',
      emoji: '🇩🇴',
      name: 'flag: Dominican Republic',
      nameUrl: 'flag-dominican-republic',
    },
    {
      id: 1523,
      code: 'U+1F1E9 U+1F1FF',
      emoji: '🇩🇿',
      name: 'flag: Algeria',
      nameUrl: 'flag-algeria',
    },
    {
      id: 1524,
      code: 'U+1F1EA U+1F1E6',
      emoji: '🇪🇦',
      name: 'flag: Ceuta &amp; Melilla',
      nameUrl: 'flag-ceuta-&-melilla',
    },
    {
      id: 1525,
      code: 'U+1F1EA U+1F1E8',
      emoji: '🇪🇨',
      name: 'flag: Ecuador',
      nameUrl: 'flag-ecuador',
    },
    {
      id: 1526,
      code: 'U+1F1EA U+1F1EA',
      emoji: '🇪🇪',
      name: 'flag: Estonia',
      nameUrl: 'flag-estonia',
    },
    {
      id: 1527,
      code: 'U+1F1EA U+1F1EC',
      emoji: '🇪🇬',
      name: 'flag: Egypt',
      nameUrl: 'flag-egypt',
    },
    {
      id: 1528,
      code: 'U+1F1EA U+1F1ED',
      emoji: '🇪🇭',
      name: 'flag: Western Sahara',
      nameUrl: 'flag-western-sahara',
    },
    {
      id: 1529,
      code: 'U+1F1EA U+1F1F7',
      emoji: '🇪🇷',
      name: 'flag: Eritrea',
      nameUrl: 'flag-eritrea',
    },
    {
      id: 1530,
      code: 'U+1F1EA U+1F1F8',
      emoji: '🇪🇸',
      name: 'flag: Spain',
      nameUrl: 'flag-spain',
    },
    {
      id: 1531,
      code: 'U+1F1EA U+1F1F9',
      emoji: '🇪🇹',
      name: 'flag: Ethiopia',
      nameUrl: 'flag-ethiopia',
    },
    {
      id: 1532,
      code: 'U+1F1EA U+1F1FA',
      emoji: '🇪🇺',
      name: 'flag: European Union',
      nameUrl: 'flag-european-union',
    },
    {
      id: 1533,
      code: 'U+1F1EB U+1F1EE',
      emoji: '🇫🇮',
      name: 'flag: Finland',
      nameUrl: 'flag-finland',
    },
    {
      id: 1534,
      code: 'U+1F1EB U+1F1EF',
      emoji: '🇫🇯',
      name: 'flag: Fiji',
      nameUrl: 'flag-fiji',
    },
    {
      id: 1535,
      code: 'U+1F1EB U+1F1F0',
      emoji: '🇫🇰',
      name: 'flag: Falkland Islands',
      nameUrl: 'flag-falkland-islands',
    },
    {
      id: 1536,
      code: 'U+1F1EB U+1F1F2',
      emoji: '🇫🇲',
      name: 'flag: Micronesia',
      nameUrl: 'flag-micronesia',
    },
    {
      id: 1537,
      code: 'U+1F1EB U+1F1F4',
      emoji: '🇫🇴',
      name: 'flag: Faroe Islands',
      nameUrl: 'flag-faroe-islands',
    },
    {
      id: 1538,
      code: 'U+1F1EB U+1F1F7',
      emoji: '🇫🇷',
      name: 'flag: France',
      nameUrl: 'flag-france',
    },
    {
      id: 1539,
      code: 'U+1F1EC U+1F1E6',
      emoji: '🇬🇦',
      name: 'flag: Gabon',
      nameUrl: 'flag-gabon',
    },
    {
      id: 1540,
      code: 'U+1F1EC U+1F1E7',
      emoji: '🇬🇧',
      name: 'flag: United Kingdom',
      nameUrl: 'flag-united-kingdom',
    },
    {
      id: 1541,
      code: 'U+1F1EC U+1F1E9',
      emoji: '🇬🇩',
      name: 'flag: Grenada',
      nameUrl: 'flag-grenada',
    },
    {
      id: 1542,
      code: 'U+1F1EC U+1F1EA',
      emoji: '🇬🇪',
      name: 'flag: Georgia',
      nameUrl: 'flag-georgia',
    },
    {
      id: 1543,
      code: 'U+1F1EC U+1F1EB',
      emoji: '🇬🇫',
      name: 'flag: French Guiana',
      nameUrl: 'flag-french-guiana',
    },
    {
      id: 1544,
      code: 'U+1F1EC U+1F1EC',
      emoji: '🇬🇬',
      name: 'flag: Guernsey',
      nameUrl: 'flag-guernsey',
    },
    {
      id: 1545,
      code: 'U+1F1EC U+1F1ED',
      emoji: '🇬🇭',
      name: 'flag: Ghana',
      nameUrl: 'flag-ghana',
    },
    {
      id: 1546,
      code: 'U+1F1EC U+1F1EE',
      emoji: '🇬🇮',
      name: 'flag: Gibraltar',
      nameUrl: 'flag-gibraltar',
    },
    {
      id: 1547,
      code: 'U+1F1EC U+1F1F1',
      emoji: '🇬🇱',
      name: 'flag: Greenland',
      nameUrl: 'flag-greenland',
    },
    {
      id: 1548,
      code: 'U+1F1EC U+1F1F2',
      emoji: '🇬🇲',
      name: 'flag: Gambia',
      nameUrl: 'flag-gambia',
    },
    {
      id: 1549,
      code: 'U+1F1EC U+1F1F3',
      emoji: '🇬🇳',
      name: 'flag: Guinea',
      nameUrl: 'flag-guinea',
    },
    {
      id: 1550,
      code: 'U+1F1EC U+1F1F5',
      emoji: '🇬🇵',
      name: 'flag: Guadeloupe',
      nameUrl: 'flag-guadeloupe',
    },
    {
      id: 1551,
      code: 'U+1F1EC U+1F1F6',
      emoji: '🇬🇶',
      name: 'flag: Equatorial Guinea',
      nameUrl: 'flag-equatorial-guinea',
    },
    {
      id: 1552,
      code: 'U+1F1EC U+1F1F7',
      emoji: '🇬🇷',
      name: 'flag: Greece',
      nameUrl: 'flag-greece',
    },
    {
      id: 1553,
      code: 'U+1F1EC U+1F1F8',
      emoji: '🇬🇸',
      name: 'flag: South Georgia &amp; South Sandwich Islands',
      nameUrl: 'flag-south-georgia-&-south-sandwich-islands',
    },
    {
      id: 1554,
      code: 'U+1F1EC U+1F1F9',
      emoji: '🇬🇹',
      name: 'flag: Guatemala',
      nameUrl: 'flag-guatemala',
    },
    {
      id: 1555,
      code: 'U+1F1EC U+1F1FA',
      emoji: '🇬🇺',
      name: 'flag: Guam',
      nameUrl: 'flag-guam',
    },
    {
      id: 1556,
      code: 'U+1F1EC U+1F1FC',
      emoji: '🇬🇼',
      name: 'flag: Guinea-Bissau',
      nameUrl: 'flag-guinea-bissau',
    },
    {
      id: 1557,
      code: 'U+1F1EC U+1F1FE',
      emoji: '🇬🇾',
      name: 'flag: Guyana',
      nameUrl: 'flag-guyana',
    },
    {
      id: 1558,
      code: 'U+1F1ED U+1F1F0',
      emoji: '🇭🇰',
      name: 'flag: Hong Kong SAR China',
      nameUrl: 'flag-hong-kong-sar-china',
    },
    {
      id: 1559,
      code: 'U+1F1ED U+1F1F2',
      emoji: '🇭🇲',
      name: 'flag: Heard &amp; McDonald Islands',
      nameUrl: 'flag-heard-&-mcdonald-islands',
    },
    {
      id: 1560,
      code: 'U+1F1ED U+1F1F3',
      emoji: '🇭🇳',
      name: 'flag: Honduras',
      nameUrl: 'flag-honduras',
    },
    {
      id: 1561,
      code: 'U+1F1ED U+1F1F7',
      emoji: '🇭🇷',
      name: 'flag: Croatia',
      nameUrl: 'flag-croatia',
    },
    {
      id: 1562,
      code: 'U+1F1ED U+1F1F9',
      emoji: '🇭🇹',
      name: 'flag: Haiti',
      nameUrl: 'flag-haiti',
    },
    {
      id: 1563,
      code: 'U+1F1ED U+1F1FA',
      emoji: '🇭🇺',
      name: 'flag: Hungary',
      nameUrl: 'flag-hungary',
    },
    {
      id: 1564,
      code: 'U+1F1EE U+1F1E8',
      emoji: '🇮🇨',
      name: 'flag: Canary Islands',
      nameUrl: 'flag-canary-islands',
    },
    {
      id: 1565,
      code: 'U+1F1EE U+1F1E9',
      emoji: '🇮🇩',
      name: 'flag: Indonesia',
      nameUrl: 'flag-indonesia',
    },
    {
      id: 1566,
      code: 'U+1F1EE U+1F1EA',
      emoji: '🇮🇪',
      name: 'flag: Ireland',
      nameUrl: 'flag-ireland',
    },
    {
      id: 1567,
      code: 'U+1F1EE U+1F1F1',
      emoji: '🇮🇱',
      name: 'flag: Israel',
      nameUrl: 'flag-israel',
    },
    {
      id: 1568,
      code: 'U+1F1EE U+1F1F2',
      emoji: '🇮🇲',
      name: 'flag: Isle of Man',
      nameUrl: 'flag-isle-of-man',
    },
    {
      id: 1569,
      code: 'U+1F1EE U+1F1F3',
      emoji: '🇮🇳',
      name: 'flag: India',
      nameUrl: 'flag-india',
    },
    {
      id: 1570,
      code: 'U+1F1EE U+1F1F4',
      emoji: '🇮🇴',
      name: 'flag: British Indian Ocean Territory',
      nameUrl: 'flag-british-indian-ocean-territory',
    },
    {
      id: 1571,
      code: 'U+1F1EE U+1F1F6',
      emoji: '🇮🇶',
      name: 'flag: Iraq',
      nameUrl: 'flag-iraq',
    },
    {
      id: 1572,
      code: 'U+1F1EE U+1F1F7',
      emoji: '🇮🇷',
      name: 'flag: Iran',
      nameUrl: 'flag-iran',
    },
    {
      id: 1573,
      code: 'U+1F1EE U+1F1F8',
      emoji: '🇮🇸',
      name: 'flag: Iceland',
      nameUrl: 'flag-iceland',
    },
    {
      id: 1574,
      code: 'U+1F1EE U+1F1F9',
      emoji: '🇮🇹',
      name: 'flag: Italy',
      nameUrl: 'flag-italy',
    },
    {
      id: 1575,
      code: 'U+1F1EF U+1F1EA',
      emoji: '🇯🇪',
      name: 'flag: Jersey',
      nameUrl: 'flag-jersey',
    },
    {
      id: 1576,
      code: 'U+1F1EF U+1F1F2',
      emoji: '🇯🇲',
      name: 'flag: Jamaica',
      nameUrl: 'flag-jamaica',
    },
    {
      id: 1577,
      code: 'U+1F1EF U+1F1F4',
      emoji: '🇯🇴',
      name: 'flag: Jordan',
      nameUrl: 'flag-jordan',
    },
    {
      id: 1578,
      code: 'U+1F1EF U+1F1F5',
      emoji: '🇯🇵',
      name: 'flag: Japan',
      nameUrl: 'flag-japan',
    },
    {
      id: 1579,
      code: 'U+1F1F0 U+1F1EA',
      emoji: '🇰🇪',
      name: 'flag: Kenya',
      nameUrl: 'flag-kenya',
    },
    {
      id: 1580,
      code: 'U+1F1F0 U+1F1EC',
      emoji: '🇰🇬',
      name: 'flag: Kyrgyzstan',
      nameUrl: 'flag-kyrgyzstan',
    },
    {
      id: 1581,
      code: 'U+1F1F0 U+1F1ED',
      emoji: '🇰🇭',
      name: 'flag: Cambodia',
      nameUrl: 'flag-cambodia',
    },
    {
      id: 1582,
      code: 'U+1F1F0 U+1F1EE',
      emoji: '🇰🇮',
      name: 'flag: Kiribati',
      nameUrl: 'flag-kiribati',
    },
    {
      id: 1583,
      code: 'U+1F1F0 U+1F1F2',
      emoji: '🇰🇲',
      name: 'flag: Comoros',
      nameUrl: 'flag-comoros',
    },
    {
      id: 1584,
      code: 'U+1F1F0 U+1F1F3',
      emoji: '🇰🇳',
      name: 'flag: St. Kitts &amp; Nevis',
      nameUrl: 'flag-st-kitts-&-nevis',
    },
    {
      id: 1585,
      code: 'U+1F1F0 U+1F1F5',
      emoji: '🇰🇵',
      name: 'flag: North Korea',
      nameUrl: 'flag-north-korea',
    },
    {
      id: 1586,
      code: 'U+1F1F0 U+1F1F7',
      emoji: '🇰🇷',
      name: 'flag: South Korea',
      nameUrl: 'flag-south-korea',
    },
    {
      id: 1587,
      code: 'U+1F1F0 U+1F1FC',
      emoji: '🇰🇼',
      name: 'flag: Kuwait',
      nameUrl: 'flag-kuwait',
    },
    {
      id: 1588,
      code: 'U+1F1F0 U+1F1FE',
      emoji: '🇰🇾',
      name: 'flag: Cayman Islands',
      nameUrl: 'flag-cayman-islands',
    },
    {
      id: 1589,
      code: 'U+1F1F0 U+1F1FF',
      emoji: '🇰🇿',
      name: 'flag: Kazakhstan',
      nameUrl: 'flag-kazakhstan',
    },
    {
      id: 1590,
      code: 'U+1F1F1 U+1F1E6',
      emoji: '🇱🇦',
      name: 'flag: Laos',
      nameUrl: 'flag-laos',
    },
    {
      id: 1591,
      code: 'U+1F1F1 U+1F1E7',
      emoji: '🇱🇧',
      name: 'flag: Lebanon',
      nameUrl: 'flag-lebanon',
    },
    {
      id: 1592,
      code: 'U+1F1F1 U+1F1E8',
      emoji: '🇱🇨',
      name: 'flag: St. Lucia',
      nameUrl: 'flag-st-lucia',
    },
    {
      id: 1593,
      code: 'U+1F1F1 U+1F1EE',
      emoji: '🇱🇮',
      name: 'flag: Liechtenstein',
      nameUrl: 'flag-liechtenstein',
    },
    {
      id: 1594,
      code: 'U+1F1F1 U+1F1F0',
      emoji: '🇱🇰',
      name: 'flag: Sri Lanka',
      nameUrl: 'flag-sri-lanka',
    },
    {
      id: 1595,
      code: 'U+1F1F1 U+1F1F7',
      emoji: '🇱🇷',
      name: 'flag: Liberia',
      nameUrl: 'flag-liberia',
    },
    {
      id: 1596,
      code: 'U+1F1F1 U+1F1F8',
      emoji: '🇱🇸',
      name: 'flag: Lesotho',
      nameUrl: 'flag-lesotho',
    },
    {
      id: 1597,
      code: 'U+1F1F1 U+1F1F9',
      emoji: '🇱🇹',
      name: 'flag: Lithuania',
      nameUrl: 'flag-lithuania',
    },
    {
      id: 1598,
      code: 'U+1F1F1 U+1F1FA',
      emoji: '🇱🇺',
      name: 'flag: Luxembourg',
      nameUrl: 'flag-luxembourg',
    },
    {
      id: 1599,
      code: 'U+1F1F1 U+1F1FB',
      emoji: '🇱🇻',
      name: 'flag: Latvia',
      nameUrl: 'flag-latvia',
    },
    {
      id: 1600,
      code: 'U+1F1F1 U+1F1FE',
      emoji: '🇱🇾',
      name: 'flag: Libya',
      nameUrl: 'flag-libya',
    },
    {
      id: 1601,
      code: 'U+1F1F2 U+1F1E6',
      emoji: '🇲🇦',
      name: 'flag: Morocco',
      nameUrl: 'flag-morocco',
    },
    {
      id: 1602,
      code: 'U+1F1F2 U+1F1E8',
      emoji: '🇲🇨',
      name: 'flag: Monaco',
      nameUrl: 'flag-monaco',
    },
    {
      id: 1603,
      code: 'U+1F1F2 U+1F1E9',
      emoji: '🇲🇩',
      name: 'flag: Moldova',
      nameUrl: 'flag-moldova',
    },
    {
      id: 1604,
      code: 'U+1F1F2 U+1F1EA',
      emoji: '🇲🇪',
      name: 'flag: Montenegro',
      nameUrl: 'flag-montenegro',
    },
    {
      id: 1605,
      code: 'U+1F1F2 U+1F1EB',
      emoji: '🇲🇫',
      name: 'flag: St. Martin',
      nameUrl: 'flag-st-martin',
    },
    {
      id: 1606,
      code: 'U+1F1F2 U+1F1EC',
      emoji: '🇲🇬',
      name: 'flag: Madagascar',
      nameUrl: 'flag-madagascar',
    },
    {
      id: 1607,
      code: 'U+1F1F2 U+1F1ED',
      emoji: '🇲🇭',
      name: 'flag: Marshall Islands',
      nameUrl: 'flag-marshall-islands',
    },
    {
      id: 1608,
      code: 'U+1F1F2 U+1F1F0',
      emoji: '🇲🇰',
      name: 'flag: North Macedonia',
      nameUrl: 'flag-north-macedonia',
    },
    {
      id: 1609,
      code: 'U+1F1F2 U+1F1F1',
      emoji: '🇲🇱',
      name: 'flag: Mali',
      nameUrl: 'flag-mali',
    },
    {
      id: 1610,
      code: 'U+1F1F2 U+1F1F2',
      emoji: '🇲🇲',
      name: 'flag: Myanmar (Burma)',
      nameUrl: 'flag-myanmar-burma',
    },
    {
      id: 1611,
      code: 'U+1F1F2 U+1F1F3',
      emoji: '🇲🇳',
      name: 'flag: Mongolia',
      nameUrl: 'flag-mongolia',
    },
    {
      id: 1612,
      code: 'U+1F1F2 U+1F1F4',
      emoji: '🇲🇴',
      name: 'flag: Macao SAR China',
      nameUrl: 'flag-macao-sar-china',
    },
    {
      id: 1613,
      code: 'U+1F1F2 U+1F1F5',
      emoji: '🇲🇵',
      name: 'flag: Northern Mariana Islands',
      nameUrl: 'flag-northern-mariana-islands',
    },
    {
      id: 1614,
      code: 'U+1F1F2 U+1F1F6',
      emoji: '🇲🇶',
      name: 'flag: Martinique',
      nameUrl: 'flag-martinique',
    },
    {
      id: 1615,
      code: 'U+1F1F2 U+1F1F7',
      emoji: '🇲🇷',
      name: 'flag: Mauritania',
      nameUrl: 'flag-mauritania',
    },
    {
      id: 1616,
      code: 'U+1F1F2 U+1F1F8',
      emoji: '🇲🇸',
      name: 'flag: Montserrat',
      nameUrl: 'flag-montserrat',
    },
    {
      id: 1617,
      code: 'U+1F1F2 U+1F1F9',
      emoji: '🇲🇹',
      name: 'flag: Malta',
      nameUrl: 'flag-malta',
    },
    {
      id: 1618,
      code: 'U+1F1F2 U+1F1FA',
      emoji: '🇲🇺',
      name: 'flag: Mauritius',
      nameUrl: 'flag-mauritius',
    },
    {
      id: 1619,
      code: 'U+1F1F2 U+1F1FB',
      emoji: '🇲🇻',
      name: 'flag: Maldives',
      nameUrl: 'flag-maldives',
    },
    {
      id: 1620,
      code: 'U+1F1F2 U+1F1FC',
      emoji: '🇲🇼',
      name: 'flag: Malawi',
      nameUrl: 'flag-malawi',
    },
    {
      id: 1621,
      code: 'U+1F1F2 U+1F1FD',
      emoji: '🇲🇽',
      name: 'flag: Mexico',
      nameUrl: 'flag-mexico',
    },
    {
      id: 1622,
      code: 'U+1F1F2 U+1F1FE',
      emoji: '🇲🇾',
      name: 'flag: Malaysia',
      nameUrl: 'flag-malaysia',
    },
    {
      id: 1623,
      code: 'U+1F1F2 U+1F1FF',
      emoji: '🇲🇿',
      name: 'flag: Mozambique',
      nameUrl: 'flag-mozambique',
    },
    {
      id: 1624,
      code: 'U+1F1F3 U+1F1E6',
      emoji: '🇳🇦',
      name: 'flag: Namibia',
      nameUrl: 'flag-namibia',
    },
    {
      id: 1625,
      code: 'U+1F1F3 U+1F1E8',
      emoji: '🇳🇨',
      name: 'flag: New Caledonia',
      nameUrl: 'flag-new-caledonia',
    },
    {
      id: 1626,
      code: 'U+1F1F3 U+1F1EA',
      emoji: '🇳🇪',
      name: 'flag: Niger',
      nameUrl: 'flag-niger',
    },
    {
      id: 1627,
      code: 'U+1F1F3 U+1F1EB',
      emoji: '🇳🇫',
      name: 'flag: Norfolk Island',
      nameUrl: 'flag-norfolk-island',
    },
    {
      id: 1628,
      code: 'U+1F1F3 U+1F1EC',
      emoji: '🇳🇬',
      name: 'flag: Nigeria',
      nameUrl: 'flag-nigeria',
    },
    {
      id: 1629,
      code: 'U+1F1F3 U+1F1EE',
      emoji: '🇳🇮',
      name: 'flag: Nicaragua',
      nameUrl: 'flag-nicaragua',
    },
    {
      id: 1630,
      code: 'U+1F1F3 U+1F1F1',
      emoji: '🇳🇱',
      name: 'flag: Netherlands',
      nameUrl: 'flag-netherlands',
    },
    {
      id: 1631,
      code: 'U+1F1F3 U+1F1F4',
      emoji: '🇳🇴',
      name: 'flag: Norway',
      nameUrl: 'flag-norway',
    },
    {
      id: 1632,
      code: 'U+1F1F3 U+1F1F5',
      emoji: '🇳🇵',
      name: 'flag: Nepal',
      nameUrl: 'flag-nepal',
    },
    {
      id: 1633,
      code: 'U+1F1F3 U+1F1F7',
      emoji: '🇳🇷',
      name: 'flag: Nauru',
      nameUrl: 'flag-nauru',
    },
    {
      id: 1634,
      code: 'U+1F1F3 U+1F1FA',
      emoji: '🇳🇺',
      name: 'flag: Niue',
      nameUrl: 'flag-niue',
    },
    {
      id: 1635,
      code: 'U+1F1F3 U+1F1FF',
      emoji: '🇳🇿',
      name: 'flag: New Zealand',
      nameUrl: 'flag-new-zealand',
    },
    {
      id: 1636,
      code: 'U+1F1F4 U+1F1F2',
      emoji: '🇴🇲',
      name: 'flag: Oman',
      nameUrl: 'flag-oman',
    },
    {
      id: 1637,
      code: 'U+1F1F5 U+1F1E6',
      emoji: '🇵🇦',
      name: 'flag: Panama',
      nameUrl: 'flag-panama',
    },
    {
      id: 1638,
      code: 'U+1F1F5 U+1F1EA',
      emoji: '🇵🇪',
      name: 'flag: Peru',
      nameUrl: 'flag-peru',
    },
    {
      id: 1639,
      code: 'U+1F1F5 U+1F1EB',
      emoji: '🇵🇫',
      name: 'flag: French Polynesia',
      nameUrl: 'flag-french-polynesia',
    },
    {
      id: 1640,
      code: 'U+1F1F5 U+1F1EC',
      emoji: '🇵🇬',
      name: 'flag: Papua New Guinea',
      nameUrl: 'flag-papua-new-guinea',
    },
    {
      id: 1641,
      code: 'U+1F1F5 U+1F1ED',
      emoji: '🇵🇭',
      name: 'flag: Philippines',
      nameUrl: 'flag-philippines',
    },
    {
      id: 1642,
      code: 'U+1F1F5 U+1F1F0',
      emoji: '🇵🇰',
      name: 'flag: Pakistan',
      nameUrl: 'flag-pakistan',
    },
    {
      id: 1643,
      code: 'U+1F1F5 U+1F1F1',
      emoji: '🇵🇱',
      name: 'flag: Poland',
      nameUrl: 'flag-poland',
    },
    {
      id: 1644,
      code: 'U+1F1F5 U+1F1F2',
      emoji: '🇵🇲',
      name: 'flag: St. Pierre &amp; Miquelon',
      nameUrl: 'flag-st-pierre-&-miquelon',
    },
    {
      id: 1645,
      code: 'U+1F1F5 U+1F1F3',
      emoji: '🇵🇳',
      name: 'flag: Pitcairn Islands',
      nameUrl: 'flag-pitcairn-islands',
    },
    {
      id: 1646,
      code: 'U+1F1F5 U+1F1F7',
      emoji: '🇵🇷',
      name: 'flag: Puerto Rico',
      nameUrl: 'flag-puerto-rico',
    },
    {
      id: 1647,
      code: 'U+1F1F5 U+1F1F8',
      emoji: '🇵🇸',
      name: 'flag: Palestinian Territories',
      nameUrl: 'flag-palestinian-territories',
    },
    {
      id: 1648,
      code: 'U+1F1F5 U+1F1F9',
      emoji: '🇵🇹',
      name: 'flag: Portugal',
      nameUrl: 'flag-portugal',
    },
    {
      id: 1649,
      code: 'U+1F1F5 U+1F1FC',
      emoji: '🇵🇼',
      name: 'flag: Palau',
      nameUrl: 'flag-palau',
    },
    {
      id: 1650,
      code: 'U+1F1F5 U+1F1FE',
      emoji: '🇵🇾',
      name: 'flag: Paraguay',
      nameUrl: 'flag-paraguay',
    },
    {
      id: 1651,
      code: 'U+1F1F6 U+1F1E6',
      emoji: '🇶🇦',
      name: 'flag: Qatar',
      nameUrl: 'flag-qatar',
    },
    {
      id: 1652,
      code: 'U+1F1F7 U+1F1EA',
      emoji: '🇷🇪',
      name: 'flag: Réunion',
      nameUrl: 'flag-reunion',
    },
    {
      id: 1653,
      code: 'U+1F1F7 U+1F1F4',
      emoji: '🇷🇴',
      name: 'flag: Romania',
      nameUrl: 'flag-romania',
    },
    {
      id: 1654,
      code: 'U+1F1F7 U+1F1F8',
      emoji: '🇷🇸',
      name: 'flag: Serbia',
      nameUrl: 'flag-serbia',
    },
    {
      id: 1655,
      code: 'U+1F1F7 U+1F1FA',
      emoji: '🇷🇺',
      name: 'flag: Russia',
      nameUrl: 'flag-russia',
    },
    {
      id: 1656,
      code: 'U+1F1F7 U+1F1FC',
      emoji: '🇷🇼',
      name: 'flag: Rwanda',
      nameUrl: 'flag-rwanda',
    },
    {
      id: 1657,
      code: 'U+1F1F8 U+1F1E6',
      emoji: '🇸🇦',
      name: 'flag: Saudi Arabia',
      nameUrl: 'flag-saudi-arabia',
    },
    {
      id: 1658,
      code: 'U+1F1F8 U+1F1E7',
      emoji: '🇸🇧',
      name: 'flag: Solomon Islands',
      nameUrl: 'flag-solomon-islands',
    },
    {
      id: 1659,
      code: 'U+1F1F8 U+1F1E8',
      emoji: '🇸🇨',
      name: 'flag: Seychelles',
      nameUrl: 'flag-seychelles',
    },
    {
      id: 1660,
      code: 'U+1F1F8 U+1F1E9',
      emoji: '🇸🇩',
      name: 'flag: Sudan',
      nameUrl: 'flag-sudan',
    },
    {
      id: 1661,
      code: 'U+1F1F8 U+1F1EA',
      emoji: '🇸🇪',
      name: 'flag: Sweden',
      nameUrl: 'flag-sweden',
    },
    {
      id: 1662,
      code: 'U+1F1F8 U+1F1EC',
      emoji: '🇸🇬',
      name: 'flag: Singapore',
      nameUrl: 'flag-singapore',
    },
    {
      id: 1663,
      code: 'U+1F1F8 U+1F1ED',
      emoji: '🇸🇭',
      name: 'flag: St. Helena',
      nameUrl: 'flag-st-helena',
    },
    {
      id: 1664,
      code: 'U+1F1F8 U+1F1EE',
      emoji: '🇸🇮',
      name: 'flag: Slovenia',
      nameUrl: 'flag-slovenia',
    },
    {
      id: 1665,
      code: 'U+1F1F8 U+1F1EF',
      emoji: '🇸🇯',
      name: 'flag: Svalbard &amp; Jan Mayen',
      nameUrl: 'flag-svalbard-&-jan-mayen',
    },
    {
      id: 1666,
      code: 'U+1F1F8 U+1F1F0',
      emoji: '🇸🇰',
      name: 'flag: Slovakia',
      nameUrl: 'flag-slovakia',
    },
    {
      id: 1667,
      code: 'U+1F1F8 U+1F1F1',
      emoji: '🇸🇱',
      name: 'flag: Sierra Leone',
      nameUrl: 'flag-sierra-leone',
    },
    {
      id: 1668,
      code: 'U+1F1F8 U+1F1F2',
      emoji: '🇸🇲',
      name: 'flag: San Marino',
      nameUrl: 'flag-san-marino',
    },
    {
      id: 1669,
      code: 'U+1F1F8 U+1F1F3',
      emoji: '🇸🇳',
      name: 'flag: Senegal',
      nameUrl: 'flag-senegal',
    },
    {
      id: 1670,
      code: 'U+1F1F8 U+1F1F4',
      emoji: '🇸🇴',
      name: 'flag: Somalia',
      nameUrl: 'flag-somalia',
    },
    {
      id: 1671,
      code: 'U+1F1F8 U+1F1F7',
      emoji: '🇸🇷',
      name: 'flag: Suriname',
      nameUrl: 'flag-suriname',
    },
    {
      id: 1672,
      code: 'U+1F1F8 U+1F1F8',
      emoji: '🇸🇸',
      name: 'flag: South Sudan',
      nameUrl: 'flag-south-sudan',
    },
    {
      id: 1673,
      code: 'U+1F1F8 U+1F1F9',
      emoji: '🇸🇹',
      name: 'flag: São Tomé &amp; Príncipe',
      nameUrl: 'flag-sao-tome-&-principe',
    },
    {
      id: 1674,
      code: 'U+1F1F8 U+1F1FB',
      emoji: '🇸🇻',
      name: 'flag: El Salvador',
      nameUrl: 'flag-el-salvador',
    },
    {
      id: 1675,
      code: 'U+1F1F8 U+1F1FD',
      emoji: '🇸🇽',
      name: 'flag: Sint Maarten',
      nameUrl: 'flag-sint-maarten',
    },
    {
      id: 1676,
      code: 'U+1F1F8 U+1F1FE',
      emoji: '🇸🇾',
      name: 'flag: Syria',
      nameUrl: 'flag-syria',
    },
    {
      id: 1677,
      code: 'U+1F1F8 U+1F1FF',
      emoji: '🇸🇿',
      name: 'flag: Eswatini',
      nameUrl: 'flag-eswatini',
    },
    {
      id: 1678,
      code: 'U+1F1F9 U+1F1E6',
      emoji: '🇹🇦',
      name: 'flag: Tristan da Cunha',
      nameUrl: 'flag-tristan-da-cunha',
    },
    {
      id: 1679,
      code: 'U+1F1F9 U+1F1E8',
      emoji: '🇹🇨',
      name: 'flag: Turks &amp; Caicos Islands',
      nameUrl: 'flag-turks-&-caicos-islands',
    },
    {
      id: 1680,
      code: 'U+1F1F9 U+1F1E9',
      emoji: '🇹🇩',
      name: 'flag: Chad',
      nameUrl: 'flag-chad',
    },
    {
      id: 1681,
      code: 'U+1F1F9 U+1F1EB',
      emoji: '🇹🇫',
      name: 'flag: French Southern Territories',
      nameUrl: 'flag-french-southern-territories',
    },
    {
      id: 1682,
      code: 'U+1F1F9 U+1F1EC',
      emoji: '🇹🇬',
      name: 'flag: Togo',
      nameUrl: 'flag-togo',
    },
    {
      id: 1683,
      code: 'U+1F1F9 U+1F1ED',
      emoji: '🇹🇭',
      name: 'flag: Thailand',
      nameUrl: 'flag-thailand',
    },
    {
      id: 1684,
      code: 'U+1F1F9 U+1F1EF',
      emoji: '🇹🇯',
      name: 'flag: Tajikistan',
      nameUrl: 'flag-tajikistan',
    },
    {
      id: 1685,
      code: 'U+1F1F9 U+1F1F0',
      emoji: '🇹🇰',
      name: 'flag: Tokelau',
      nameUrl: 'flag-tokelau',
    },
    {
      id: 1686,
      code: 'U+1F1F9 U+1F1F1',
      emoji: '🇹🇱',
      name: 'flag: Timor-Leste',
      nameUrl: 'flag-timor-leste',
    },
    {
      id: 1687,
      code: 'U+1F1F9 U+1F1F2',
      emoji: '🇹🇲',
      name: 'flag: Turkmenistan',
      nameUrl: 'flag-turkmenistan',
    },
    {
      id: 1688,
      code: 'U+1F1F9 U+1F1F3',
      emoji: '🇹🇳',
      name: 'flag: Tunisia',
      nameUrl: 'flag-tunisia',
    },
    {
      id: 1689,
      code: 'U+1F1F9 U+1F1F4',
      emoji: '🇹🇴',
      name: 'flag: Tonga',
      nameUrl: 'flag-tonga',
    },
    {
      id: 1690,
      code: 'U+1F1F9 U+1F1F7',
      emoji: '🇹🇷',
      name: 'flag: Turkey',
      nameUrl: 'flag-turkey',
    },
    {
      id: 1691,
      code: 'U+1F1F9 U+1F1F9',
      emoji: '🇹🇹',
      name: 'flag: Trinidad &amp; Tobago',
      nameUrl: 'flag-trinidad-&-tobago',
    },
    {
      id: 1692,
      code: 'U+1F1F9 U+1F1FB',
      emoji: '🇹🇻',
      name: 'flag: Tuvalu',
      nameUrl: 'flag-tuvalu',
    },
    {
      id: 1693,
      code: 'U+1F1F9 U+1F1FC',
      emoji: '🇹🇼',
      name: 'flag: Taiwan',
      nameUrl: 'flag-taiwan',
    },
    {
      id: 1694,
      code: 'U+1F1F9 U+1F1FF',
      emoji: '🇹🇿',
      name: 'flag: Tanzania',
      nameUrl: 'flag-tanzania',
    },
    {
      id: 1695,
      code: 'U+1F1FA U+1F1E6',
      emoji: '🇺🇦',
      name: 'flag: Ukraine',
      nameUrl: 'flag-ukraine',
    },
    {
      id: 1696,
      code: 'U+1F1FA U+1F1EC',
      emoji: '🇺🇬',
      name: 'flag: Uganda',
      nameUrl: 'flag-uganda',
    },
    {
      id: 1697,
      code: 'U+1F1FA U+1F1F2',
      emoji: '🇺🇲',
      name: 'flag: U.S. Outlying Islands',
      nameUrl: 'flag-usa-outlying-islands',
    },
    {
      id: 1698,
      code: 'U+1F1FA U+1F1F3',
      emoji: '🇺🇳',
      name: 'flag: United Nations',
      nameUrl: 'flag-united-nations',
    },
    {
      id: 1699,
      code: 'U+1F1FA U+1F1F8',
      emoji: '🇺🇸',
      name: 'flag: United States',
      nameUrl: 'flag-united-states',
    },
    {
      id: 1700,
      code: 'U+1F1FA U+1F1FE',
      emoji: '🇺🇾',
      name: 'flag: Uruguay',
      nameUrl: 'flag-uruguay',
    },
    {
      id: 1701,
      code: 'U+1F1FA U+1F1FF',
      emoji: '🇺🇿',
      name: 'flag: Uzbekistan',
      nameUrl: 'flag-uzbekistan',
    },
    {
      id: 1702,
      code: 'U+1F1FB U+1F1E6',
      emoji: '🇻🇦',
      name: 'flag: Vatican City',
      nameUrl: 'flag-vatican-city',
    },
    {
      id: 1703,
      code: 'U+1F1FB U+1F1E8',
      emoji: '🇻🇨',
      name: 'flag: St. Vincent &amp; Grenadines',
      nameUrl: 'flag-st-vincent-&-grenadines',
    },
    {
      id: 1704,
      code: 'U+1F1FB U+1F1EA',
      emoji: '🇻🇪',
      name: 'flag: Venezuela',
      nameUrl: 'flag-venezuela',
    },
    {
      id: 1705,
      code: 'U+1F1FB U+1F1EC',
      emoji: '🇻🇬',
      name: 'flag: British Virgin Islands',
      nameUrl: 'flag-british-virgin-islands',
    },
    {
      id: 1706,
      code: 'U+1F1FB U+1F1EE',
      emoji: '🇻🇮',
      name: 'flag: U.S. Virgin Islands',
      nameUrl: 'flag-usa-virgin-islands',
    },
    {
      id: 1707,
      code: 'U+1F1FB U+1F1F3',
      emoji: '🇻🇳',
      name: 'flag: Vietnam',
      nameUrl: 'flag-vietnam',
    },
    {
      id: 1708,
      code: 'U+1F1FB U+1F1FA',
      emoji: '🇻🇺',
      name: 'flag: Vanuatu',
      nameUrl: 'flag-vanuatu',
    },
    {
      id: 1709,
      code: 'U+1F1FC U+1F1EB',
      emoji: '🇼🇫',
      name: 'flag: Wallis &amp; Futuna',
      nameUrl: 'flag-wallis-&-futuna',
    },
    {
      id: 1710,
      code: 'U+1F1FC U+1F1F8',
      emoji: '🇼🇸',
      name: 'flag: Samoa',
      nameUrl: 'flag-samoa',
    },
    {
      id: 1711,
      code: 'U+1F1FD U+1F1F0',
      emoji: '🇽🇰',
      name: 'flag: Kosovo',
      nameUrl: 'flag-kosovo',
    },
    {
      id: 1712,
      code: 'U+1F1FE U+1F1EA',
      emoji: '🇾🇪',
      name: 'flag: Yemen',
      nameUrl: 'flag-yemen',
    },
    {
      id: 1713,
      code: 'U+1F1FE U+1F1F9',
      emoji: '🇾🇹',
      name: 'flag: Mayotte',
      nameUrl: 'flag-mayotte',
    },
    {
      id: 1714,
      code: 'U+1F1FF U+1F1E6',
      emoji: '🇿🇦',
      name: 'flag: South Africa',
      nameUrl: 'flag-south-africa',
    },
    {
      id: 1715,
      code: 'U+1F1FF U+1F1F2',
      emoji: '🇿🇲',
      name: 'flag: Zambia',
      nameUrl: 'flag-zambia',
    },
    {
      id: 1716,
      code: 'U+1F1FF U+1F1FC',
      emoji: '🇿🇼',
      name: 'flag: Zimbabwe',
      nameUrl: 'flag-zimbabwe',
    },
    {
      id: 1717,
      code: 'U+1F3F4 U+E0067 U+E0062 U+E0065 U+E006E U+E0067 U+E007F',
      emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿',
      name: 'flag: England',
      nameUrl: 'flag-england',
    },
    {
      id: 1718,
      code: 'U+1F3F4 U+E0067 U+E0062 U+E0073 U+E0063 U+E0074 U+E007F',
      emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿',
      name: 'flag: Scotland',
      nameUrl: 'flag-scotland',
    },
    {
      id: 1719,
      code: 'U+1F3F4 U+E0067 U+E0062 U+E0077 U+E006C U+E0073 U+E007F',
      emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿',
      name: 'flag: Wales',
      nameUrl: 'flag-wales',
    },
  ]
  
  export default emojis;