import React from 'react'
import './index.css'
import Routes from './Routes'
import 'bootstrap/dist/css/bootstrap.min.css';
import Helmet from 'react-helmet'
import { createRoot } from 'react-dom/client';
import api from "./service/api";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <>
    <Helmet>
      <title>Painel Administrativo</title>
      <meta name="description" content="Painel Administrativo" />
      <meta property="og:title" content="Painel Administrativo" />
      <meta property="og:description" content="Painel Administrativo | Islagás" />

      <meta property="og:image" content="http://painel.atlantisagencia.com.br:21004/static/media/Prancheta_7.b38765c7e45aa758b8f0.png" />

    </Helmet>
    <Routes />
  </>
)

