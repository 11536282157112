import React from 'react'
import './training.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import TableTraining from '../TableTraining'
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Form from 'react-bootstrap/Form';
import Backdrop from '@mui/material/Backdrop';
import axios from 'axios';

const Training = () => {
    const [show, setShow] = React.useState(false);
    const [providers, setProviders] = React.useState([])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }

        axios
            .post('https://api.clubedorevendedordegas.com.br/CadastrarCurso', {
                name: data.cursoName,
                image: data.thumbCurso,
                thumbnail: `https://api.clubedorevendedordegas.com.br/files/thumbs/${data.thumbCurso.name}`
            }, headers)


        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    function mostraImagem(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("artImage");

            reader.onload = function (e) {
                imagem.src = e.target.result
            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo Curso'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Adicionar Curso</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Nome do curso*</InputLabel>
                                <input
                                    required
                                    id="categoryProduct"
                                    name="cursoName"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="categoryProduct">Thumbnail do curso*</InputLabel>
                                <img className="imgInput2" id='artImage' />
                                <input type="file" name="thumbCurso" style={{ marginTop: 5 }} onChange={(e) => {
                                    mostraImagem(e.target)
                                }} />
                            </div>
                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Criar curso
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }
    const [open, setOpen] = React.useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h3>Treinamentos</h3>
                    </div>
                    <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                        <Link to="/cadastrar-treinamento" className="me-2 button">
                            {'Nova Aula'}
                        </Link>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableTraining />
                </Card>
            </div >
        </>
    )
}

export default Training