import React from 'react'
import { useState, createContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './pages/Header'
import Dashboard from './pages/Dashboard/index'
import Login from './pages/Login'
import Footer from './pages/Footer'
import Menu from './pages/Menu'
import Collaborators from './pages/Collaborators'
import Profile from './pages/Profile'
import UpdatePassword from './pages/UpdatePassword'
import './default.css'
import Accordion from './contexts/accordion'
import CreateArt from './pages/CreateArt';
import EditArt from './pages/EditArt';
import Arts from './pages/Arts';
import Addons from './pages/Addons';
import PageNotFound from './pages/PageNotFound';
import ResalePerfil from './pages/ResalePerfil';
import Invoices from './pages/Invoices';
import Request from './pages/Requests'
import RequestSpecificy from './pages/RequestSpecificy'
import RequestPerfil from './pages/RequestPerfil'
import Training from './pages/Training';
import CreateTraining from './pages/CreateTraining';
import EditTraining from './pages/EditTraining';

function Rotas() {
    const [accordion, setAccordion] = useState(true);
    const value = { accordion, setAccordion }
    const storage = sessionStorage.getItem('token');
    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    if (storage !== 'dXN1YXJpb2F1dG9yaXphZG9hY2Vzc2FybmFlcnA=') {
        return (
            <Login />
        )
    } else {
        return (
            <Router>
                <div className='BaseContainer'>
                    <Accordion.Provider value={value} >
                        {/* <Menu /> */}
                        <div className='CenterContainer'>
                            <Header />
                            <Routes>
                                <Route exact element={<Dashboard />} path='/' />
                                <Route exact element={<Dashboard />} path='/dashboard' />
                                <Route exact element={<ResalePerfil />} path='/perfil-revenda' />
                                <Route exact element={<Invoices />} path='/faturas' />
                                {typeCollaborator == 'superadmin' ? (
                                    <Route exact element={<Collaborators />} path='/colaboradores' />
                                ) : (<Route exact element={<PageNotFound />} path='*' />)}
                                <Route exact element={<Profile />} path='/perfil' />
                                <Route exact element={<UpdatePassword />} path='/alterar-senha' />
                                {typeCollaborator == 'superadmin' || typeCollaborator == 'artmanager' ? (
                                    <>
                                        <Route exact element={<CreateArt />} path='/cadastrar-arte' />
                                        <Route exact element={<EditArt />} path='/editar-arte' />
                                        <Route exact element={<Arts />} path='/artes' />
                                    </>
                                ) : (<Route exact element={<PageNotFound />} path='*' />)}
                                {typeCollaborator == 'superadmin' || typeCollaborator == 'addonmanager' ? (
                                    <Route exact element={<Addons />} path='/addons' />
                                ) : (<Route exact element={<PageNotFound />} path='*' />)}
                                <Route exact element={<CreateTraining />} path='/cadastrar-treinamento' />
                                <Route exact element={<EditTraining />} path='/editar-treinamento' />
                                <Route exact element={<Training />} path='/treinamentos' />
                                <Route exact element={<PageNotFound />} path='*' />
                                <Route exact element={<Request />} path="/solicitacoes" />
                                <Route exact element={<RequestSpecificy />} path="/solicitacao/:type" />
                                <Route exact element={<RequestPerfil />} path="/perfil-solicitacao/:id" />
                            </Routes>
                            <Footer />
                        </div>
                    </Accordion.Provider>
                </div>
            </Router>
        )
    }
}

export default Rotas