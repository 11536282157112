import React from 'react';
import { useState, useEffect } from 'react';
import './dashboard.css'
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';

import { Card } from "react-bootstrap";

import MonthlyBarChart from './MonthlyBarChart';
import AnalyticEcommerce from '../../components/cards/statistics/AnalyticEcommerce';
import MainCard from '../../components/MainCard';
import api from '../../service/api';

import {
    AppCurrentVisits,
} from '../../sections/@dashboard/app';

import { useTheme } from '@mui/material/styles';
import { ConsoleSqlOutlined } from '@ant-design/icons';
import InputLabel from '@mui/material/InputLabel';
import CurrencyInput from 'react-currency-input-field';
import { set } from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import icon from '../../image/truck.png';
import InputMask from 'react-input-mask';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import FormControl from '@mui/material/FormControl';
import { CustomProvider, DateRangePicker } from 'rsuite';
import pt_BR from 'rsuite/locales/pt_BR'
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import LineChart from './LineChart';
import PierChart from './PierChart'

const Dashboard = () => {
    const theme = useTheme();

    const [ordered, setOrdered] = useState([]);
    const [saleChanell, setSaleChanell] = useState([]);
    const [driver, setDriver] = useState([]);
    const [cBusiness, setCBusiness] = useState([]);
    const [CategoryBusiness, setCategoryBusiness] = useState([]);
    const [payment, setPayment] = useState([]);
    const [sector, setSector] = useState([]);
    const [products, setProducts] = useState([]);
    const [filterDate, setFilterDate] = useState('');
    const [filterCategory, setFilterCategory] = useState('');

    let totalOldMonth = 0;
    let totalMonth = 0
    let totalToday = 0;
    let totalYesterday = 0;
    var porc = Number(totalMonth) / Number(totalOldMonth) * 100
    var porcDay = Number(totalToday) / Number(totalYesterday) * 100

    if (isNaN(porcDay)) {
        porcDay = 0;
    }

    if (isNaN(porc)) {
        porc = 0;
    }

    return (
        <div className='orderedContainer'>
            <div className="titleAndButton">
                <div>
                    <Typography variant="h5">Dashboard</Typography>
                </div>
            </div>
            <Card style={{ width: '100%', height: '250vh', borderRadius: '10px', padding: '10px' }}>
                <div className='divControllReserve'>
                    <div className='divClientReserve'>
                        <span className='titleDivReserve'>Clientes</span>
                        <span className='resultDivReserve'>1500</span>
                    </div>
                    <div className='divMonthTotalReserve'>
                        <span className='titleDivReserve'>Receita mensal</span>
                        <CurrencyInput
                            prefix="R$"
                            className='resultDivReserve'
                            value={totalMonth}
                            decimalsLimit={2}
                            decimalScale={2}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            disabled
                        />
                    </div>
                    <Grid item xs={10} sm={8} md={4} lg={5} className='filterDashboard'>
                        <FormControl sx={{ m: 1, minWidth: 320 }}>
                            <InputLabel id="demo-simple-select-label">Filtrar por últimos dias</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filterDate}
                                label="Filtrar por últimos dias"
                                onChange={e => {
                                    setFilterDate(e.target.value)
                                }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                            <FormHelperText id="helperFilterDate">Filtre por datas</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={8} md={4} lg={5} className='filterDashboard'>
                        <FormControl sx={{ m: 1, minWidth: 320 }}>
                            <InputLabel id="demo-simple-select-label">Categorias"</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filterCategory}
                                label="Categorias"
                                onChange={e => {
                                    setFilterCategory(e.target.value)
                                }}
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                            <FormHelperText id="helperFilterDate">Filtre por categoria</FormHelperText>
                        </FormControl>
                    </Grid>
                </div>
                <div className='divGridReserve'>
                    <Grid container rowSpacing={2} columnSpacing={2.75}>
                        <Grid item xs={12} md={5} lg={3}>
                            <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic'>
                                <Typography variant="span" className='titleSpanGrafic'>Módulos</Typography>
                            </Grid>
                            <LineChart />
                        </Grid>
                        <Grid item xs={12} md={5} lg={3}>
                            <Grid container alignItems="center" justifyContent="space-between" className='titleDivGrafic'>
                                <Typography variant="span" className='titleSpanGrafic'>Módulos</Typography>
                            </Grid>
                            <PierChart />
                        </Grid>
                    </Grid >
                </div>
            </Card>
        </div >
    );
}

export default Dashboard