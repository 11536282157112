import axios from "axios";
import { useEffect } from "react";

var querystring = require('querystring');

const token = axios
  .post("https://auth.nuvemfiscal.com.br/oauth/token",
    querystring.stringify({
      client_id: "8AsP9wr1N5YF27yCTz8G",
      client_secret: "ag5CnYdOHMa1bDTmOeiRPqVmFtTfICDSgRxCzba6",
      grant_type: 'client_credentials',
      scope: 'nfe nfce nfse empresa cep cnpj cte mdfe'
    }), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  .then((response) => {
    return response.data.access_token;
  })

export default token;