import { useEffect, useState } from "react";
import React from 'react'
import './requests.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import redes_sociais from '../../image/redes-sociais.png'
import google_meu_negocio from '../../image/google-meu-negocio.png'
import app_cliente from '../../image/app-cliente.png'
import { Link } from "react-router-dom";
import { GoRocket } from "react-icons/go";
import { PiMegaphoneDuotone } from "react-icons/pi";

const Requests = () => {
    const [duvidas, setDuvidas] = useState([]);
    const [suporte, setSuporte] = useState([]);
    const [informacoes, setInformacoes] = useState([]);
    const [raioX, setRaioX] = useState([]);
    const [google, setGoogle] = useState([]);
    const [appGasClick, setAppGasClick] = useState([]);
    const [adsGoogle, setAdsGoogle] = useState([]);
    const [beta, setBeta] = useState([])

    useEffect(() => {
        var arrayDuvidas = []
        var arraySuporte = []
        var arrayInformcacoes = []
        var arrayRaioX = []
        var arrayGoogle = []
        var arrayAppGasClick = []
        var arrayGoogleAds = []
        var arrayBeta = [];
        api
            .get('/TodasSolicitacoes')
            .then(response => {
                response.data.map(key => {
                    if (key['request'] == 'duvidas plataforma') {
                        arrayDuvidas.push(key)
                    } else if (key['request'] == 'suporte') {
                        arraySuporte.push(key)
                    } else if (key['request'] == 'informacoes') {
                        arrayInformcacoes.push(key)
                    } else if (key['request'] == 'raio-x') {
                        arrayRaioX.push(key)
                    } else if (key['request'] == 'google') {
                        arrayGoogle.push(key)
                    } else if (key['request'] == 'app gas click') {
                        arrayAppGasClick.push(key)
                    } else if (key['request'] == 'meu google ads') {
                        arrayGoogleAds.push(key)
                    } else if (key['request'] == 'Aplicativo da revenda' || key['request'] == 'Whatsapp') {
                        arrayBeta.push(key);
                    }
                })
                setDuvidas(arrayDuvidas)
                setSuporte(arraySuporte)
                setInformacoes(arrayInformcacoes)
                setRaioX(arrayRaioX)
                setGoogle(arrayGoogle)
                setAppGasClick(arrayAppGasClick)
                setAdsGoogle(arrayGoogleAds)
                setBeta(arrayBeta)
            })
    }, [])

    return (
        <>
            <div className='orderedContainer'>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="containerRequestChamados">
                        <div className="divSpanRequest">
                            <span className="spanRequest">Solicitações</span>
                        </div>
                        <hr />
                        <div className="containerDivRequestChamados">
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <img src={redes_sociais} className='imgModule' />
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Raio x das Redes Sociais</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/raio-x">{raioX.length} {raioX.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <img src={google_meu_negocio} className='imgModule' />
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Google meu negócio</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/google">{google.length} {google.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <PiMegaphoneDuotone className="iconReseller" />
                                    {/* <img src={google_meu_negocio} className='imgModule' /> */}
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Google ADS</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/google">{adsGoogle.length} {adsGoogle.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <img src={app_cliente} className='imgModule' />
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Aplicativo Gás no Clique</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/app-gas-click">{appGasClick.length} {appGasClick.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <GoRocket className="iconReseller" />
                                    {/* <img src={app_cliente} className='imgModule' /> */}
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Solicitação de Beta</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/app-gas-click">{beta.length} {beta.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerRequestChamados">
                        <div className="divSpanRequest">
                            <span className="spanRequest">Chamados</span>
                        </div>
                        <hr />
                        <div className="containerDivRequestChamados">
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <img src={redes_sociais} className='imgModule' />
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Suporte</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/suporte">{suporte.length} {suporte.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <img src={google_meu_negocio} className='imgModule' />
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Dúvidas</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/duvidas">{duvidas.length} {duvidas.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                            <div className="containerDivBtnRequest">
                                <div className="divRequestChamados">
                                    <img src={app_cliente} className='imgModule' />
                                    <div className='divSpanNameModule'>
                                        <span className='spanNameModule'>Informações</span>
                                    </div>
                                </div>
                                <div className="btnRquestChamado">
                                    <Link className="linkRequestChamado" to="/solicitacao/informacoes">{informacoes.length} {informacoes.length == 1 ? "solicitação" : "solicitações"}</Link >
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div >
        </>
    )
}

export default Requests