import { useState, useEffect } from "react";
import React from 'react'
import './createTraining.css';
import { Card, Button } from "react-bootstrap";
import api from "../../service/api";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ColorPicker from 'react-pick-color';
import { AiFillLeftCircle } from "react-icons/ai"
import ultraLogo from '../../image/ultragaz-logo-0.png'
import liguiLogo from '../../image/liquigas-logo-00.png'
import copaLogo from '../../image/copagaz-logo-0.png'
import nacionalLogo from '../../image/nacional-gas-4096.png'
import superLogo from '../../image/supergasbras-logo-0.png'
import consiLogo from '../../image/consigaz.png'
import servLogo from '../../image/SERVGAS-DISTRIBUIDORA-DE-GAS-SA.webp'
import indeLogo from '../../image/revendaindependente.png'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import YouTube from "react-youtube"
import axios from "axios";

const CreateTraining = () => {

    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [categorySelected, setCategorySelected] = useState();
    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    const [imageSelected, setImageSelected] = useState(ultragraz)
    const [marcaSelected, setMarcaSelected] = useState('ultragaz');
    const [ultragraz, setUltragraz] = useState('');
    const [consigaz, setConsigaz] = useState('')
    const [copagaz, setCopagaz] = useState('')
    const [independente, setIndependente] = useState('')
    const [liquigas, setLiquigas] = useState('')
    const [nacionalGas, setNacionalGas] = useState('')
    const [ngc, setNgc] = useState('')
    const [servigas, setServigas] = useState('')
    const [supergasbras, setSupergasbras] = useState('')
    const [ultragrazFile, setUltragrazFile] = useState('');
    const [consigazFile, setConsigazFile] = useState('')
    const [copagazFile, setCopagazFile] = useState('')
    const [independenteFile, setIndependenteFile] = useState('')
    const [liquigasFile, setLiquigasFile] = useState('')
    const [nacionalGasFile, setNacionalGasFile] = useState('')
    const [ngcFile, setNgcFile] = useState('')
    const [servigasFile, setServigasFile] = useState('')
    const [supergasbrasFile, setSupergasbrasFile] = useState('')
    const [videoId, setVideoId] = useState('')

    const [videos, setVideos] = React.useState([])
    const [courses, setCourses] = React.useState([]);
    const [courseVideos, setCourseVideos] = React.useState([])

    React.useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosCursos')
            .then(response => {
                setCourses(response.data);
            })
    }, [])

    React.useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosCursosVideos')
            .then(response => {
                setCourseVideos(response.data);
            })
    }, [])

    React.useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosVideos')
            .then(response => {
                setVideos(response.data);
            })
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }

        let postComplete = data.descricaoVideo.split('\n');
        var arrayPost = [];
        postComplete.map(key => {
            postComplete.map(key => {
                if (key !== '') {
                    if (key !== ' ') {
                        if (key !== '  ') {
                            arrayPost.push(key);
                        }
                    }

                }
            })
        })

        let dados = {
            image: data.thumbVideo,
            url_video: data.urlVideo,
            title: data.nomeVideo,
            description: arrayPost.join(' <br /> '),
            url_thumbnail: `https://api.clubedorevendedordegas.com.br/files/thumbs/${data.thumbVideo.name}`,
        }

        axios.post("https://api.clubedorevendedordegas.com.br/CadastrarVideo", dados, headers)
            .then(response => {
                let id = response.data.id;
                axios.post("https://api.clubedorevendedordegas.com.br/CadastrarVideoCurso", {
                    course_id: data.cursoVideo,
                    video_id: id,
                    lesson: data.aulaVideo
                })
            });

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function mostraImagem(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("artImage");

            reader.onload = function (e) {
                imagem.src = e.target.result
            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    const opts = {
        height: '200px',
        width: '100%',
        playerVars: {
            autoplay: 0,
            enablejsapi: 1,
        },
    };

    const checkElapsedTime = (e) => {
        // setEventTest(e.target)
    };

    return (
        <>
            <Loading />
            <div className='orderedContainer'>
                <div className="titleAndButton">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Link to="/treinamentos" style={{ background: 'transparent', color: 'black', border: 'none', textDecoration: 'none' }}><AiFillLeftCircle style={{ fontSize: 26 }} /> Voltar </Link>
                        <h3 style={{ marginLeft: 10 }}>Cadastrar vídeo</h3>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '135vh', borderRadius: '10px', padding: '10px' }}>
                    <form className="input" onSubmit={submitForm} enctype="multipart/form-data" >
                        <div className="col2">
                            <div className="divInputsImages">
                                <span>Thumbnail</span>
                                <img src={imageSelected} className="imgInput2" id='artImage' />
                                <input type="file" name="thumbVideo" style={{ marginTop: 5 }} onChange={(e) => {
                                    mostraImagem(e.target)
                                }} />
                                <div style={{ marginTop: 10, width: '90%' }}>
                                    {videoId !== '' ? (
                                        <YouTube videoId={videoId} opts={opts} onReady={checkElapsedTime} className='youtubePlayer' />
                                    ) : ''}
                                    <TextField id="outlined-basic" label="Url vídeo" variant="outlined" className="inputDetails" name='urlVideo' onChange={e => {
                                        let url = e.target.value.split('https://www.youtube.com/watch?v=')
                                        setVideoId(url[1])
                                    }} />
                                </div>
                            </div>
                            <div className="divDetaisImage">
                                <TextField id="outlined-basic" label="Nome do Vídeo" variant="outlined" className="inputDetails" name='nomeVideo' />
                                <FormControl className="inputDetails">
                                    <InputLabel id="demo-simple-select-label">Curso</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={age}
                                        label="Categoria"
                                        name="cursoVideo"
                                    >
                                        {courses.map(key => {
                                            return (
                                                <MenuItem value={key['id']}>{key['name']}</MenuItem>
                                            )
                                        })}

                                    </Select>
                                </FormControl>
                                <TextField id="outlined-basic" label="Número da aula" variant="outlined" className="inputDetails" name='aulaVideo' />
                                <TextField
                                    id="outlined-multiline-static"
                                    className="inputDetails"
                                    label="Descrição"
                                    multiline
                                    rows={7}
                                    name='descricaoVideo'
                                // defaultValue="Default Value"
                                />
                                <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                    <button className="buttonSecundary" >
                                        Cadastrar vídeo
                                    </button>
                                </div>
                            </div>
                        </div>

                    </form>
                </Card>
            </div >
        </>
    )
}

export default CreateTraining