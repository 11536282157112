import { useEffect, useState } from "react";
import React from 'react'
import './resalePerfil.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableArts from '../TableArts'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import { useParams } from "react-router";
import { FaWhatsapp } from "react-icons/fa";
import { FaRegImages } from "react-icons/fa";
import { MdOutlineExposurePlus2 } from "react-icons/md";
import { TbDeviceMobileDollar } from "react-icons/tb";
import { TbDeviceMobilePin } from "react-icons/tb";
import { TbReportAnalytics } from "react-icons/tb";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaBullhorn } from "react-icons/fa";
import Paper from '@mui/material/Paper';
import MaterialTable from 'material-table';
import { Link } from "react-router-dom";
import axios from "axios";
import { accordionSummaryClasses } from "@mui/material";
import CurrencyInput from 'react-currency-input-field';

const ResalePerfil = () => {
    const [show, setShow] = useState(false);
    const [addons, setAddons] = useState([]);
    const [reselerName, setReselerName] = useState('');
    const [modules, setModules] = useState([])
    const [invoices, setInvoices] = useState([])
    const [imgReserve, setImgReserve] = useState('');
    const [renovacao, setRenovacao] = useState('Não possui fatura')
    const [renovacaoLengh, setRenovacaoLengh] = useState(0)

    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const id = sessionStorage.getItem('addons_id');

    useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosUsuariosRevenda/clube_api')
            .then(response => {
                console.log(response.data)
                // response.data.map(key => {
                //     if (key.id == id) {
                //         setAddons(key)
                //         // axios.get(`${key.url}Configuracoes`)
                //         //     .then(response => {
                //         //         setImgReserve(response.data[0].logoLogin)
                //         //     })
                //     }
                // })
            })
    }, [])

    useEffect(() => {
        api
            .get('/TodasFaturas')
            .then(response => {
                let datasTimestamp = [];
                var timestamp = new Date()
                var timestampSecond = timestamp.getTime() / 1000
                setInvoices(response.data)
                response.data.map(key => {
                    if (key.addon_id == id) {
                        datasTimestamp.push(Number(key.maturity))
                    }
                })
                var maisProximo = datasTimestamp.reduce(function (anterior, corrente) {
                    return (Math.abs(corrente - timestampSecond) < Math.abs(anterior - timestampSecond) ? corrente : anterior);
                });
                let dateFormated = new Date(Number(maisProximo) * 1000)
                const date1 = new Date(`${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear()}`);
                const date2 = new Date(`${dateFormated.getMonth() + 1}/${dateFormated.getDate()}/${dateFormated.getFullYear()}`);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if (diffDays >= 0) {
                    setRenovacao(' dia(as) para vencer')
                }
                setRenovacaoLengh(diffDays)
            })
    }, [])

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    const columns = [
        {
            title: "Data", field: "maturity", render: (rowData) => {
                let dateFormated = new Date(Number(rowData.maturity) * 1000)
                return (
                    <span>{`${dateFormated.getDate()}/${dateFormated.getMonth() + 1}/${dateFormated.getFullYear()}`}</span>
                )
            }
        },
        {
            title: "Valor", field: "value", render: (rowData) => {
                return (
                    <CurrencyInput
                        prefix="R$"
                        className='currentValuePerfilResale'
                        value={rowData.value}
                        decimalsLimit={2}
                        decimalScale={2}
                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                        disabled
                    />
                )
            }
        },
    ]

    return (
        <>
            <Loading />
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h3>Perfil da {reselerName}</h3>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <div className="containerReseler">
                        <div className="dataReseler">
                            <div className="containerImgReseler">
                                <img src={imgReserve} className="imgReserve" />
                            </div>
                            <div className="contenctReseler">
                                <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Revenda: </span> {addons.name}</span>
                                <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Nome: </span> {addons.name_create}</span>
                                <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>CPF: </span> {addons.cpf}</span>
                                <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>E-mail: </span> {addons.email}</span>
                                <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Telefone: </span> {addons.phone}</span>
                                <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>Endereço: </span> {addons.address}</span>
                                <span style={{ padding: 0 }}><span style={{ fontWeight: 'bold' }}>CNPJ:</span> {addons.cnpj}</span>
                            </div>
                            <div className="statusReseler">
                                <div className="divStatus" style={addons.status == 1 ? { backgroundColor: '#2D8D00' } : { backgroundColor: 'red' }}>
                                    {addons.status == 1 ? "Ativo" : "Inativo"}
                                </div>
                                <div>
                                    <span style={renovacaoLengh >= 10 ? { color: 'green' } : { color: 'red' }}>{renovacao == 'Não possui fatura' ? renovacao : renovacaoLengh + renovacao}</span>
                                </div>
                            </div>
                        </div>
                        <div className="tableInvoice">
                            <Paper sx={{ width: '50%', mb: 2 }}>
                                <MaterialTable
                                    title="Faturas"
                                    columns={columns}
                                    data={invoices}
                                    actions={[
                                        {
                                            icon: () => <Link to="/fatura" className='btnViewer'>Visualizar</Link>,
                                            tooltip: 'Fatura',
                                            onClick: (event, rowData) => {
                                                sessionStorage.setItem('invoice_id', rowData.id);
                                            }
                                        }
                                    ]}
                                    options={{
                                        actionsColumnIndex: -1,
                                        pageSize: 10,
                                        pageSizeOptions: false,
                                    }}
                                    localization={{
                                        pagination: {
                                            labelDisplayedRows: '{from}-{to} de {count}'
                                        },
                                        header: {
                                            actions: 'Ações'
                                        },
                                        body: {
                                            emptyDataSourceMessage: 'Não há registros a serem exibidos',
                                            filterRow: {
                                                filterTooltip: 'Filtro'
                                            }
                                        },
                                        toolbar: {
                                            searchTooltip: 'Pesquisar',
                                            searchPlaceholder: 'Pesquisar',
                                            exportTitle: 'Exportar',
                                            exportAriaLabel: 'Exportar',
                                            exportCSVName: "Exportar para CSV",
                                            exportPDFName: "Exportar para PDF",
                                            nRowsSelected: '{0} linha(s) selecionada(s)'
                                        }
                                    }}
                                />
                            </Paper>
                        </div>

                    </div>
                </Card>
            </div >
        </>
    )
}

export default ResalePerfil