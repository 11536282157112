import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableInvoices.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlinePlayCircle, AiOutlinePauseCircle } from 'react-icons/ai'
import { BsFillLockFill, BsFillUnlockFill, BsLockFill } from 'react-icons/bs';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { TextareaAutosize } from '@material-ui/core';
import { FcLike, FcDislike } from 'react-icons/fc';
import { PiPlayPause } from 'react-icons/pi';
import axios from "axios";
import Switch from '@mui/material/Switch';
import { FaWhatsapp } from "react-icons/fa";
import { FaRegImages } from "react-icons/fa";
import { MdOutlineExposurePlus2 } from "react-icons/md";
import { Link } from "react-router-dom";
import { TbDeviceMobileDollar } from "react-icons/tb";
import { TbDeviceMobilePin } from "react-icons/tb";
import { TbReportAnalytics } from "react-icons/tb";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaBullhorn } from "react-icons/fa";
import CurrencyInput from 'react-currency-input-field';
import { KeyTwoTone } from '@mui/icons-material';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cliente',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'endereco',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'telefone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function TableInvoices() {
  const [rows, setRows] = React.useState([])
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);
  const [categorys, setCategorys] = React.useState([]);
  const [subcategorys, setSubcategorys] = React.useState([]);
  const [situation, setSituation] = React.useState();
  const [checked, setChecked] = React.useState(true);
  const [status, setStatus] = React.useState('Ativo')
  const [addons, setAddons] = React.useState([])

  React.useEffect(() => {
    api.get('/TodasFaturas')
      .then(response => {
        setRows(response.data);
      })
  }, [])

  // React.useEffect(() => {
  //   api.get('/TodasAddons')
  //     .then(response => {
  //       setAddons(response.data)
  //     })
  // }, [])

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      name: data.nameAddonEdit,
      url: data.urlAddonEdit,
      addons: JSON.stringify([[{ addon: 'whatsapp', status: data.addon1Edit }], [{ addon: 'gerador de imagem', status: data.addon2Edit }], [{ addon: 'app cliente', status: data.addon3Edit }], [{ addon: 'app motorista/localizacao', status: data.addon4Edit }], [{ addon: 'relatorios', status: data.addon5Edit }], [{ addon: 'notas', status: data.addon6Edit }], [{ addon: 'marketing', status: data.addon7Edit }]])
    }

    api.put("/AtualizarAddons", dados)
      .then(response => {
        console.log(response)
      });

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {
    let statusClient = 0;

    if (situation == 1) {
      statusClient = 0;
    } else {
      statusClient = 1;
    }

    api.put('/AtualizarAddons', {
      id: idModal,
      status: statusClient
    })

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Addons cliente
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}></button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Detalhe dos addons do clients</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">URL Api</td>
                      <td>{key['url']} </td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Addons</td>
                      <td style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        {JSON.parse(key['addons']).map(key => {
                          return (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '40%', margin: 10 }}>
                              <span >{key[0].addon == 'whatsapp' ? 'Whatsapp' : key[0].addon == 'gerador de imagem' ? 'Gerador de Imagem' : key[0].addon == 'app cliente' ? 'APP Cliente' : key[0].addon == 'app motorista/localizacao' ? 'APP Motorista / Localização' : key[0].addon == 'relatorios' ? 'Relatórios' : key[0].addon == 'notas' ? 'Notas' : key[0].addon == 'marketing' ? 'Marketing' : ''}</span>
                              <span >{key[0].status == 1 ? (<BsFillUnlockFill style={{ color: 'green', fontSize: 22, margin: "0 -3px" }} />) : (<BsFillLockFill style={{ color: 'red', fontSize: 22 }} />)}</span>
                            </div>
                          )
                        })}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar addon do cliente
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}></button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Detalhes da addon do cliente</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do cliente:</td>
                        <td>
                          <input
                            required
                            name="nameAddonEdit"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">URL API:</td>
                        <td>
                          <input
                            required
                            name="urlAddonEdit"
                            className="inputCadastro"
                            defaultValue={key['url']}
                          />
                        </td>
                      </tr>
                      {JSON.parse(key['addons']).map((key, index) => {
                        return (
                          <tr>
                            <td className="labelConfirm">{key[0].addon == 'whatsapp' ? 'Whatsapp' : key[0].addon == 'gerador de imagem' ? 'Gerador de Imagem' : key[0].addon == 'app cliente' ? 'APP Cliente' : key[0].addon == 'app motorista/localizacao' ? 'APP Motorista / Localização' : key[0].addon == 'relatorios' ? 'Relatórios' : key[0].addon == 'notas' ? 'Notas' : key[0].addon == 'marketing' ? 'Marketing' : ''}:</td>
                            <td>
                              <Form.Select
                                name={String('addon' + (index + 1) + 'Edit')}
                                className="inputCadastro"
                              >
                                <option value='' >Selecione a situação da addon</option>
                                <option value='1' selected={key[0].status == 1}>Ativado</option>
                                <option value='0' selected={key[0].status == 0}>Desativado</option>
                              </Form.Select>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Situação do cliente
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}></button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja {situation == 1 ? ('desativar') : ('reativar')} o cliente selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const handleSwitch = (e, id) => {
    let checked = e.target.checked;
    let statusClient = '';
    if (checked) {
      statusClient = 1;
      setChecked(1)
      setStatus('Ativo')
    } else {
      statusClient = 0;
      setChecked(0)
      setStatus('Inativo')
    }

    api.put('/AtualizarAddons', {
      id: id,
      status: statusClient
    })
    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 500);

  }

  const columns = [
    {
      title: "Nome", field: "addon_id", render: (rowData) => {
        let reselerName = '';
        addons.map(key => {
          if (key.id == rowData.addon_id) {
            reselerName = key.name
          }
        })
        return (
          <span>{reselerName}</span>
        )
      }
    },
    {
      title: "Data", field: "date", render: (rowData) => {
        let dateFormated = new Date(Number(rowData.maturity) * 1000)
        return (
          <span>{`${dateFormated.getDate()}/${dateFormated.getMonth() + 1}/${dateFormated.getFullYear()}`}</span>
        )
      }
    },
    {
      title: "Valor", field: "value", render: (rowData) => {
        return (
          <CurrencyInput
            prefix="R$"
            className='currentValuePerfilResale'
            value={rowData.value}
            decimalsLimit={2}
            decimalScale={2}
            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
            disabled
          />
        )
      }
    },
    {
      title: "Status", field: "status", render: (rowData) => {
        let status = ''
        if (rowData.status == 'aberto') {
          status = 'Em Aberto'
        } else if (rowData.status == 'atraso') {
          status = 'Em Atraso'
        } else if (rowData.status == 'pago') {
          status = 'Pago'
        }
        return (
          <div>
            <span>{status}</span>
          </div>
        )
      }
    },
    {
      title: "Renovação", field: "", render: (rowData) => {
        let renovacao = 'Não possui fatura';
        let renovacaoLengh = 0;
        var timestamp = new Date()
        let dateFormated = new Date(Number(rowData.maturity) * 1000)
        let date1 = new Date(`${timestamp.getMonth() + 1}/${timestamp.getDate()}/${timestamp.getFullYear()}`);
        let date2 = new Date(`${dateFormated.getMonth() + 1}/${dateFormated.getDate()}/${dateFormated.getFullYear()}`);
        let diffTime = Math.abs(date2 - date1);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays >= 0) {
          renovacao = ' dia(as) para fechar';
        }
        renovacaoLengh = diffDays;
        return (
          <div>
            <span style={renovacaoLengh >= 10 ? { color: 'green' } : { color: 'red' }}>{renovacao == 'Não possui fatura' ? renovacao : renovacaoLengh + renovacao}</span>
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Loading />
      <ModalPainel />
      <ModalEdit />
      <ModalCancelar />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title={''}
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <Link to="/perfil-revenda" className='btnViewer'>Visualizar</Link>,
                tooltip: 'Perfil',
                onClick: (event, rowData) => {
                  sessionStorage.setItem('addons_id', rowData.id);
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
