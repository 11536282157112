import { useState } from "react";
import React from 'react'
import './invoices.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableInvoices from '../TableInvoices'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';

const invoices = () => {
    const [show, setShow] = useState(false);
    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let addonsList = JSON.stringify([[addon => 'whatsapp', status => data.addon1], [addon => 'gerador de imagem', status => data.addon2], [addon => 'app cliente', status => data.addon3], [addon => 'app motorista/localizacao', status => data.addon4], [addon => 'relatorios', status => data.addon5], [addon => 'notas', status => data.addon6], [addon => 'marketing', status => data.addon7]])

        api.post("/CadastrarAddon", {
            name: data.nameAddon,
            url: data.urlAddon,
            status: '1',
            addons: JSON.stringify([[{ addon: 'whatsapp', status: data.addon1 }], [{ addon: 'gerador de imagem', status: data.addon2 }], [{ addon: 'app cliente', status: data.addon3 }], [{ addon: 'app motorista/localizacao', status: data.addon4 }], [{ addon: 'relatorios', status: data.addon5 }], [{ addon: 'notas', status: data.addon6 }], [{ addon: 'marketing', status: data.addon7 }]]),
        });
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function OffCanvasExample({ ...props }) {
        return (
            <>
                <Loading />
                <Button variant="primary" onClick={handleShow} className="me-2 button">
                    {'Novo cliente'}
                </Button>
                <Offcanvas show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header >
                        <Offcanvas.Title>Novo cliente</Offcanvas.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>X</button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <form className="input" onSubmit={submitForm}>
                            <div className="divCadastro">
                                <InputLabel htmlFor="nameCollaborator">Nome *</InputLabel>
                                <input
                                    required
                                    id="nameCollaborator"
                                    name="nameAddon"
                                    className="inputCadastro"
                                />
                            </div>
                            <div className="divCadastro">
                                <InputLabel htmlFor="typeCollaborator">URL API *</InputLabel>
                                <input
                                    required
                                    id="nameCollaborator"
                                    name="urlAddon"
                                    className="inputCadastro"
                                />
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">Whatsapp *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='addon1'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione a situação da addon</option>
                                    <option value='1'>Ativa</option>
                                    <option value='0' selected>Desativada</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">Gerador de imagem *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='addon2'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione a situação da addon</option>
                                    <option value='1'>Ativa</option>
                                    <option value='0' selected>Desativada</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">App Cliente *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='addon3'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione a situação da addon</option>
                                    <option value='1'>Ativa</option>
                                    <option value='0' selected>Desativada</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">App Motorista/Localização *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='addon4'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione a situação da addon</option>
                                    <option value='1'>Ativa</option>
                                    <option value='0' selected>Desativada</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">Relatórios *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='addon5'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione a situação da addon</option>
                                    <option value='1'>Ativa</option>
                                    <option value='0' selected>Desativada</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">Notas *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='addon6'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione a situação da addon</option>
                                    <option value='1'>Ativa</option>
                                    <option value='0' selected>Desativada</option>
                                </Form.Select>
                            </div>

                            <div className="divCadastro">
                                <InputLabel htmlFor="senhaCollaborator">Marketing *</InputLabel>
                                <Form.Select
                                    id='typeCollaborator'
                                    name='addon7'
                                    className="inputCadastro"
                                >
                                    <option value=''>Selecione a situação da addon</option>
                                    <option value='1'>Ativa</option>
                                    <option value='0' selected>Desativada</option>
                                </Form.Select>
                            </div>

                            <div className="buttonLogin">
                                <button className="buttonSecundary" >
                                    Adicionar cliente
                                </button>
                            </div>
                        </form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );

    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h3>Faturas</h3>
                    </div>
                    {/* <div>
                        <OffCanvasExample key={0} placement={'end'} name={'end'} />
                    </div> */}
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '255vh', borderRadius: '10px', padding: '10px' }}>
                    <TableInvoices />
                </Card>
            </div >
        </>
    )

}

export default invoices