import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableTraining.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineDollar } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'
import { TextareaAutosize } from '@material-ui/core';
import { CollectionsBookmarkOutlined } from '@material-ui/icons';
import { MdEdit } from "react-icons/md";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import axios from 'axios';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'cliente',
    numeric: false,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Tipo',
  },
  {
    id: 'endereco',
    numeric: false,
    disablePadding: false,
    label: 'Endereço',
  },
  {
    id: 'telefone',
    numeric: false,
    disablePadding: false,
    label: 'Telefone',
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: false,
    label: 'Ações',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              className="colunmLabel"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const [rows, setRows] = React.useState([])
  const [categorys, setCategorys] = React.useState([]);
  const [subcategorys, setSubcategorys] = React.useState([]);

  const [videos, setVideos] = React.useState([])
  const [courses, setCourses] = React.useState([]);
  const [courseVideos, setCourseVideos] = React.useState([])

  React.useEffect(() => {
    axios
      .get('https://api.clubedorevendedordegas.com.br/TodosCursos')
      .then(response => {
        setCourses(response.data);
      })
  }, [])

  React.useEffect(() => {
    axios
      .get('https://api.clubedorevendedordegas.com.br/TodosCursosVideos')
      .then(response => {
        setCourseVideos(response.data);
        setRows(response.data)
      })
  }, [])

  React.useEffect(() => {
    axios
      .get('https://api.clubedorevendedordegas.com.br/TodosVideos')
      .then(response => {
        setVideos(response.data);
      })
  }, [])

  const columns = [
    {
      title: "Curso", field: "course_id", render: (rowData) => {
        let courseName = '';
        console.log(rowData)
        courses.map(key => {
          if (key['id'] == rowData.course_id) {
            courseName = key['name']
          }
        })
        return (
          <div>
            <span>{courseName}</span>
          </div>
        )
      }
    },
    { title: "Aula", field: "lesson" },
    {
      title: "Título", field: 'title', render: (rowData) => {
        let titleVideo = '';
        videos.map(key => {
          if (key['id'] == rowData.video_id) {
            titleVideo = key['title']
          }
        })
        return (
          <div>
            <span>{titleVideo}</span>
          </div>
        )
      }
    }
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Treinamentos"
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <Link to="/editar-treinamento" className='btnEditArt'><MdEdit className='iconEditArt' /> </Link>,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  sessionStorage.setItem('artEditId', rowData.id);
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
      {/* <Box sx={{ width: '100%' }}>
        <div className='filterArts'>
          <TextField id="outlined-basic" label="Buscar imagem" variant="outlined" className='filterArtName' onChange={handleChange} />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Categoria</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className='filterArtCategory'
              value={age}
              label="Categoria"
              onChange={e => {
                let value = e.target.value;
                if (value !== '') {
                  setCategoryId(value);
                  setSubCategory(true)
                } else {
                  setCategoryId(0);
                  setSubCategory(false)
                }
              }}
            >
              {categorys.map(key => {
                return (
                  <MenuItem value={key.id}>{key.name}</MenuItem>
                )
              })}
              <MenuItem value={''}>Limpar</MenuItem>
            </Select>
          </FormControl>
          {subCategory ? (
            <FormControl fullWidth style={{ marginLeft: 10 }}>
              <InputLabel id="demo-simple-select-label">Sub categoria</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className='filterArtCategory'
                value={age}
                label="Sub categoria"
                onChange={handleChange}
              >
                {subcategorys.map(key => {
                  if (key.category_id == categoryId) {
                    return (
                      <MenuItem value={key.id}>{key.name}</MenuItem>
                    )
                  }
                })}
                <MenuItem value={''}>Limpar</MenuItem>
              </Select>
            </FormControl>
          ) : ("")}

        </div>
        <div className='containerAllArts'>
          {rows.slice(0, showMore).map(key => {
            console.log(key.url)
            return (
              <div className='containerArt'>
                <div className='imageArt'>
                  <img src={key.url}  className='imgArt' />
                </div>
                <div className='containerContect'>
                  <span className='titleArt'>
                    {key.name}
                  </span>
                  <Link to='/editar-arte' className='btnEditArt' onClick={e => {
                    sessionStorage.setItem('artEditId', key.id);

                  }}>
                    <MdEdit className='iconEditArt' /> 
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
        {allArts.length >= showMore ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
            <button className="me-2 button" onClick={() => {
              setShowMore(showMore + 48)
            }}>Carregar mais</button>
          </div>
        ) : ""}
      </Box> */}
    </>
  );
}
