import { useState, useEffect } from "react";
import React from 'react'
import './editTraining.css';
import { Card, Button } from "react-bootstrap";
import api from "../../service/api";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ColorPicker from 'react-pick-color';
import { AiFillLeftCircle } from "react-icons/ai"
import emojis from "../../assets/emojis";
import ultraLogo from '../../image/ultragaz-logo-0.png'
import liguiLogo from '../../image/liquigas-logo-00.png'
import copaLogo from '../../image/copagaz-logo-0.png'
import nacionalLogo from '../../image/nacional-gas-4096.png'
import superLogo from '../../image/supergasbras-logo-0.png'
import consiLogo from '../../image/consigaz.png'
import servLogo from '../../image/SERVGAS-DISTRIBUIDORA-DE-GAS-SA.webp'
import indeLogo from '../../image/revendaindependente.png'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from "react-router-dom";
import axios from "axios";
import YouTube from "react-youtube"

const EditTraining = () => {

    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [categorySelected, setCategorySelected] = useState();
    const [allArts, setAllArts] = useState([]);
    const [ultragraz, setUltragraz] = useState(null);
    const [consigaz, setConsigaz] = useState(null)
    const [copagaz, setCopagaz] = useState(null)
    const [independente, setIndependente] = useState(null)
    const [liquigas, setLiquigas] = useState(null)
    const [nacionalGas, setNacionalGas] = useState(null)
    const [ngc, setNgc] = useState(null)
    const [servigas, setServigas] = useState(null)
    const [supergasbras, setSupergasbras] = useState(null)
    const [ultragrazFile, setUltragrazFile] = useState('');
    const [consigazFile, setConsigazFile] = useState('')
    const [copagazFile, setCopagazFile] = useState('')
    const [independenteFile, setIndependenteFile] = useState('')
    const [liquigasFile, setLiquigasFile] = useState('')
    const [nacionalGasFile, setNacionalGasFile] = useState('')
    const [ngcFile, setNgcFile] = useState('')
    const [servigasFile, setServigasFile] = useState('')
    const [supergasbrasFile, setSupergasbrasFile] = useState('')
    const [imageSelected, setImageSelected] = useState(ultragraz)
    const [marcaSelected, setMarcaSelected] = useState('ultragaz');

    const [videos, setVideos] = React.useState([])
    const [courses, setCourses] = React.useState([]);
    const [courseVideos, setCourseVideos] = React.useState([])
    const [videoId, setVideoId] = useState([])
    const [idAula, setIdAula] = useState('')

    const editArtId = sessionStorage.getItem('artEditId');

    React.useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosCursos')
            .then(response => {
                setCourses(response.data);
            })
    }, [])

    React.useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosCursosVideos')
            .then(response => {
                setCourseVideos(response.data);
                response.data.map(key => {
                    if (key['id'] == editArtId) {
                        setIdAula(key['video_id']);
                    }
                })
            })
    }, [])

    React.useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosVideos')
            .then(response => {
                setVideos(response.data);
            })
    }, [])

    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        const headers = {
            'headers': {
                'Content-Type': 'multipart/form-data',
            }
        }

        let postComplete = data.descricaoVideo.split('\n');
        var arrayPost = [];
        postComplete.map(key => {
            postComplete.map(key => {
                if (key !== '') {
                    if (key !== ' ') {
                        if (key !== '  ') {
                            arrayPost.push(key);
                        }
                    }

                }
            })
        })

        let dados = {
        }

        if (data.thumbVideo == '') {
            dados = {
                id: idAula,
                url_video: data.urlVideo,
                title: data.nomeVideo,
                description: arrayPost.join(' <br /> '),
            }
        } else {
            dados = {
                id: idAula,
                image: data.thumbVideo,
                url_video: data.urlVideo,
                title: data.nomeVideo,
                description: arrayPost.join(' <br /> '),
                url_thumbnail: `https://api.clubedorevendedordegas.com.br/files/thumbs/${data.thumbVideo.name}`,
            }
        }

        axios.put("https://api.clubedorevendedordegas.com.br/AtualizarVideo", dados, headers)

        axios.put("https://api.clubedorevendedordegas.com.br/AtualizarVideoCurso", {
            id: editArtId,
            course_id: data.cursoVideo,
            lesson: data.aulaVideo
        })

        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    function mostraImagem(img) {
        if (img.files && img.files[0]) {
            var reader = new FileReader();
            var imagem = document.getElementById("artImage");

            reader.onload = function (e) {
                imagem.src = e.target.result
            };

            reader.readAsDataURL(img.files[0]);
        }
    }

    const opts = {
        height: '200px',
        width: '100%',
        playerVars: {
            autoplay: 0,
            enablejsapi: 1,
        },
    };

    const checkElapsedTime = (e) => {
        // setEventTest(e.target)
    };

    React.useEffect(() => {
        var imagem = document.getElementById("artImage");
        courseVideos.map(key => {
            if (key['id'] == editArtId) {
                videos.map(k => {
                    if (k['id'] == key['video_id']) {
                        imagem.src = k['url_thumbnail']
                        let url = k['url_video'].split('https://www.youtube.com/watch?v=')
                        setVideoId(url[1])
                    }
                })
            }
        })
    }, [videos])



    return (
        <>
            <Loading />
            <div className='orderedContainer'>
                <div className="titleAndButton">
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Link to="/treinamentos" style={{ background: 'transparent', color: 'black', border: 'none', textDecoration: 'none' }}><AiFillLeftCircle style={{ fontSize: 26 }} /> Voltar </Link>
                        <h3 style={{ marginLeft: 10 }}>Editar Aula</h3>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '135vh', borderRadius: '10px', padding: '10px' }}>
                    <form className="input" onSubmit={submitForm} enctype="multipart/form-data" >
                        {courseVideos.map(key => {
                            if (key['id'] == editArtId) {
                                return (
                                    <div className="col2">
                                        <div className="divInputsImages">
                                            <span>Thumbnail</span>
                                            <img className="imgInput2" id='artImage' />
                                            <input type="file" name="thumbVideo" style={{ marginTop: 5 }} onChange={(e) => {
                                                mostraImagem(e.target)
                                            }} />
                                            <div style={{ marginTop: 10, width: '90%' }}>
                                                {videoId !== '' ? (
                                                    <YouTube videoId={videoId} opts={opts} onReady={checkElapsedTime} className='youtubePlayer' />
                                                ) : ''}
                                                {videos.map(k => {
                                                    if (k['id'] == key['video_id']) {
                                                        return (
                                                            <TextField id="outlined-basic" label="Url vídeo" variant="outlined" className="inputDetails" name='urlVideo' onChange={e => {
                                                                let url = e.target.value.split('https://www.youtube.com/watch?v=')
                                                                setVideoId(url[1])
                                                            }} defaultValue={k['url_video']} />
                                                        )
                                                    }
                                                })}

                                            </div>
                                        </div>
                                        <div className="divDetaisImage">
                                            {videos.map(k => {
                                                if (k['id'] == key['video_id']) {
                                                    return (
                                                        <TextField id="outlined-basic" label="Nome do Vídeo" variant="outlined" className="inputDetails" name='nomeVideo' defaultValue={k['title']} />
                                                    )
                                                }
                                            })}
                                            <FormControl className="inputDetails">
                                                <InputLabel id="demo-simple-select-label">Curso</InputLabel>
                                                <Select
                                                    required
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    // value={age}
                                                    label="Categoria"
                                                    name="cursoVideo"
                                                    defaultValue={key['course_id']}
                                                >
                                                    {courses.map(key => {
                                                        return (
                                                            <MenuItem value={key['id']}>{key['name']}</MenuItem>
                                                        )
                                                    })}

                                                </Select>
                                            </FormControl>
                                            <TextField id="outlined-basic" label="Número da aula" variant="outlined" className="inputDetails" name='aulaVideo' defaultValue={key['lesson']} />
                                            {videos.map(k => {
                                                if (k['id'] == key['video_id']) {
                                                    return (
                                                        <TextField
                                                            id="outlined-multiline-static"
                                                            className="inputDetails"
                                                            label="Descrição"
                                                            multiline
                                                            rows={7}
                                                            name='descricaoVideo'
                                                            defaultValue={k['description']}
                                                        />
                                                    )
                                                }
                                            })}

                                            <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                                <button className="buttonSecundary" >
                                                    Atualizar vídeo
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })}


                    </form>
                </Card>
            </div >
            {/* <div className='orderedContainer'>
                <div className="titleAndButton">
                    <div>
                        <Button href="/artes" style={{ background: 'transparent', color: 'black', border: 'none', textDecoration: 'none' }}><AiFillLeftCircle style={{ fontSize: 26 }} /> Voltar</Button>
                    </div>
                </div>
                <div className="titleAndButton">
                    <div>
                        <h3>Editar arte</h3>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', height: '135vh', borderRadius: '10px', padding: '10px' }}>
                    <form className="input" onSubmit={submitForm}>
                        {allArts.map(key => {
                            if (key['id'] == editArtId) {
                                let dateFormatedStart = '';
                                let dateFormatedEnd = '';
                                if (key['date_start_situation'] !== null) {
                                    var dateStartFormated = new Date(Number(key['date_start_situation']));
                                    let dayStart = dateStartFormated.getDate() + 1;
                                    var monthStart = dateStartFormated.getMonth() + 1;
                                    var yearStart = dateStartFormated.getFullYear();

                                    if (dayStart > 1 && dayStart < 10) {
                                        dayStart = "0" + dayStart;
                                    }

                                    dateFormatedStart = [yearStart, monthStart, dayStart].join('-');
                                }

                                if (key['date_end_situation'] !== null) {
                                    var dateEndFormated = new Date(Number(key['date_end_situation']));
                                    let dayEnd = dateEndFormated.getDate() + 1;
                                    var monthEnd = dateEndFormated.getMonth() + 1;
                                    var yearEnd = dateEndFormated.getFullYear();

                                    if (dayEnd > 1 && dayEnd < 10) {
                                        dayEnd = "0" + dayEnd;
                                    }

                                    dateFormatedEnd = [yearEnd, monthEnd, dayEnd].join('-');
                                }

                                var linhas = key['post'].split('<br /> ');
                                return (
                                    <>
                                        <div className="container2itens" style={{ justifyContent: "center" }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Nome da imagem </InputLabel>
                                                <input
                                                    required
                                                    id="artNameEdit"
                                                    name="artNameEdit"
                                                    className="inputCadastro"
                                                    defaultValue={key['name']}
                                                />
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Situação </InputLabel>
                                                <Form.Select
                                                    id='artSituationEdit'
                                                    name='artSituationEdit'
                                                    className="inputCadastro"
                                                    required
                                                    onChange={(e) => dateSituation(e.target.value)}
                                                >
                                                    <option value=''>Selecione uma situação </option>
                                                    <option value='emAlta' selected={key['situation'] == 'emAlta'}>Em alta</option>
                                                    <option value='normal' selected={key['situation'] == 'normal'}>Normal</option>
                                                </Form.Select>
                                            </div>
                                        </div>

                                        <div className="container2itens" id="datesSituation" style={{ justifyContent: "center", display: key['situation'] == 'emAlta' ? 'flex' : 'none' }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data inicial da situação </InputLabel>
                                                <input
                                                    type="date"
                                                    id="dateStartArtEdit"
                                                    name="dateStartArtEdit"
                                                    className="inputCadastro"
                                                    defaultValue={dateFormatedStart}
                                                />
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Data final da situação </InputLabel>
                                                <input
                                                    type="date"
                                                    id="dateEndArtEdit"
                                                    name="dateEndArtEdit"
                                                    className="inputCadastro"
                                                    defaultValue={dateFormatedEnd}
                                                />
                                            </div>
                                        </div>

                                        <div className="container2itens" style={{ justifyContent: "center", paddingTop: 10 }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Categoria </InputLabel>
                                                <Form.Select
                                                    id='typeCollaborator'
                                                    name='artCategoryEdit'
                                                    className="inputCadastro"
                                                    required
                                                    onChange={(e) => setCategorySelected(e.target.value)}
                                                >
                                                    <option value=''>Selecione uma categoria</option>
                                                    {category.map(c => {
                                                        return <option selected={key['category'] == c['id']} value={c['id']}>{c['name']}</option>
                                                    })}
                                                </Form.Select>
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginBottom: 10 }}>Subcategoria</InputLabel>
                                                <Form.Select
                                                    id='typeCollaborator'
                                                    name='artSubcategoryEdit'
                                                    className="inputCadastro"
                                                >
                                                    <option value=''>Selecione um Subcategoria</option>
                                                    {subcategory.map(s => {
                                                        if (s['category_id'] == categorySelected) {
                                                            return <option selected={key['subcategory'] == s['id']} value={s['id']}>{s['name']}</option>
                                                        }
                                                    })}
                                                </Form.Select>
                                            </div>
                                        </div>

                                        <div className="container2itens" style={{ justifyContent: "center", paddingTop: 10 }}>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Arte</InputLabel>
                                                <img src={key['url']} className="imgProfile" id="artImage" />
                                                <input
                                                    type="file"
                                                    name="artImageEdit"
                                                    style={{ marginLeft: 10 }}
                                                    onChange={(e) => {
                                                        mostraImagem(e.target)
                                                    }}
                                                />
                                            </div>
                                            <div className="divCadastro" style={{ display: 'flex', flexDirection: 'column', width: '40%', margin: '0 10px' }}>
                                                <InputLabel htmlFor="nameCollaboratorProfile" style={{ marginLeft: 10 }}>Texto para postagem</InputLabel>
                                                <textarea
                                                    name="artPostEdit"
                                                    rows={14}
                                                    style={{ padding: 10, resize: 'none' }}
                                                    defaultValue={linhas.join('\n \n')}
                                                />
                                                <Form.Select
                                                    className="inputCadastro"
                                                >
                                                    {arrayEmojis.map(key => {
                                                        return (
                                                            <option value={key[1]['emoji']}>{key[1]['emoji']}</option>
                                                        )

                                                    })}
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="buttonLogin" style={{ marginLeft: 10, marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                                            <button className="buttonSecundary" >
                                                Editar arte
                                            </button>
                                        </div>
                                    </>
                                )
                            }

                        })}

                    </form>
                </Card>
            </div > */}
        </>
    )
}

export default EditTraining