import { useState } from "react";
import React from 'react'
import './arts.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import makeAnimated from 'react-select/animated';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import TableArts from '../TableArts'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import InputMask from 'react-input-mask';
import { Link } from "react-router-dom";

const Arts = () => {
    const [show, setShow] = useState(false);
    const typeCollaborator = sessionStorage.getItem("typeCollaborator");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const submitForm = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);
        let phone = data.celularCollaborator.replace("-", "").replace(" ", "").replace("(", "").replace(")", "");
        api.post("/CadastrarColaborador", {
            name: data.nameCollaborator,
            number: Number(data.numeroCollaborator),
            street: data.enderecoCollaborator,
            city: data.cidadeCollaborator,
            state: data.estadoCollaborator,
            neighborhood: data.bairroCollaborator,
            complement: data.complementoCollaborator,
            reference_point: data.referenciaCollaborator,
            cpf: data.cpfCollaborator,
            email: data.emailCollaborator,
            phone: phone,
            birthdate: data.dataNascimentoCollaborator,
            password: data.senhaCollaborator,
            type: data.typeCollaborator,
            obs: data.obsCollaborator
        });
        setOpen(true)
        setTimeout(function () {
            window.location.reload(1);
        }, 3000);
    }

    const [open, setOpen] = useState(false);

    function Loading() {
        return (
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }

    return (
        <>
            <div className='orderedContainer'>

                <div className="titleAndButton">
                    <div>
                        <h3>Artes</h3>
                    </div>
                    <div>
                        <Link to="/cadastrar-arte" className="me-2 button">
                            {'Nova arte'}
                        </Link>
                    </div>
                </div>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px' }}>
                    <TableArts />
                </Card>
            </div >
        </>
    )
}

export default Arts