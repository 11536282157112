import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import api from "../../service/api";
import Button from '@mui/material/Button';
import './tableCollaborators.css'
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { AiOutlineUser, AiOutlineEdit, AiOutlineDelete, AiOutlineDollar } from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialTable from 'material-table'

export default function EnhancedTable() {
  const [rows, setRows] = React.useState([])
  const [collaborators, setCollaborators] = React.useState([]);
  const [painelModal, setPainelModal] = React.useState(false);
  const [idModal, setIdModal] = React.useState();
  const [editModal, setEditModal] = React.useState(false);
  const [modalCancel, setModalCancel] = React.useState(false);

  const typeCollaborator = sessionStorage.getItem("typeCollaborator");

  React.useEffect(() => {
    api
      .get("/TodosColaboradores")
      .then((response) => {
        setRows(response.data);
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }, []);

  const handleModalClose = () => {
    setPainelModal(false)
    setEditModal(false)
    setModalCancel(false)
  };

  const submitModalEdit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    const dados = {
      id: idModal,
      email: data.emailCollaboratorEdit,
      name: data.nameCollaboratorEdit,
      type: data.typeCollaboratorEdit
    }

    api.put("/AtualizarColaborador", dados);

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  const handleCancelOrdered = async (e) => {

    api.delete('/DeletarColaborador/' + idModal)

    setOpen(true)
    setTimeout(function () {
      window.location.reload(1);
    }, 3000);
  }

  function ModalPainel(props) {
    return (
      <Modal
        show={painelModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do colaborador</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              return (
                <Table striped bordered hover className="tableProfile">
                  <tbody>
                    <tr>
                      <td className="labelConfirm">Nome do cliente</td>
                      <td>{key['name']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">Tipo de colaborador</td>
                      <td>{key['type']}</td>
                    </tr>
                    <tr>
                      <td className="labelConfirm">E-mail</td>
                      <td>{key['email']}</td>
                    </tr>
                  </tbody>
                </Table>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )

  }

  function ModalEdit(props) {
    return (
      <Modal
        show={editModal}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Editar perfil
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <div className='titleProfile'>
            <h4>Informações do colaborador</h4>
          </div>
          {rows.map(key => {
            if (key['id'] == idModal) {
              return (
                <form className="input" onSubmit={submitModalEdit}>
                  <Table striped bordered hover className="tableProfile">
                    <tbody>
                      <tr>
                        <td className="labelConfirm">Nome do cliente:</td>
                        <td>
                          <input
                            required
                            name="nameCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['name']}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">Tipo de colaborador:</td>
                        <td>
                          <Form.Select
                            name='typeCollaboratorEdit'
                            className="inputCadastro"
                          >
                            <option value='' >Selecione um colaborador</option>
                            <option value='admin' selected={key['type'] == 'admin'}>Administrador</option>
                            <option value='recepcao' selected={key['type'] == 'recepcao'}>Recepcionista</option>
                          </Form.Select>
                        </td>
                      </tr>
                      <tr>
                        <td className="labelConfirm">E-mail</td>
                        <td>
                          <input
                            required
                            name="emailCollaboratorEdit"
                            className="inputCadastro"
                            defaultValue={key['email']}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="buttonLogin">
                    <button className="buttonSecundary" >
                      Atualizar
                    </button>
                  </div>
                </form>
              )
            }
          })}
        </Modal.Body>
      </Modal>
    )
  }

  function ModalCancelar(props) {
    return (
      <Modal
        show={modalCancel}
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
            Excluir colaborador
          </Modal.Title>
          <button type="button" className="btn-close" aria-label="Close" onClick={handleModalClose}>X</button>
        </Modal.Header>
        <Modal.Body className="cancelBody">
          <h4>Você tem certeza que deseja excluir o colaborador selecionado?</h4>
          <div className="cancelOrder">
            <Button onClick={handleCancelOrdered} className="cancelButton true">Sim</Button>
            <Button onClick={handleModalClose} className="cancelButton false">Não</Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  const [open, setOpen] = React.useState(false);

  function Loading() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  const columns = [
    { title: "Colaborador", field: "name" },
    {
      title: "Tipo", field: "type", render: (rowData) => {
        let typeCollaborator = '';

        if (rowData.type == 'superadmin') {
          typeCollaborator = 'Superadministrador';
        } else if (rowData.type == 'addonmanager') {
          typeCollaborator = 'Gerenciador de addons';
        } else if (rowData.type == 'artmanager') {
          typeCollaborator = 'Gerenciador de arte';
        } else {
          typeCollaborator = 'Não identificado';
        }

        return <div>{typeCollaborator}</div>
      }
    },
    { title: "E-mail", field: "email" }
  ]

  return (
    <>
      <Loading />
      <ModalCancelar />
      <ModalPainel />
      <ModalEdit />
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <MaterialTable
            title="Colaboradores"
            columns={columns}
            data={rows}
            actions={[
              {
                icon: () => <AiOutlineUser className='perfilAction' />,
                tooltip: 'Perfil',
                onClick: (event, rowData) => {
                  setPainelModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineEdit className='perfilAction' />,
                tooltip: 'Editar',
                onClick: (event, rowData) => {
                  setEditModal(true)
                  setIdModal(rowData.id)
                }
              },
              {
                icon: () => <AiOutlineDelete className='perfilAction' />,
                tooltip: 'Deletar',
                onClick: (event, rowData) => {
                  setModalCancel(true)
                  setIdModal(rowData.id)
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: false,
            }}
            localization={{
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}'
              },
              header: {
                actions: 'Ações'
              },
              body: {
                emptyDataSourceMessage: 'Não há registros a serem exibidos',
                filterRow: {
                  filterTooltip: 'Filtro'
                }
              },
              toolbar: {
                searchTooltip: 'Pesquisar',
                searchPlaceholder: 'Pesquisar',
                exportTitle: 'Exportar',
                exportAriaLabel: 'Exportar',
                exportCSVName: "Exportar para CSV",
                exportPDFName: "Exportar para PDF",
                nRowsSelected: '{0} linha(s) selecionada(s)'
              }
            }}
          />
        </Paper>
      </Box>
    </>
  );
}
