import React from 'react'
import './pageNotFound.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import Image404 from '../../image/404.png'

const PageNotFound = () => {

    return (
        <>
            <div className='PageNotFoundContainer'>
                <Card className="cardContainer" style={{ width: '100%', height: '60vh', borderRadius: '10px', padding: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={Image404} />
                </Card>
            </div >
        </>
    )
}

export default PageNotFound