import { useEffect, useState } from "react";
import React from 'react'
import './requestSpecificy.css';
import { Card, Button, Offcanvas } from "react-bootstrap";
import api from "../../service/api";
import redes_sociais from '../../image/redes-sociais.png'
import google_meu_negocio from '../../image/google-meu-negocio.png'
import app_cliente from '../../image/app-cliente.png'
import { Link, useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';

const RequestSpecificy = () => {
    const [requests, setRequests] = useState([])
    const [titlePage, setTitlePage] = useState('')
    const [resellers, setResellers] = useState([])

    const { type } = useParams()

    useEffect(() => {
        var array = []
        let typeRequest = ''
        if (type == 'duvidas') {
            typeRequest = 'duvidas plataforma'
            setTitlePage('Dúvidas da plataforma')
        } else if (type == 'suporte') {
            typeRequest = 'suporte'
            setTitlePage('Suporte')
        } else if (type == 'informacoes') {
            typeRequest = 'informacoes'
            setTitlePage('Informações')
        } else if (type == 'raio-x') {
            typeRequest = 'raio-x'
            setTitlePage('Raio-x das redes sociais')
        } else if (type == 'google') {
            typeRequest = 'google'
            setTitlePage('Google meu negócio')
        } else if (type == 'app-gas-click') {
            typeRequest = 'app gas click'
            setTitlePage('Aplicativo gás no clique')
        }
        api
            .get('/TodasSolicitacoes')
            .then(response => {
                response.data.map(key => {
                    if (key['request'] == typeRequest) {
                        array.push(key)
                    }
                })

                setRequests(array)
            })
    }, [])

    useEffect(() => {
        axios
            .get('https://api.clubedorevendedordegas.com.br/TodosUsuariosRevenda/clube_api')
            .then(response => {
                response.data.map(k => {
                    requests.map(key => {
                        if (k.id == key.reseller) {
                            setResellers(k)
                        }
                    })
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [requests])

    return (
        <>
            <div className='orderedContainer'>
                <Card className="cardContainer" style={{ width: '100%', borderRadius: '10px', padding: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="containerRequestChamados">
                        <div className="divSpanRequest">
                            <span className="spanRequest">{titlePage}</span>
                        </div>
                        <hr />
                        <TableContainer component={Paper} style={{ width: '50%', height: '100%' }}>
                            <Table sx={{ width: '100%', height: '100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="titleHeaderTable">Revenda</TableCell>
                                        <TableCell className="titleHeaderTable">Plano</TableCell>
                                        <TableCell className="titleHeaderTable">Situação</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {requests.map(row => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {resellers.reseller_name}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        type == 'duvidas' ? ('Dúvidas da plataforma') : type == 'suporte' ? ('Suporte') : type == 'informacoes' ? ('Informações') : type == 'raio-x' ? ('Raio-x das redes sociais') : type == 'google' ? ('Google meu negócio') : type == ('app-gas-click') ? ('Aplicativo gás no clique') : ''
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        row.status == 'aberto' ? ('Aberto') : row.status == 'fechado' ? ('Fechado') : ('Pendente')
                                                    }
                                                </TableCell>
                                                <TableCell style={{ padding: 10 }}>
                                                    <div className="divLinkToMore">
                                                        <Link className="linkToMore" to={`/perfil-solicitacao/${row.id}`}>Ver</Link>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Card>
            </div >
        </>
    )
}

export default RequestSpecificy