import React from 'react';
import './footer.css'
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';

const Footer = () => {
    return (
        <Nav className="justify-content-center">
            {/* <div>Create by Agência Atlantis</div> */}
        </Nav>
    )
}

export default Footer